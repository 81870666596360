import { useTranslation } from "react-i18next";
import UserActionPagesTemplate from "../../Template/UserActionPagesTemplate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { SET_MESSAGE } from "../../Actions/Types";
import { resetPasswordService } from "../../Services/UserAction/ResetPasswordService";

const ResetPasswordPage = () => {

    // Redux
    const { user } = useSelector((store: StoreProptypes) => store);

    // Function
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const resetToken = searchParams.get("token");
    const ResetEmail = searchParams.get("email");

    useEffect(() => {
        if (user) {

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("You're already logged In. You can change your password via profile settings.", { returnObjects: true }),
                    "toast_type": "success"
                }
            });

            navigate('/front/edit-profile/');

        } else {
            resetToken && setToken(resetToken);
            ResetEmail && setUserEmail(ResetEmail);
        }

    }, [resetToken, ResetEmail])

    const inputRef = useRef<HTMLInputElement | null>(null);

    const [userEmail, setUserEmail] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();

        if (password === '') {

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Please enter your password.", { returnObjects: true }),
                    "toast_type": "danger"
                }
            });

        }
        else if (password.length < 8 || password.length > 25) {

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Password should be between 8 to 25 letters.", { returnObjects: true }),
                    "toast_type": "danger"
                }
            });

        }
        else if (password !== cpassword) {

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Password and confirm password doesn't match.", { returnObjects: true }),
                    "toast_type": "danger"
                }
            });

        }
        else {
            if (userEmail && token && password && cpassword) {
                resetPasswordService(userEmail, token, password, cpassword, handleFocus, t, dispatch, navigate);
            }
        }
    }

    const handleFocus = () => {
        //console.log("handle Close called");
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }

    return (
        <>
            <UserActionPagesTemplate>
                <h1>{t('Reset Password')}</h1>
                <Form>
                    <Form.Group className="form-group" controlId="user_email">
                        <Form.Label>{t('New Password')}</Form.Label>
                        <Form.Control
                            type="password"
                            aria-label="New Password"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                            value={password}
                            ref={inputRef}
                            autoComplete="new-password"
                            aria-describedby="passwordHelpBlock"
                            size="lg"
                        />
                        <Form.Text id="passwordHelpBlock" muted>{t("Password should be between 8 to 25 letters")}</Form.Text>
                    </Form.Group>
                    <Form.Group className="form-group" controlId="user_email">
                        <Form.Label>{t('Confirm Password')}</Form.Label>
                        <Form.Control
                            type="password"
                            aria-label="Confirm Password"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCPassword(e.target.value)}
                            value={cpassword}
                            autoComplete="new-password"
                            aria-describedby="passwordHelpBlock1"
                            size="lg"
                        />
                        <Form.Text id="passwordHelpBlock1" muted>{t("Re-enter password to confirm")}</Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit" size="lg" className="w-100" onClick={(e) => handleSubmit(e)}>{t('Reset Password')}</Button>
                </Form>
                <div className="aioa_dashboard-login-link">
                    <Link to="/front/login" className="aioa_free-trial-link">{t("Login")}</Link>
                </div>
            </UserActionPagesTemplate>
        </>
    )
}

export default ResetPasswordPage;