const parseISODate = (TimeISOString: string | null, is_year?: boolean, date_range?: boolean) => {

    if (TimeISOString) {

        if (TimeISOString === "0000-00-00 00:00:00") {
            return '-';
        }

        let parsedTime = new Date(TimeISOString);
        parsedTime = new Date(parsedTime.getTime());

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        let dateString;

        const generateDateFormate = (itemID: number) => {
            if (itemID) {
                const temID = [...Array((2 - ((itemID).toString().length)))].map((i) => 0);
                return `${temID}${itemID}`;
            }
        }


        if (is_year) {
            dateString = parsedTime.getDate() + ' ' + (monthNames[parsedTime.getMonth()]);
        } else if (date_range) {
            dateString = (parsedTime.getFullYear() + '-' + generateDateFormate(parsedTime.getMonth() + 1) + '-' + generateDateFormate(parsedTime.getDate()));
        } else {
            dateString = (monthNames[parsedTime.getMonth()]) + ' ' + parsedTime.getDate() + ', ' + parsedTime.getFullYear();
        }

        return dateString;

    }
    else {
        return '-'
    }
}



export default parseISODate;