import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { forgetPasswordApi } from "../../Api/UserAction/ForgotPasswordApi";

const forgetPasswordService = (
    email: string,
    handleFocus: Function,
    t: Function,
    dispatch: Function,
    navigate: Function
) => {

    // Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    forgetPasswordApi(email).then((status: number | never[]) => {
        if (status === 200) {

            dispatch({
                type: LOADING,
                payload: false,
            });

            navigate('/front/login');

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Forgot Password Success Msg", { returnObjects: true }),
                    "toast_type": "success",
                    "onclose": null
                }
            });

        } else {

            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Forgot Password Failed Msg", { returnObjects: true }),
                    "toast_type": "danger",
                    "onclose": handleFocus
                }
            });

        }

    });
}

export { forgetPasswordService }