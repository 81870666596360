import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface PropTypes {
    setChangeWidgetSize: Function
}

const WidgetSize = (props: PropTypes) => {

    // Props
    const { setChangeWidgetSize } = props;

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    // Stets
    const [widgetSize, setWidgetSize] = useState<number>(0);

    // Set current widget icon type
    useEffect(() => {
        if (currentWebsite) {
            if (currentWebsite.widget_size === 1) {
                setWidgetSize(1);
            } else {
                setWidgetSize(0);
            }
        }
    }, [currentWebsite]);

    // set current icon value in prop function
    useEffect(() => {
        if (widgetSize === 1) {
            setChangeWidgetSize(1);
        } else {
            setChangeWidgetSize(0);
        }
    }, [widgetSize]);

    return (
        <>
            <div className="aioa_dashboard-widget-settings-btns">
                <Button onClick={() => setWidgetSize(1)} className={`${widgetSize === 1 ? 'active' : ''}`} size="lg">{t('widget oversize label')}</Button>
                <Button onClick={() => setWidgetSize(0)} className={`${widgetSize === 0 ? 'active' : ''}`} size="lg">{t('widget regular label')}</Button>
            </div>
        </>
    )
}

export default WidgetSize;