const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

const domainValidationAPI = async (websiteURL: string): Promise<any> => {
    let url = `${baseURL}/check-exist-email-website`;

    const Formdata = new FormData();
    Formdata.append("website", websiteURL);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: Formdata
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { domainValidationAPI }