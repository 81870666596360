const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

const ReportProblemAPI = async (
    accessToken: string,
    websiteID:number,
    problemType: string,
    media: File,
    description: string
    
): Promise<any> => {

    const url = `${baseURL}/free-version-report-problem`;
    const formData = new FormData();

    formData.append("website_id", websiteID.toString());
    formData.append("problem_type", problemType);
    formData.append("screenshot_file", media);
    formData.append("description", description);
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });

        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}



export default ReportProblemAPI