import { LOADING, SET_MESSAGE, UPDATE_USER } from "../../Actions/Types";
import { updateUserProfileApi } from "../../Api/UserAction/UpdateUserDetails";
import { UserPropTypes } from "../../PropTypes/UserPropTypes";

const updateUserService = (
    user: UserPropTypes,
    accessToken: string,
    setLoading: Function,
    dispatch: Function,
    t: Function,
    password?: string
) => {

    // Loading true
    dispatch({
        type: LOADING,
        payload: true,
    });


    updateUserProfileApi(user, accessToken, password).then(response => {
        if (response.Data) {
            dispatch({
                type: UPDATE_USER,
                payload: { user: response.Data },
            });

            // Loading true
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Profile Update Success Msg", { returnObjects: true }),
                    "toast_type": "success"
                }
            });
        }
        else {

            // Loading true
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Profile Update failed Msg", { returnObjects: true }),
                    "toast_type": "danger"
                }
            });
        }
    });
}

export { updateUserService }