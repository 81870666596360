import "../../Assets/scss/pages/manage-alt-text.scss";
import { addDefaultImgSrc } from "../../Helpers/addDefaultImgSrc";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import { Badge, Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import ShowRecordItem from "../../Components/ShowRecordItem";
import PaginationComponent from "../../Components/Pagination";
import { ManageImageAltTableCell } from "../../Components/ManageImageAltTableCell";
import { ImageAltListPropTypes, SelectedImageAltListPropTypes } from "../../PropTypes/ImageAltPropTypes";
import { AllUpdateImageAltTextService, getAllImageAltTextService } from "../../Services/ImageAltTextService";
import { debounce } from "lodash";
import NoRecordFound from "../../Components/NoRecordFound";
import InitMsgBlock from "../../Components/InitMsgBlock";
import FreeVersionOverlay from "../../Components/FreeVersionOverlay";

const ImageAltTextRemediationPage = () => {

  // Function
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pageID } = useParams();

  // Redux
  const { currentWebsite, accessToken, currentWebsitePlanStatus, isLoading } = useSelector((store: StoreProptypes) => store);

  // State
  const [altImageAPIRecall, setAltImageAPIRecall] = useState<boolean>(false);
  const [updateAltTextFlag, setUpdateAltTextFlag] = useState<boolean>(false);

  const [originalImgObj, setOriginalImgObj] = useState<SelectedImageAltListPropTypes[]>([]);
  const [renderImgObj, setRenderImgObj] = useState<SelectedImageAltListPropTypes[]>([]);
  const [selectedAltImgObj, setSelectedAltImgObj] = useState<SelectedImageAltListPropTypes[]>([]);
  const [imageAltErrorPopupObj, setImageAltErrorPopupObj] = useState<SelectedImageAltListPropTypes[]>([])

  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [filterValue, setFilterValue] = useState("1");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);

  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);

  const [showFreeWidgetStrip, setShowFreeWidgetStrip] = useState(false);

  const [totalMissingAltRecord, setTotalMissingAltRecord] = useState<number>();
  const [totalUpdateAltRecord, setTotalUpdateAltRecord] = useState<number>();
  const [totalDecorativeAltRecord, setTotalDecorativeAltRecord] = useState<number>();
  const [totalOtherRecord, setTotalOtherRecord] = useState<number>();
  const [allImageListRecord, setAllImageListRecord] = useState<number>(0);

  useEffect(() => {
    if (currentWebsite) {

      setTotalMissingAltRecord(currentWebsite.total_missing_alt_tag)
      setTotalUpdateAltRecord(currentWebsite.total_update_alt_text)
      setTotalDecorativeAltRecord(currentWebsite.total_decorative_images)
      setTotalOtherRecord(currentWebsite.total_other_images)

      if (filterValue === "1") {
        setAllImageListRecord(currentWebsite.total_missing_alt_tag);
      } else if (filterValue === "3") {
        setAllImageListRecord(currentWebsite.total_update_alt_text);
      } else if (filterValue === "4") {
        setAllImageListRecord(currentWebsite.total_decorative_images);
      } else if (filterValue === "5") {
        setAllImageListRecord(currentWebsite.total_other_images);
      } else {
        setAllImageListRecord(0);
      }
    }
  }, [currentWebsite, filterValue])

  const [imageAltErrorPopup, setImageAltErrorPopup] = useState(false);

  const terms = ""
  const sortByValue = "1";
  const prevLimitRef = useRef<number>(10);
  const prevFilterValueRef = useRef<string>('1');

  // For Free Widget
  useEffect(() => {
    if (currentWebsite) {
      if (currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0) {
        if (currentWebsite.current_package_only[0].is_free_widget === 1) {
          setShowFreeWidgetStrip(true);
        } else {
          setShowFreeWidgetStrip(false);
        }
      }
    }
  }, [currentWebsite]);

  // Filter Value
  useEffect(() => {
    setRenderImgObj([]);
    setSelectedAltImgObj([]);
    setImageAltErrorPopupObj([]);
    setOffset(0);
  }, [filterValue]);

  // Pagination
  useEffect(() => {
    setCurrentPage(1);
  }, [limit, filterValue]);

  useEffect(() => {
    setOffset(currentPage * limit - limit);
  }, [currentPage]);

  // Get Main List
  useEffect(() => {
    if (accessToken && currentWebsite && (limit === prevLimitRef.current) && (filterValue === prevFilterValueRef.current)) {
      getAllImageAltTextService(
        accessToken,
        currentWebsite.id,
        offset,
        limit,
        terms,
        filterValue,
        sortByValue,
        dispatch,
        t,
        setOriginalImgObj,
      );
    }
  }, [offset, currentWebsite, altImageAPIRecall, pageID]);

  useEffect(() => {
    if (((limit !== prevLimitRef.current) || (filterValue !== prevFilterValueRef.current)) && (offset === 0)) {
      if (accessToken && currentWebsite) {
        getAllImageAltTextService(
          accessToken,
          currentWebsite.id,
          offset,
          limit,
          terms,
          filterValue,
          sortByValue,
          dispatch,
          t,
          setOriginalImgObj,
        );
      }
      prevLimitRef.current = limit;
      prevFilterValueRef.current = filterValue;
    }
  }, [offset, limit, filterValue]);



  // Functionality

  // All Array Functions
  const checkDifference = (
    selectedAltImgObj: SelectedImageAltListPropTypes[],
    renderImgObj: SelectedImageAltListPropTypes[]
  ) => {
    const tempArray = renderImgObj.filter((originalItem) => {
      return !selectedAltImgObj.some((item) => originalItem.id === item.id);
    });
    return tempArray;
  };

  const mergeArray = (
    A: SelectedImageAltListPropTypes[],
    B: SelectedImageAltListPropTypes[]
  ) => {
    const tempArray = checkDifference(A, B);
    let mergedArray = [...A, ...tempArray];
    return mergedArray;
  };

  const popArray = (
    A: SelectedImageAltListPropTypes[],
    B: SelectedImageAltListPropTypes[]
  ) => {
    const tempArray = A.filter((item) => {
      return !B.some((Bitem) => item.id === Bitem.id);
    });
    return tempArray;
  };

  // Copy Array
  useEffect(() => {
    if (originalImgObj) {
      const tempImageAltTextListObj = originalImgObj.map((item) => {
        if (filterValue === "1") {
          const tempObj = {
            ...item,
            is_selected: selectedAltImgObj.some(selectedItem => selectedItem.id === item.id) ? 1 : 0,
            is_updated_from: item.ai_generated_text ? 2 : 1,
            temp_alt_text_updated: item.ai_generated_text ? item.ai_generated_text : item.alt_text,
          };
          return tempObj;
        } else {
          const tempObj = {
            ...item,
            is_selected: selectedAltImgObj.some(selectedItem => selectedItem.id === item.id) ? 1 : 0,
          };
          return tempObj;
        }
      });

      tempImageAltTextListObj && setRenderImgObj(tempImageAltTextListObj);
    } else {
      setRenderImgObj(originalImgObj);
    }
  }, [originalImgObj]);

  // Set Check all
  useEffect(() => {
    //console.info(renderImgObj.length);
    if (renderImgObj.length > 0) {
      if (checkDifference(selectedAltImgObj, renderImgObj).length) {
        setIsCheckAll(false);
      } else {
        setIsCheckAll(true);
      }
    } else {
      setIsCheckAll(false);
    }
  }, [renderImgObj]);

  const HandleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    let handleCheckFlag = e.target.checked;
    if (handleCheckFlag) {
      let tempCheckAllSelectedObj = renderImgObj.map((item) => {
        const tempObj = {
          ...item,
          is_selected: 1,
        }
        return tempObj;
      });
      setSelectedAltImgObj(mergeArray(selectedAltImgObj, tempCheckAllSelectedObj));
    } else {
      setRenderImgObj(originalImgObj);
      setSelectedAltImgObj(popArray(selectedAltImgObj, renderImgObj));
    }
  }

  useEffect(() => {
    if (selectedAltImgObj) {
      const tempImageAltTextListObj = renderImgObj.map((item) => {
        if (filterValue === "1") {
          const tempObj = {
            ...item,
            is_selected: selectedAltImgObj.some(selectedItem => selectedItem.id === item.id) ? 1 : 0,
            is_updated_from: item.ai_generated_text ? 2 : 1,
            temp_alt_text_updated: item.ai_generated_text
              ? item.ai_generated_text
              : item.alt_text,
          };
          return tempObj;
        } else {
          const tempObj = {
            ...item,
            is_selected: selectedAltImgObj.some(selectedItem => selectedItem.id === item.id) ? 1 : 0,
          };
          return tempObj;
        }
      });

      tempImageAltTextListObj && setRenderImgObj(tempImageAltTextListObj);
    }
  }, [selectedAltImgObj])

  // individualCheck Function
  const individualCheck = (item: SelectedImageAltListPropTypes, flag: boolean) => {
    if (flag) {
      setSelectedAltImgObj(mergeArray(selectedAltImgObj, [{ ...item, is_selected: 1 }]));
    } else {
      setSelectedAltImgObj(popArray(selectedAltImgObj, [item]));
      const findOriElement = originalImgObj.filter(oriObj => oriObj.id === item.id).map(oriFilterObj => {
        return oriFilterObj;
      })
      let tempCheckAllSelectedObj = renderImgObj.map((renderItem) => {
        if (renderItem.id === item.id) {
          return findOriElement[0];
        } else {
          return renderItem
        }
      });
      setRenderImgObj(tempCheckAllSelectedObj);
    }
  };

  // Decorative Switch
  const decorativeImageSwitch = (
    decorativeItem: SelectedImageAltListPropTypes,
    flag: boolean,
    newAltText?: string
  ) => {
    //console.info("decorativeItem -->", decorativeItem, flag, newAltText);
    if (renderImgObj) {
      const tempImageAltTextListObj1 = renderImgObj.map((item) => {
        let tempObj = item;

        if (decorativeItem.id === item.id) {
          if (flag) {
            tempObj = {
              ...item,
              is_decorative: 1,

            };
          } else {
            tempObj = {
              ...item,
              temp_alt_text_updated: newAltText ? newAltText : "",
              is_decorative: 0,

            };
          }
        }

        return tempObj;
      });

      setRenderImgObj([...tempImageAltTextListObj1]);


      //console.log("render img obj ===>", tempImageAltTextListObj1);
      //console.log("decorative img obj ===>", decorativeItem);

      // Selected

      if (selectedAltImgObj.some((item) => item.id === decorativeItem.id)) {

        let compareOriginalArraysObj = originalImgObj.filter(item => item.id === decorativeItem.id);

        const swichCondition = !!compareOriginalArraysObj[0].is_decorative === flag;
        const oriTextCondition = ((compareOriginalArraysObj[0].alt_text) === decorativeItem.alt_text);
        const updateTextCondition = compareOriginalArraysObj[0].alt_text_updated === decorativeItem.alt_text_updated;
        //const oriAITextCondition = compareOriginalArraysObj[0].ai_generated_text === decorativeItem.alt_text_updated;

        const oriTextWithNewTextCondition = (newAltText ? (compareOriginalArraysObj[0].alt_text === newAltText) : false);
        const updateTextWithNewTextCondition = (compareOriginalArraysObj[0].alt_text_updated ? (compareOriginalArraysObj[0].alt_text_updated) === newAltText : false);

        const tab1Condition = ((compareOriginalArraysObj[0].alt_text) === decorativeItem.temp_alt_text_updated);

        let finalCondition;

        if (filterValue === '5') {
          if (decorativeItem.is_open_textarea) {
            finalCondition = swichCondition && oriTextCondition && oriTextWithNewTextCondition;
          } else {
            finalCondition = swichCondition;
          }

        }

        if (filterValue === '3') {
          if (decorativeItem.is_open_textarea) {
            finalCondition = swichCondition && oriTextCondition && updateTextCondition && updateTextWithNewTextCondition;
          } else {
            finalCondition = swichCondition;
          }

        }

        if (filterValue === '4') {
          finalCondition = swichCondition;
        }

        if (filterValue === '1') {
          //console.log("Select image 125 ==>", newAltText)

          if ((decorativeItem.temp_alt_text_updated === '') && newAltText === '') {
            finalCondition = swichCondition && tab1Condition;
          } else {
            finalCondition = swichCondition;
          }

        }

        if (finalCondition) {
          const removeSelectedTempImageAltTextObj = selectedAltImgObj.filter(filterItem => filterItem.id === decorativeItem.id).map((item) => {
            let tempObj = item;
            if (flag) {
              tempObj = {
                ...item,
                is_decorative: 1,
                is_selected: 0,
              };
            } else {
              tempObj = {
                ...item,
                temp_alt_text_updated: newAltText ? newAltText : "",
                is_decorative: 0,
                is_selected: 0,
                is_open_textarea: 0
              };
            }
            return tempObj;
          })
          setSelectedAltImgObj(popArray(selectedAltImgObj, removeSelectedTempImageAltTextObj));
          //setRenderImgObj(mergeArray(renderImgObj, [ ...removeSelectedTempImageAltTextObj]))
        } else {
          const selectedTempImageAltTextListObj = selectedAltImgObj.map((item) => {
            let tempObj = item;
            if (decorativeItem.id === item.id) {
              if (flag) {
                tempObj = {
                  ...item,
                  is_decorative: 1,
                  is_selected: 1,
                };
              } else {
                tempObj = {
                  ...item,
                  temp_alt_text_updated: newAltText ? newAltText : "",
                  is_decorative: 0,
                  is_selected: 1,
                };
              }
            }
            return tempObj;
          });
          setSelectedAltImgObj([...selectedTempImageAltTextListObj]);
        }

      } else {

        let selectedTempImgObj = renderImgObj.filter((item) => item.id === decorativeItem.id).map((mapItem) => {
          let tempObj = mapItem;

          if (decorativeItem.id === mapItem.id) {
            if (flag) {
              tempObj = {
                ...mapItem,
                is_decorative: 1,
                is_selected: 1,
              };
            } else {
              tempObj = {
                ...mapItem,
                temp_alt_text_updated: newAltText ? newAltText : "",
                is_decorative: 0,
                is_selected: 1,
              };
            }
          }

          return tempObj;

        });

        setSelectedAltImgObj([...selectedAltImgObj, ...selectedTempImgObj]);

      }

    }

  };

  const debouncedUpdateAltTextHandler = useMemo(() => {
    return debounce(decorativeImageSwitch, 500);
  }, [renderImgObj]);

  // Copy Text
  const HandleCopyText = (
    id: number,
    text: string,
    radioType: number | null
  ) => {
    //console.log("HandleCopyText ==>", id, text, radioType)
    if (renderImgObj) {
      //console.log("HandleCopyText if loop");
      const tempImageAltTextListObj2 = renderImgObj.map((item) => {
        let tempObj = item;

        if (id === item.id) {
          //console.log("HandleCopyText if loop Item ==>", tempObj);
          if (text) {
            //console.log("HandleCopyText if loop Item ==>");
            tempObj = {
              ...item,
              temp_alt_text_updated: text,
              is_selected: 1,
              is_updated_from: radioType ? radioType : 0,
              is_open_textarea: 1,
            };
          }
        }
        return tempObj;
      });
      //console.log("tempImageAltTextListObj2 ==>", tempImageAltTextListObj2)
      setRenderImgObj([...tempImageAltTextListObj2]);

      // Selected Item
      if (selectedAltImgObj.some((item) => item.id === id)) {
        const selectedTempImageAltTextListObj = selectedAltImgObj.map((item) => {
          let tempObj = item;
          if (id === item.id) {
            if (text) {
              tempObj = {
                ...item,
                temp_alt_text_updated: text,
                is_selected: 1,
                is_updated_from: radioType ? radioType : 0,
                is_open_textarea: 1,
              };
            }
          }
          return tempObj;
        });

        setSelectedAltImgObj([...selectedTempImageAltTextListObj]);

      } else {
        //console.log("Here..")
        let selectedTempImgObj = renderImgObj.filter((item) => item.id === id).map((mapItem) => {
          let tempObj = mapItem;
          if (id === mapItem.id) {
            if (text) {
              tempObj = {
                ...mapItem,
                temp_alt_text_updated: text,
                is_selected: 1,
                is_updated_from: radioType ? radioType : 0,
                is_open_textarea: 1,
              };
            } else {
              if (filterValue === '1') {
                tempObj = {
                  ...mapItem,
                  temp_alt_text_updated: '',
                  is_selected: 1,
                  is_updated_from: 0,
                  is_open_textarea: 0,
                };
              }
            }
          }
          return tempObj;
        });

        if (filterValue === '1') {
          setRenderImgObj(mergeArray(renderImgObj, selectedTempImgObj));
        }

        setSelectedAltImgObj([...selectedAltImgObj, ...selectedTempImgObj]);

      }

    }
  };

  // Change Event
  const handleSelectImgObj = (selectTab1Item: SelectedImageAltListPropTypes, value: string) => {
    const selectedTempImageAltTextListObj = selectedAltImgObj.map((item) => {
      let tempObj = item;
      if (selectTab1Item.id === item.id) {
        if (value) {
          tempObj = {
            ...item,
            temp_alt_text_updated: value,
            is_selected: 1,
            is_updated_from: 0,
            is_open_textarea: 0,
          };
        }
      }
      return tempObj;
    });

    setSelectedAltImgObj([...selectedTempImageAltTextListObj]);
  }

  // onFocus Event
  const handleSelectImgObjOnFocus = (objItemId: number) => {
    HandleCopyText(objItemId, '', 0)
  }

  // Focus Relese
  const handleSelectImgObjOnBlur = (objItem: SelectedImageAltListPropTypes, selectObjValue: string) => {

    if (selectObjValue === '') {
      //objItem && setImageAltErrorPopupObj([objItem]);
      //setImageAltErrorPopup(true);
      setSelectedAltImgObj(popArray(selectedAltImgObj, [objItem]));
    }

  }


  // Error Image Popup
  const [isShowImagePopup, setIsShowImagePopup] = useState(false);
  const [imagePopupObj, setImagePopupObj] = useState<ImageAltListPropTypes | null>();

  const handleImagePopupClose = () => {
    setIsShowImagePopup(false);
    setImagePopupObj(null);
  };
  const handleImagePopupShow = (imageAltTextItem: ImageAltListPropTypes) => {
    setIsShowImagePopup(true);
    setImagePopupObj(imageAltTextItem);
  };


  // Submit Updated List
  const HandleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let SelectedImgObjCheck = selectedAltImgObj.filter((SelectedImgObj) => SelectedImgObj.is_decorative === 0 && SelectedImgObj.temp_alt_text_updated === "");

    if (SelectedImgObjCheck.length > 0) {

      SelectedImgObjCheck && setImageAltErrorPopupObj([...SelectedImgObjCheck]);
      setImageAltErrorPopup(true);

    } else {
      if (accessToken && selectedAltImgObj && currentWebsite) {
        AllUpdateImageAltTextService(accessToken, currentWebsite.id, dispatch, t, selectedAltImgObj).then((res) => {
          if (res.response.status === 200) {
            setSelectedAltImgObj([]);
            setImageAltErrorPopupObj([]);
            if (accessToken && currentWebsite) {
              getAllImageAltTextService(
                accessToken,
                currentWebsite.id,
                offset,
                limit,
                terms,
                filterValue,
                sortByValue,
                dispatch,
                t,
                setOriginalImgObj,
              );
            }
          }
        })
      }
    }
  };


  // Show information popup

  const [isShowInfoModal, setIsShowInfoModal] = useState<boolean>(false);
  const [infoModalType, setInfoModalType] = useState<number>(0);

  const handleShowInfoModal = (type: number) => {
    setInfoModalType(type);
    setIsShowInfoModal(true);
  }
  const handleHideInfoModal = () => {
    setIsShowInfoModal(false);
    setInfoModalType(0);
  }

  return (
    <>
      <DashboardPagesTemplate>

        <DashboardPageTitle
          pageTitle={`${t("Manage Alt Text Popup Title")}`}
          subPageTitle={`For ${currentWebsite?.domain}`}
        />

        <div className={`aioa_dashboard-manage-image-alt ${(currentWebsitePlanStatus.isInit || currentWebsitePlanStatus.isFreeWidget) ? `website-init-msg` : ``}`}>

          {(currentWebsitePlanStatus.isFreeWidget) ? (
            <>
              <FreeVersionOverlay />
            </>
          ) :
            <>
              {currentWebsitePlanStatus.isInit && (
                <>
                  <InitMsgBlock />
                </>
              )}
            </>

          }

          <div className="aioa_dashboard-image-list-filter-wrapper" id="aioa_dashboard-order-list-filter">

            <div className="aioa_dashboard-image-type-list-tab">
              <ul>
                <li>
                  <Button
                    size="lg"
                    className={`${filterValue === '1' ? `active` : ``}`}
                    onClick={(e) => setFilterValue("1")}
                    role="tab"
                    aria-selected={filterValue === "1" ? true : false}
                  >
                    {t("Recommended Alt Text Based on AI")} ({totalMissingAltRecord ? totalMissingAltRecord : 0})
                  </Button></li>
                <li>
                  <Button
                    size="lg"
                    className={`${filterValue === '3' ? `active` : ``}`}
                    onClick={(e) => setFilterValue("3")}
                    role="tab"
                    aria-selected={filterValue === "3" ? true : false}
                  >
                    {t('Remediated')} ({totalUpdateAltRecord ? totalUpdateAltRecord : 0})
                  </Button>
                </li>
                <li>
                  <Button
                    size="lg"
                    className={`${filterValue === '4' ? `active` : ``}`}
                    onClick={(e) => setFilterValue("4")}
                    role="tab"
                    aria-selected={filterValue === "4" ? true : false}
                  >
                    {t('Decorative Images')} ({totalDecorativeAltRecord ? totalDecorativeAltRecord : 0})
                  </Button>
                </li>
                <li>
                  <Button
                    size="lg"
                    className={`${filterValue === '5' ? `active` : ``}`}
                    onClick={(e) => setFilterValue("5")}
                    role="tab"
                    aria-selected={filterValue === "5" ? true : false}
                  >
                    {t('Other Images')} ({totalOtherRecord ? totalOtherRecord : 0})
                  </Button>
                </li>
              </ul>
            </div>

            <div className="aioa_dashboard-image-list-filter-block-list">
              <div className="aioa_dashboard-image-list-filter-block caption-text">
                <h2 className="h5 mb-0">
                  {filterValue === "1"
                    ? t("Recommended Alt Text Based on AI")
                    : filterValue === "2"
                      ? t("Images with Alt")
                      : filterValue === "3"
                        ? <>
                          <span>{t("Remediated")}</span>
                          <Button variant="link" className="info-btn" aria-label={t("Learn more about remediated alt text").toString()} onClick={() => handleShowInfoModal(2)}>
                            <i className="material-symbols-outlined" aria-hidden="true">info</i>
                          </Button>
                        </>
                        : filterValue === "4"
                          ? t("Decorative Images")
                          : t("Other Images")}
                </h2>
              </div>

              <div className="aioa_dashboard-image-list-filter-block">
                <Form.Label htmlFor='showPages'>{t("Items Per Page")}</Form.Label>
                <Form.Select
                  onChange={(e) => setLimit(parseInt(e.target.value))}
                  id="showPages"
                  value={limit}
                  aria-label={t("Items Per Page").toString()}
                >
                  <option value="10">{t("10")}</option>
                  <option value="20">{t("20")}</option>
                  <option value="40">{t("40")}</option>
                  <option value="80">{t("80")}</option>
                  <option value="100">{t("100")}</option>
                </Form.Select>
              </div>

              <div className="aioa_dashboard-image-list-filter-block d-sm-none">
                <Form.Check
                  className="form-check"
                  type="checkbox"
                  aria-label={t("Select all images").toString()}
                  label={t("Select all images").toString()}
                  onChange={(e) => HandleCheckAll(e)}
                  checked={isCheckAll}
                  disabled={showFreeWidgetStrip}
                />
              </div>

            </div>

          </div>

          <div className="aioa_dashboard-table">

            <div className="aioa_dashboard-table-thead">
              <div className="aioa_dashboard-table-tr">
                <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap" aria-hidden="true">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip
                        data-bs-html="true"
                        id="button-tooltip"
                      >
                        <span className="text-start">
                          {t("Select All")}
                        </span>
                      </Tooltip>
                    }
                  >
                    <Form.Check
                      className="form-check"
                      type="checkbox"
                      aria-label={t("Select all images").toString()}
                      onChange={(e) => HandleCheckAll(e)}
                      checked={isCheckAll}
                      disabled={showFreeWidgetStrip}
                    />
                  </OverlayTrigger>
                </div>
                <div className="aioa_dashboard-table-th tcw-250 text-center aioa_dashboard-table-cell-sidegap" aria-hidden="true">{t("Image")}</div>
                <div className="aioa_dashboard-table-th tcw-200 text-center aioa_dashboard-table-cell-sidegap" aria-hidden="true">
                  {t("Is Decorative?")}
                  <Button variant="link" className="info-btn" aria-label={t("Learn more about Decorative Images").toString()} onClick={() => handleShowInfoModal(3)}>
                    <i className="material-symbols-outlined" aria-hidden="true">info</i>
                  </Button>
                </div>
                <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap" aria-hidden="true">{t("Update Alt Text")}</div>
              </div>
            </div>

            <div className="aioa_dashboard-table-body" aria-live='polite'>
              {renderImgObj ? (
                <>
                  {renderImgObj.length > 0 ? (
                    <>
                      {renderImgObj.map((renderImgObjElement) => {

                        // Selected Object Condition
                        let imageAltTextItem: SelectedImageAltListPropTypes;
                        let selectedRenderImgObj = selectedAltImgObj.find((selectedItem) => selectedItem.id === renderImgObjElement.id);
                        if (selectedRenderImgObj) {
                          imageAltTextItem = selectedRenderImgObj;
                        } else {
                          imageAltTextItem = renderImgObjElement;
                        }

                        return (
                          <ManageImageAltTableCell
                            item={renderImgObjElement} key={renderImgObjElement.id}
                            rowClass={imageAltErrorPopupObj.find((errorImgObj) => errorImgObj.id === renderImgObjElement.id) ? "error-row" : ""}
                            showFreeWidgetStrip={showFreeWidgetStrip}
                            individualCheck={individualCheck}
                            handleImagePopupShow={handleImagePopupShow}
                            decorativeImageSwitch={decorativeImageSwitch}
                            filterValue={filterValue}
                            HandleCopyText={HandleCopyText}
                            handleSelectImgObj={handleSelectImgObj}
                            handleSelectImgObjOnFocus={handleSelectImgObjOnFocus}
                            handleSelectImgObjOnBlur={handleSelectImgObjOnBlur}
                            debouncedUpdateAltTextHandler={debouncedUpdateAltTextHandler}
                          />
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {filterValue === "1"
                        ? <NoRecordFound joyful={true} imageType={`img`} noRecordText={`${t('Recommended Alt Text message')}`} />
                        : filterValue === "3"
                          ? <NoRecordFound joyful={false} imageType={`img`} noRecordText={`${t('Remediated message')}`} />
                          : filterValue === "4"
                            ? <NoRecordFound joyful={false} imageType={`img`} noRecordText={`${t('Decorative Images message')}`} />
                            : <NoRecordFound joyful={false} imageType={`img`} noRecordText={`${t('Other Images message')}`} />
                      }
                    </>
                  )}
                </>
              ) : (
                <>

                </>
              )}
            </div>


            {/* Save Button */}
            {selectedAltImgObj.length > 0 && (
              <>
                <div
                  className="position-sticky bottom-0 bg-light py-3"
                  style={{ zIndex: 9 }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="me-3 ">
                      {selectedAltImgObj.length} {t("Selected Image")}
                    </span>
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      onClick={(e) => HandleSubmit(e)}
                      disabled={isLoading}
                    >
                      <>
                        {t("Save")}
                      </>
                    </Button>
                  </div>
                </div>
              </>
            )}

            <div className="aioa_dashboard-table-pagination-main">
              <>
                <ShowRecordItem
                  offset={offset}
                  limit={limit}
                  totalRecord={allImageListRecord}
                />
                <PaginationComponent
                  currentPage={currentPage}
                  totalRecords={allImageListRecord}
                  itemsPerPage={limit}
                  setCurrentPage={setCurrentPage}
                />
              </>
            </div>

          </div>

          {/* Show Image Info Modal */}
          <Modal show={isShowImagePopup} onHide={handleImagePopupClose} fullscreen="sm-down" backdrop="static" centered>
            <Modal.Header closeButton>
              <Modal.Title>&nbsp;</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {imagePopupObj && (
                <>
                  <div className="alt-text-box-img">
                    <div className="text-right">
                      {imagePopupObj.is_updated_from === 2 && imagePopupObj.ai_generated_text && (
                        <Badge
                          bg="danger"
                          className="mb-2"
                          style={{ borderRadius: "0" }}
                        >
                          {t("AI Based Alt Text")}
                        </Badge>
                      )}
                      <div className="text-center">
                        <img
                          onError={addDefaultImgSrc}
                          src={imagePopupObj.image_url}
                          alt={imagePopupObj.alt_text_updated}
                        />
                      </div>
                    </div>

                    <div className="mt-3 text-start">
                      {imagePopupObj.alt_text ? (
                        <>
                          <div className="">
                            <strong>{t("Original Alt Text")}</strong> :{" "}
                            {imagePopupObj.alt_text}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {imagePopupObj.ai_generated_text ? (
                        <>
                          <div className="">
                            <strong>{t("AI Alt Text")}</strong> :{" "}
                            {imagePopupObj.ai_generated_text}
                          </div>
                        </>
                      ) : (
                        <>
                        </>
                      )}

                      {imagePopupObj.alt_text_updated ? (
                        <>
                          <div className="">
                            <strong>{t("Updated Alt Text")}</strong> :{" "}
                            {imagePopupObj.alt_text_updated}
                          </div>
                        </>
                      ) : (
                        <>
                        </>
                      )}

                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>

          {/* Show Error Info Modal */}
          <Modal show={imageAltErrorPopup} onHide={() => setImageAltErrorPopup(false)} fullscreen="sm-down" backdrop="static" centered>
            <Modal.Header closeButton>
              <Modal.Title>{t("Error Updating Alt Text Popup Title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="errorpopup-content">
                <p>{t("Error Updating Alt Text Popup Description")}</p>
              </div>
            </Modal.Body>
            <Modal.Footer >
              <Button variant="primary" size="lg" className="m-0" onClick={() => setImageAltErrorPopup(false)}>{t("Okay")}</Button>
            </Modal.Footer>
          </Modal>

          {/* Show Info Modal */}
          <Modal show={isShowInfoModal} onHide={() => handleHideInfoModal()} fullscreen="sm-down" backdrop="static" centered>
            <Modal.Header closeButton>
              <Modal.Title>
                {infoModalType === 1 ? (
                  <>

                  </>
                ) : infoModalType === 2 ? (
                  <>
                    {t("Remediated")}
                  </>
                ) : infoModalType === 3 ? (
                  <>
                    {t("About Decorative Images")}
                  </>
                ) : (
                  <>

                  </>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {infoModalType === 1 ? (
                <>

                </>
              ) : infoModalType === 2 ? (
                <>
                  {t("Remediated Popup Description")}
                </>
              ) : infoModalType === 3 ? (
                <>
                  {t("Decorative Image Popup Description")}
                </>
              ) : (
                <>

                </>
              )}
            </Modal.Body>
          </Modal>

        </div>

      </DashboardPagesTemplate>

    </>
  )

}

export default ImageAltTextRemediationPage;