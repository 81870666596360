const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

const savePrePurchasedDomainApi = async (
    token: string,
    websiteURL: string,
    userID: number,
    planID: number,
): Promise<any> => {

    let url = `${baseURL}/add-pre-purchased-domain`;

    const data = new FormData();
    data.append("url", websiteURL);
    data.append("user_id", userID.toString());
    data.append("main_package_id", planID.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

export { savePrePurchasedDomainApi }