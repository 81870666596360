import i18n from "i18next";
import { getLanguagesApi, getTranslationsApi } from "../../Api/LanguageApi";
import { SET_ACTIVE_LANGUAGE, SET_LANGUAGE_KEYWORDS_LIST, SET_LANGUAGE_LIST, TRANSLATION_LOADING } from "../../Actions/Types";
import { LanguagePropTypes } from "../../PropTypes/LanguagePropTypes";

const getLanguagesList = (dispatch: Function) => {
    dispatch({
        type: TRANSLATION_LOADING,
        payload: true
    });

    getLanguagesApi().then(response => {
        if (response.Data) {

            // Store All keywords
            getTranslationsApi(response.Data[0].code).then(translationsResponse => {
                if (translationsResponse.Data) {

                    dispatch({
                        type: SET_LANGUAGE_LIST,
                        payload: response.Data,
                    });

                    dispatch({
                        type: SET_ACTIVE_LANGUAGE,
                        payload: response.Data[0],
                    });

                    dispatch({
                        type: SET_LANGUAGE_KEYWORDS_LIST,
                        payload: translationsResponse.Data,
                    });
                }
            })

        }
    });
}

const setLanguage = (languageObj: LanguagePropTypes, dispatch: Function) => {

    dispatch({
        type: TRANSLATION_LOADING,
        payload: true
    });

    getTranslationsApi(languageObj.code).then(translationsResponse => {
        if (translationsResponse.Data) {

            dispatch({
                type: SET_ACTIVE_LANGUAGE,
                payload: languageObj,
            });

            dispatch({
                type: SET_LANGUAGE_KEYWORDS_LIST,
                payload: translationsResponse.Data,
            });
        }
    })

}

const getTranslation = (languageCode: string) => {
    getTranslationsApi(languageCode).then(response => {
        if (response.Data) {
            i18n.addResources(
                languageCode,
                'translation',
                { ...response.Data },
            );
            i18n.changeLanguage(languageCode)
        }
    })
}

export { getLanguagesList, setLanguage, getTranslation };
