import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import { websiteListPropType } from "../../PropTypes/WebsitePropTypes";
import parseISODate from "../../Helpers/parseISODate";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import IsVerified from '../../Assets/img/verified.png';
import InVerified from '../../Assets/img/Inactive.png';
import PaymentButtonBlock from "../PaymentButton";

interface PropTypes {
    item: websiteListPropType,
    srno: number,
    handleSetCurrentWebsite: Function
}

const ActiveWebsiteListTableCellBlock = (props: PropTypes) => {
    // PropTypes
    const { item, srno, handleSetCurrentWebsite } = props;

    // Redux Data
    const { user } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    return (
        <div className="aioa_dashboard-table-tr">

            <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Sr No")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{srno}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Domain")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                    <div className="domain-name">
                        {item.is_verified === 1 ? (
                            <>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip data-bs-html="true" id="button-tooltip">
                                            <span className="text-start">{t("Widget is active")}</span>
                                        </Tooltip>
                                    }
                                >
                                    <div className={`domain-verify-icon`}>
                                        <img src={IsVerified} alt='' />
                                    </div>
                                </OverlayTrigger>
                            </>
                        ) : (
                            <>
                                {item.widget_last_use_date ? (
                                    <>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip data-bs-html="true" id="button-tooltip">
                                                    <span className="text-start">{t(`Widget was lastly used on ${parseISODate(item.widget_last_use_date)}`)}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={`domain-verify-icon`}>
                                                <img src={InVerified} alt='' />
                                            </div>
                                        </OverlayTrigger>
                                    </>
                                ) : (
                                    <>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip data-bs-html="true" id="button-tooltip">
                                                    <span className="text-start">{t("Widget activation is pending.")}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={`domain-verify-icon`}>
                                                <img src={InVerified} alt='' />
                                            </div>
                                        </OverlayTrigger>
                                    </>
                                )}
                            </>
                        )}
                        {item.domain}
                    </div>
                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Plan")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Plan").toString()}>{item.current_package_only[0].name}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Status")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}><strong className="text-success">{t('Active')}</strong></div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Price(USD)")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Price(USD)").toString()}>
                    {item.is_show_price !== 1 ? (
                        <>
                            ${item.current_package_only[0].final_price}
                        </>
                    ) : (
                        <>
                            -
                        </>
                    )}

                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Renew Date")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Renew Date").toString()}>{parseISODate(item.current_package_only[0].end_date)}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-100 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Manage Plan")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Manage Plan").toString()}>
                    <Button variant="link" onClick={() => handleSetCurrentWebsite(item.id)}>
                        <i className="material-symbols-outlined" aria-hidden="true">settings</i>
                    </Button>
                </div>
            </div>

        </div>
    )
}

export default ActiveWebsiteListTableCellBlock;