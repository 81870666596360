import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { saveNormalWebsiteApi } from "../../Api/DomainApi/SaveNormalDomainApi"
import encodeBase64 from "../../Helpers/encodeBase64";
import { getDomainListService } from "./DomainList";

const saveNormalDomainService = (
    token: string,
    newWebsiteURL: string,
    userID: number,
    newWebsitePlanID: number,
    newWebsitePlanType: string,
    activeLanguageCode: string,
    freeVersionFlag: boolean,
    dispatch: Function,
    navigate: Function,
    t: Function
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    saveNormalWebsiteApi(token, newWebsiteURL, userID, newWebsitePlanID).then((res) => {
        if (res.Data) {
            const urlString = `${newWebsitePlanID}|${newWebsiteURL.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]}|${newWebsitePlanType}|${userID}|${activeLanguageCode}|1`;
            const encodedString = encodeBase64(urlString);
            if (freeVersionFlag) {
                getDomainListService(token, dispatch).then(() => {
                    // Set Loading
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });

                    dispatch({
                        type: SET_MESSAGE,
                        payload: {
                            "toast_message": t('Domain Added Successfully'),
                            "toast_type": "success"
                        }
                    });

                    
                    navigate(`/front/orders?website_update=true`);
                    
                });
            }
            else {
                // Set Loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });
                navigate(`/front/package/${encodedString}`);
            }



        } else {
            //console.info(res.error);
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": res.error,
                    "toast_type": "danger"
                }
            });

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

export { saveNormalDomainService }