import { useTranslation } from "react-i18next";
import "../../Assets/scss/components/footer.scss";

const DashboardFooter = () => {
    const { t } = useTranslation();

    const year = new Date().getFullYear();

    return (
        <>
            <footer className="aioa_dashboard-footer">
                <div className="aioa_dashboard-footer-copyright">© {year}, <a href='https://www.skynettechnologies.com/' target="_blank" rel="noreferrer">Skynet Technologies</a></div>
                <div className="aioa_dashboard-footer-nav">
                    <ul className={`nav`}>
                        <li className="nav-item">
                            <a href="https://www.skynettechnologies.com/knowledge-base" className="nav-link" target="_blank" rel="noreferrer">{t("knowledge base menu label")}</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.skynettechnologies.com/terms-conditions" className="nav-link" target="_blank" rel="noreferrer">{t("Terms & Conditions")}</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.skynettechnologies.com/privacy-policy" className="nav-link" target="_blank" rel="noreferrer">{t("Privacy Policy")}</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.skynettechnologies.com/disclaimer" className="nav-link" target="_blank" rel="noreferrer">{t("Disclaimer")}</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.skynettechnologies.com/accessibility-statement" className="nav-link" target="_blank" rel="noreferrer">{t("Accessibility Statement")}</a>
                        </li>
                    </ul>
                </div>
            </footer>
        </>
    )
}

export default DashboardFooter;