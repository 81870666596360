import { LOADING, SET_WEBSITE_LIST } from "../../Actions/Types";
import { getDomainListApi, getDomainListWithDetailsApi } from "../../Api/DomainApi/DomainListWithDetailsApi";

const getDomainListWithDetailsService = (
    token: string,
    dispatch: Function,
    t: Function,
    setDomainList: Function,
    limit: number,
    offset: number,
    terms: string,
    statusFilter: number,
    startDate : string, 
    endDate : string,
    setTotalMainRecord: Function,
    setTotalRecord: Function
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    getDomainListWithDetailsApi(token, limit, offset, terms, statusFilter, startDate, endDate).then((res) => {
        if (res.status === 200) {

            // Set Website List
            setDomainList(res.Data);

            // Set Record Record
            setTotalRecord(res.total_records);

            // Set Total Record
            setTotalMainRecord(res.total_records_main);

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

const getDomainListService = async (
    token: string,
    dispatch: Function,
): Promise<any> => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    getDomainListApi(token).then((listRes) => {
        if (listRes.status === 200) {

            // Set Website List
            dispatch({
                type: SET_WEBSITE_LIST,
                payload: listRes.Data
            })

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

        } else {

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    });

    return {status:200}

}

export { getDomainListWithDetailsService, getDomainListService }