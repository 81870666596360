import { useEffect, useState } from "react";
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { getSurveyDataService } from "../../Services/SurveyDataService";
import { SurveyPropTypes } from "../../PropTypes/SurveyPropTypes";

interface PropTypes {
    surveyFormReason: number;
    setSurveyFormReason: Function,
    surveyFormReasonText: string,
    setSurveyFormReasonText: Function
}

const SurveyForm = (prop: PropTypes) => {

    const { surveyFormReason, setSurveyFormReason, surveyFormReasonText, setSurveyFormReasonText } = prop;

    const { t } = useTranslation();

    const [surveyData, setSurveyData] = useState<SurveyPropTypes[]>();

    useEffect(() => {
        getSurveyDataService(setSurveyData);
    }, [])

    return (
        <>
            <div className="survey-form-main">
                <Form.Group className="mb-3" controlId="survey-dropdown">
                    <Form.Label>{t('Reason for cancellation')} <strong className="text-danger">*</strong></Form.Label>
                    <Form.Select size="lg" defaultValue={surveyFormReason} onChange={(e) => setSurveyFormReason(parseInt(e.target.value))}>
                        <option value={0}>Please Select</option>
                        {surveyData && surveyData.length > 0 && (
                            <>
                                {surveyData.map((surveyItem) => {
                                    return (
                                        <option value={surveyItem.id} key={surveyItem.id}>{surveyItem.title}</option>
                                    )
                                })}
                            </>
                        )}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="survey-textarea">
                    <Form.Label>{t('Details')}</Form.Label>
                    <Form.Control
                        size="lg"
                        defaultValue={surveyFormReasonText}
                        onChange={(e) => setSurveyFormReasonText(e.target.value)}
                        className="border"
                        as="textarea"
                        placeholder={`${t('Tell us more about your reason for cancellation')}`}
                    />
                </Form.Group>
            </div>
            <div className="survey-form-footer-title">
                <p className="text-danger mb-0"><strong>{t('note label')}</strong>: {t('subscription cancellation note')}</p>
            </div>
        </>
    )
}

export default SurveyForm;