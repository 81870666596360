import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { StoreProptypes } from '../PropTypes/StoreProptypes';

interface PropTypes {
  userid: number | null;
  children: React.ReactElement<any, any>
}

const ProtectedRoute = ({ userid, children }: PropTypes) => {
  const { currentUserStatus } = useSelector((store: StoreProptypes) => store);
  
  if (!userid) {
    return <Navigate to={`/front/login${currentUserStatus.currentUserType === 5 ? '?app=readwritemadesimple' : ''}`} replace />;
  }

  return children;
};

export default ProtectedRoute