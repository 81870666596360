import { HelmetProvider } from 'react-helmet-async';
import BrowserRouterComponents from './Router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { API_RECALL } from './Actions/Types';

function App() {

  const dispatch = useDispatch();

  const helmetContext = {};

  const setCookie = (name: string, value: string) => {
    let inFifteenMinutes = new Date(new Date().getTime() + 60 * 60 * 1000);
    Cookies.set(name, value, { expires: inFifteenMinutes });
  };

  // Get cookie value
  const getCookie = (name: string): string | undefined => {
    return Cookies.get(name);
  };

  // Check if cookie is expired (returns true if expired)
  const isCookieExpired = (name: string): boolean => {
    const value = getCookie(name);
    return value === undefined;
  };

  if (isCookieExpired("apiDataRecall")) {
    setCookie("apiDataRecall", 'true');

    // Loading
    dispatch({
      type: API_RECALL,
      payload: true,
    });
    
  } else {
    //console.info("Cookie Not Expired ==>");
  }

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <BrowserRouterComponents />
      </HelmetProvider >
    </>
  );
}

export default App;
