import DashboardLanguageButton from "../Components/LanguageButton";
import MainPageTemplate from "./MainPageTemplate";

interface PropTypes {
    children: any
}
const DashboardIframePagesTemplate = (prop: PropTypes) => {
    // Set Props
    const { children } = prop;
    return (
        <>
            <MainPageTemplate>
                <div className="aioa_dashboard-content-wrapper">
                    <main id="main">
                        <div className="d-none">
                            <DashboardLanguageButton />
                        </div>
                        <div className="aioa_dashboard-page-content aioa_dashboard-pages">
                            {children}
                        </div>
                    </main>
                </div>
            </MainPageTemplate>
        </>
    )
}

export default DashboardIframePagesTemplate;