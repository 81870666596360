import { Card } from "react-bootstrap"
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";

const PlatformWidgetCurrentActivePlanInfo = () => { // only Normal user Plan Details

    // Functions
    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-user">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Current Plan')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{t(`${currentWebsite.current_package_only[0].name} Plan`)}</h4>
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    <strong className="original-price">{t('Manage by ')} {currentWebsite.platform}</strong>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}


export default PlatformWidgetCurrentActivePlanInfo;