import { useSelector } from "react-redux";
import { StoreProptypes } from "../PropTypes/StoreProptypes";
interface PropTypes {
    price: number,
    isDiscountPr?: boolean,
    discountPr?: number
}

const StrikePrice = (props: PropTypes) => {
    const { price, isDiscountPr, discountPr } = props;

    let strikePrice: number = price;

    // Redux Data
    const { currentUserDiscount } = useSelector((store: StoreProptypes) => store);

    if (isDiscountPr && discountPr && discountPr > 0) {
        strikePrice = price / 100 * (100 - discountPr);
    } else if (currentUserDiscount) {
        if (currentUserDiscount.discount_type === 2) {
            strikePrice = price / 100 * (100 - currentUserDiscount.discount_value);
        }
    } else {

    }

    return <>{strikePrice.toFixed(2)}</>;
}

export default StrikePrice;