import "../../Assets/scss/pages/pdf-remediation.scss";

import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Form, Button, Modal, OverlayTrigger, Tooltip, Placeholder, Badge, Alert } from "react-bootstrap";
import PaginationComponent from "../../Components/Pagination";
import NoRecordFound from "../../Components/NoRecordFound";
import parseISODate from "../../Helpers/parseISODate";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import { cancelRemediationQuotePDF, directPaymentService, getPDFListService } from "../../Services/PDFService";
import { PDFListPropTypes } from "../../PropTypes/PDFListPropTypes";
import ShowRecordItem from "../../Components/ShowRecordItem";
import InitMsgBlock from "../../Components/InitMsgBlock";
import FreeVersionOverlay from "../../Components/FreeVersionOverlay";

const PDFList = () => {

  // Function
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux
  const { currentWebsite, accessToken, activeLanguage, user, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);


  const [apiRecall, setApiRecall] = useState<boolean>(false);
  const [tabStep, setTabStep] = useState<number>(0);

  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [disabledCheckBox, setDisabledCheckBox] = useState<boolean>(false);

  const [pdfList, setPDFList] = useState<PDFListPropTypes[]>();
  const [renderPDFList, setRenderPDFList] = useState<PDFListPropTypes[]>();
  const [selectPdf, setSelectPdf] = useState<PDFListPropTypes[]>([]);
  const [selectPdfID, setSelectPdfID] = useState<string[]>([]);

  const [selectPdfPrice, setSelectPdfPrice] = useState<number>(0);

  const [filter, setFilter] = useState("all");
  const [sortBy, setSortBy] = useState("1");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [terms, setTerms] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(false);
  const [showAlert, setAlert] = useState(false);

  const [totalRecordWithoutFilter, setTotalRecordWithoutFilter] = useState<number>(0);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [totalInaccessiblePdfs, setTotalInaccessiblePdfs] = useState<number>(0);
  const [totalQuotePdfs, setTotalQuotePdfs] = useState<number>(0);
  const [totalRemediatedPdfs, setTotalRemediatedPdfs] = useState<number>(0);
  const [totalQuotesPDFsPrice, setTotalQuotesPDFsPrice] = useState<number>(0);
  const [purchaseUrl, setPurchaseUrl] = useState('');

  const [prePurchasePDFTotalCount, setPrePurchasePDFTotalCount] = useState<number>(0);
  const [prePurchasePDFRemainingCount, setPrePurchasePDFRemainingCount] = useState<number>(0);

  const prevLimitRef = useRef<number>(10);

  const searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerms(e.target.value);
  };


  useEffect(() => {
    setCurrentPage(1);
  }, [limit, filter]);

  useEffect(() => {
    setOffset(0);
  }, [filter, sortBy]);

  useEffect(() => {
    setOffset(currentPage * limit - limit);
  }, [currentPage, limit]);

  useEffect(() => {
    if (tabStep === 1) {
      setFilter('4');
    } else {
      setFilter('all');
    }
  }, [tabStep]);

  useEffect(() => {
    if (accessToken && currentWebsite && (limit === prevLimitRef.current)) {
      getPDFListService(
        accessToken,
        dispatch,
        setPDFList,
        setTotalRecord,
        setTotalInaccessiblePdfs,
        setTotalQuotePdfs,
        setTotalRemediatedPdfs,
        setTotalQuotesPDFsPrice,
        setPurchaseUrl,
        offset,
        limit,
        currentWebsite.id,
        setTotalRecordWithoutFilter,
        setPrePurchasePDFTotalCount,
        setPrePurchasePDFRemainingCount,
        terms,
        filter,
        sortBy
      );
    }
  }, [offset, currentWebsite, terms, filter, accessToken, sortBy, showAlert, apiRecall]);

  useEffect(() => {

    if (limit !== prevLimitRef.current && offset === 0) {

      if (accessToken && currentWebsite) {
        getPDFListService(
          accessToken,
          dispatch,
          setPDFList,
          setTotalRecord,
          setTotalInaccessiblePdfs,
          setTotalQuotePdfs,
          setTotalRemediatedPdfs,
          setTotalQuotesPDFsPrice,
          setPurchaseUrl,
          offset,
          limit,
          currentWebsite.id,
          setTotalRecordWithoutFilter,
          setPrePurchasePDFTotalCount,
          setPrePurchasePDFRemainingCount,
          terms,
          filter,
          sortBy
        );
      }

      prevLimitRef.current = limit;
    }

  }, [offset, limit]);


  // All Check

  // Copy Array
  useEffect(() => {
    if (pdfList) {
      const tempImageAltTextListObj = pdfList.map((item) => {
        const tempObj = {
          ...item,
          is_selected: selectPdf.some(selectedItem => selectedItem.id === item.id),
        };
        return tempObj;
      });

      tempImageAltTextListObj && setRenderPDFList(tempImageAltTextListObj);
    } else {
      setRenderPDFList(pdfList);
    }
  }, [pdfList]);

  const checkDifference = (
    selectedPDFObj: PDFListPropTypes[],
    renderPDFObj: PDFListPropTypes[]
  ) => {
    const tempArray = renderPDFObj.filter((originalItem) => {
      return !selectedPDFObj.some((item) => ((originalItem.id === item.id)));
    });
    return tempArray;
  };

  const mergeArray = (
    A: PDFListPropTypes[],
    B: PDFListPropTypes[]
  ) => {
    const tempArray = checkDifference(A, B);
    let mergedArray = [...A, ...tempArray];
    return mergedArray;
  };

  const popArray = (
    A: PDFListPropTypes[],
    B: PDFListPropTypes[]
  ) => {
    const tempArray = A.filter((item) => {
      return !B.some((Bitem) => item.id === Bitem.id);
    });
    return tempArray;
  };


  useEffect(() => {
    if (renderPDFList && (checkDifference(selectPdf, renderPDFList.filter(item => !item.is_requested)).length > 0)) {
      setIsCheckAll(false);
    } else {
      setIsCheckAll(true);
    }

    if (selectPdf.length === 0 && renderPDFList && (checkDifference(selectPdf, renderPDFList.filter(item => !item.is_requested)).length === 0)) {
      setIsCheckAll(false);
      setDisabledCheckBox(true);
    } else {
      setDisabledCheckBox(false);
    }

  }, [renderPDFList]);


  const HandleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    let handleCheckFlag = e.target.checked;
    //console.log("All PDF added...", handleCheckFlag, renderPDFList, (handleCheckFlag && renderPDFList))
    if (handleCheckFlag && renderPDFList) {
      //console.log("All PDF added...")
      let tempCheckAllSelectedObj = renderPDFList.filter((checkFilterItem) => !checkFilterItem.is_requested).map((item) => {
        const tempObj = {
          ...item,
          is_selected: true,
        }
        return tempObj;
      });
      setSelectPdf(mergeArray(selectPdf, tempCheckAllSelectedObj));
    } else {
      renderPDFList && setSelectPdf(popArray(selectPdf, renderPDFList));
    }
  }

  useEffect(() => {
    if (selectPdf && renderPDFList) {
      const tempImageAltTextListObj = renderPDFList.map((item) => {
        const tempObj = {
          ...item,
          is_selected: selectPdf.some(selectedItem => selectedItem.id === item.id),
        };
        return tempObj;
      });

      tempImageAltTextListObj && setRenderPDFList(tempImageAltTextListObj);
    }
  }, [selectPdf])

  const individualCheck = (item: PDFListPropTypes, flag: boolean) => {
    //console.log("Update changes ==>", flag, item )
    if (flag) {
      setSelectPdf(mergeArray(selectPdf, [{ ...item, is_selected: true }]));
    } else {
      setSelectPdf(popArray(selectPdf, [item]));
    }
  };

  // Send IDs
  useEffect(() => {
    if (selectPdf) {
      selectPdf && setSelectPdfID(
        selectPdf.map((item) => item.id.toString())
      );
      // Price  
      const sum = selectPdf.reduce((accumulator, object) => {
        return accumulator + object.total_price;
      }, 0);
      selectPdf && setSelectPdfPrice(sum);
    } else {
      setSelectPdfID([]);
      setSelectPdfPrice(0)
    }
  }, [selectPdf]);

  // Popup

  /*  const handleRemediationQuotePopupShow = () => {
     setShowModal(true);
   }; */
  const handleRemediationQuotePopupClose = () => {
    setShowModal(false);
  }

  // payment api
  const [paymentBtnLoading, setPaymentBtnLoading] = useState(false);
  const handleDirectPaymentRequest = (flag?: number) => {
    if (selectPdfID && accessToken && currentWebsite) {
      directPaymentService(
        accessToken,
        currentWebsite.id,
        selectPdfID.join(),
        setPaymentBtnLoading,
        setSelectPdf,
        navigate,
        setLoading,
        setPDFList,
        setTotalRecord,
        setTotalInaccessiblePdfs,
        setTotalQuotePdfs,
        setTotalRemediatedPdfs,
        setTotalQuotesPDFsPrice,
        setPurchaseUrl,
        offset,
        limit,
        setTotalRecordWithoutFilter,
        setPrePurchasePDFTotalCount,
        setPrePurchasePDFRemainingCount,
        flag,
      );
      //getPaymentLinkService(accessToken, currentWebsite.id, selectPdfID.join(), setPaymentBtnLoading, setSelectPdf);
    }
  }


  // Status popup
  const [showStatusModalFlag, setShowStatusModalFlag] = useState<boolean>(false);
  const [showStatusCode, setShowStatusCode] = useState<number | null>(1);

  const handleShowStatusPopup = (statusCode: number) => {
    setShowStatusModalFlag(true);
    setShowStatusCode(statusCode);
  }
  const handleCloseStatusPopup = () => {
    setShowStatusModalFlag(false);
    setShowStatusCode(null);
  }



  // Remediation Quote Button

  //const [remediationQuoteButton, setRemediationQuoteButton] = useState(false);
  const [remediationQuotePaymentButton, setRemediationQuotePaymentButton] = useState(false);
  const [remediationQuoteRemoveButton, setRemediationQuoteRemoveButton] = useState(false);

  useEffect(() => {
    if (selectPdf) {

      /* const pdfRemediationButton = selectPdf.filter((item) => item.request_status === 0).length
      if (pdfRemediationButton > 0) {
        setRemediationQuoteButton(true);
      } else {
        setRemediationQuoteButton(false);
      } */

      const pdfRemediationPaymentButton = selectPdf.filter((item) => ((item.request_status === 0))).length
      if (pdfRemediationPaymentButton > 0) {
        setRemediationQuotePaymentButton(true);
      } else {
        setRemediationQuotePaymentButton(false);
      }

      /* const pdfRemediationRemoveButton = selectPdf.filter((item) => ((item.request_status === 0))).length
      if (pdfRemediationRemoveButton > 0) {
        setRemediationQuoteRemoveButton(true);
      } else {
        setRemediationQuoteRemoveButton(false);
      } */

    }
  }, [selectPdf]);

  // Status popup
  const [remediationQuoteRemoveModalFlag, setShowRemediationQuoteRemoveModalFlag] = useState<boolean>(false);

  const handleShowRemediationQuotePopup = () => {
    setShowRemediationQuoteRemoveModalFlag(true);
  }
  const handleCloseRemediationQuotePopup = () => {
    setShowRemediationQuoteRemoveModalFlag(false);
  }

  //
  const [userName, setUserName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userPhone, setUserPhone] = useState<string>('');
  //const [userComment, setUserComment] = useState<string>('');
  const [loadingPopupRequest, setLoadingPopupRequest] = useState<string>('');

  useEffect(() => {
    if (user) {
      user.name && setUserName(user.name);
      user.email && setUserEmail(user.email);
      user.phone && setUserPhone(user.phone);
    }
  }, [user])

  const handleCancelRemediationQuotePDF = () => {
    if (accessToken && currentWebsite && userName && userEmail && userPhone) {
      cancelRemediationQuotePDF(
        accessToken,
        userName,
        userEmail,
        userPhone,
        'Cancel PDF Remediation Request',
        currentWebsite.id,
        0,
        1,
        1,
        selectPdfID.join(),
        setLoadingPopupRequest,
        apiRecall,
        setApiRecall,
        setShowRemediationQuoteRemoveModalFlag,
        t,
        dispatch
      );
    }
    setSelectPdf([]);
    setSelectPdfID([]);
  }

  // API Recall
  useEffect(() => {
    if (!showModal && message) {
      setApiRecall(!apiRecall);
      setMessage(false);
      setSelectPdf([]);
      setSelectPdfID([]);
    }
  }, [showModal, message,])


  /* useEffect(() => {
    if (accessToken && currentWebsite && currentWebsitePlanStatus && currentWebsitePlanStatus.siteInit) {
      setCurrentDomainDetailDataService(accessToken, currentWebsite.id, dispatch)
    }
  }, [totalRecord]); */


  const [showEstimatedPricingMsgFlag, setShowEstimatedPricingMsgFlag] = useState(false);

  useEffect(() => {
    if (selectPdf.length > 0) {
      let pageConditionCheck = selectPdf.filter((checkFilterItem) => checkFilterItem.pages === 0).length > 0;
      if (pageConditionCheck) {
        setShowEstimatedPricingMsgFlag(true);
      } else {
        setShowEstimatedPricingMsgFlag(false);
      }
    }
  }, [selectPdf])


  useEffect(() => {
    if (currentWebsite) {
      setSelectPdf([]);
    }
  }, [currentWebsite])


  //const [apiLoading, setApiLoading] = useState(false);


  return (
    <>
      <DashboardPageTitle
        pageTitle={`${t("Documents & PDFs")}`}
        subPageTitle={`${t('dashboard page sub title', { domainName: currentWebsite?.domain })}`}
      />

      <div className={`aioa_dashboard-pdf-remediation ${(currentWebsitePlanStatus.isInit || currentWebsitePlanStatus.isFreeWidget) ? `website-init-msg` : ``}`}>

        {(currentWebsitePlanStatus.isFreeWidget) ? (
          <>
            <FreeVersionOverlay />
          </>
        ) :
          <>
            {currentWebsitePlanStatus.isInit && (
              <>
                <InitMsgBlock />
              </>
            )}
          </>

        }

        <div className="aioa_dashboard-pdf-remediation-filter-wrapper">

          <div className="aioa_dashboard-pdf-remediation-type-list-tab">
            <ul>
              <li>
                <Button
                  size="lg"
                  className={`${tabStep === 0 ? `active` : ``}`}
                  onClick={(e) => setTabStep(0)}
                  role="tab"
                  aria-selected={tabStep === 0 ? true : false}
                >
                  {t("Inaccessible PDF")} ({totalInaccessiblePdfs ? totalInaccessiblePdfs : 0})
                </Button>
              </li>
              <li>
                <Button
                  size="lg"
                  className={`${tabStep === 1 ? `active` : ``}`}
                  onClick={(e) => { setTabStep(1); setFilter('4') }}
                  role="tab"
                  aria-selected={tabStep === 1 ? true : false}
                >
                  {t("Remediated PDF")} ({totalRemediatedPdfs ? totalRemediatedPdfs : 0})
                </Button>
              </li>
            </ul>
          </div>

          <div className="aioa_dashboard-pdf-remediation-filter-block-wrapper">

            <div className="aioa_dashboard-pdf-remediation-filter-block caption-text">
              <h2 className="h5 mb-0">
                {tabStep === 1 ? (
                  <>
                    {t("Following are list of remediated PDF")}
                  </>
                ) : (
                  <>
                    {t("Select PDF URL to Request Remediation services")}
                  </>
                )}
              </h2>
            </div>

            <div className="aioa_dashboard-pdf-remediation-filter-block-list">

              {!(filter === '4') ? (
                <>
                  <div className="aioa_dashboard-pdf-remediation-filter-block">
                    <Form.Label htmlFor="pdf_type">{t("PDF Status")}</Form.Label>
                    <Form.Select
                      onChange={(e) => setFilter(e.target.value)}
                      id="pdf_type"
                      value={filter}
                      aria-label={t("PDF Status").toString()}
                    >
                      <option value="all">{t("All PDFs")}</option>
                      <option value="0">{t("Pending")}</option>
                      <option value="3">{t("Processing")}</option>
                    </Form.Select>
                  </div>
                </>
              ) : (
                <>

                </>
              )}

              <div className="aioa_dashboard-pdf-remediation-filter-block">
                <Form.Label htmlFor='showPages'>{t("Items Per Page")}</Form.Label>
                <Form.Select
                  onChange={(e) => setLimit(parseInt(e.target.value))}
                  id="showPages"
                  value={limit}
                  aria-label={t("Items Per Page").toString()}
                >
                  <option value="10">{t("10")}</option>
                  <option value="20">{t("20")}</option>
                  <option value="40">{t("40")}</option>
                  <option value="80">{t("80")}</option>
                  <option value="100">{t("100")}</option>
                </Form.Select>
              </div>

            </div>

          </div>

        </div>

        <div className="aioa_dashboard-table">
          <div className="aioa_dashboard-table-thead">
            <div className="aioa_dashboard-table-tr">

              {(filter === 'all') || (filter === '0') || (filter === '3') ? (
                <>
                  {(filter === '3') ? (
                    <>
                      <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap" aria-hidden="true"></div>
                    </>
                  ) : (
                    <>
                      <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap" aria-hidden="true">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip
                              data-bs-html="true"
                              id="button-tooltip"
                            >
                              <span className="text-start">
                                {t("Select All")}
                              </span>
                            </Tooltip>
                          }
                        >
                          <Form.Check
                            className="p-0 m-0 form-check"
                            type="checkbox"
                            aria-label={t("Select all PDFs").toString()}
                            onChange={(e) => HandleCheckAll(e)}
                            checked={isCheckAll}
                            disabled={disabledCheckBox}
                          />
                        </OverlayTrigger>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap" aria-hidden="true">{t("Sr No")}</div>
                </>
              )}

              <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap" aria-hidden="true">
                {t("PDF URL")}
              </div>

              {(filter === '4') ? (
                <>
                  <div className="aioa_dashboard-table-th tcw-200 aioa_dashboard-table-cell-sidegap text-center" aria-hidden="true">{t("Submitted Date")}</div>
                  <div className="aioa_dashboard-table-th tcw-200 aioa_dashboard-table-cell-sidegap text-center" aria-hidden="true">{t("Completed Date")}</div>
                </>
              ) : (
                <></>
              )}
              <div className="aioa_dashboard-table-th tcw-150 aioa_dashboard-table-cell-sidegap text-center" aria-hidden="true">
                {t("Pages")}
              </div>
              <div className="aioa_dashboard-table-th tcw-150 aioa_dashboard-table-cell-sidegap text-center" aria-hidden="true">
                {t("Price(USD)")}
              </div>
              {(filter === '0') || (filter === 'all') || (filter === '3') ? (
                <>
                  <div className="aioa_dashboard-table-th tcw-200 text-center aioa_dashboard-table-cell-sidegap" aria-hidden="true">
                    {t("Status")}
                  </div>
                </>
              ) : (
                <>

                </>
              )}

            </div>
          </div>
          <div className="aioa_dashboard-table-body" aria-live="polite">
            {renderPDFList ? (
              <>
                {renderPDFList.length > 0 ? (
                  <>
                    {renderPDFList.map((renderPDF, index) => {

                      let pdf: PDFListPropTypes;
                      let selectedRenderPDFObj = selectPdf.find((selectedItem) => selectedItem.id === renderPDF.id)
                      if (selectedRenderPDFObj) {
                        pdf = selectedRenderPDFObj;
                      } else {
                        pdf = renderPDF;
                      }

                      return (
                        <>
                          <div className="aioa_dashboard-table-tr" key={pdf.id}>
                            {(filter === 'all') || (filter === '0') || (filter === '3') ? (
                              <>
                                <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                                  <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Select PDF")}</div>
                                  <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Select PDF").toString()}>
                                    <Form.Check
                                      className="p-0 m-0 form-check"
                                      type="checkbox"
                                      aria-label={t("Select PDF").toString()}
                                      name={`${pdf.id}`}
                                      id={`${pdf.id}`}
                                      onChange={(e) =>
                                        individualCheck(
                                          pdf,
                                          e.target.checked
                                        )
                                      }
                                      checked={pdf.is_selected}
                                      disabled={pdf.is_requested ? true : false}
                                    //disabled={disabledFlag}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                                  <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Sr No")}</div>
                                  <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{index + 1 + offset}</div>
                                </div>
                              </>
                            )}

                            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                              <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("PDF URL")}</div>
                              <div className='aioa_dashboard-table-td-cell-value' aria-label={t("PDF URL").toString()}>
                                {(filter === '4') ? (
                                  <>
                                    <a
                                      href={pdf.remidate_pdf}
                                      className="d-flex flex-row align-items-center"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i className={`material-symbols-outlined fs-3 me-2 d-none d-lg-inline`} aria-hidden="true">download_for_offline</i>
                                      <span className="w-100 text-primary domain-name">
                                        {pdf.remidate_pdf_title}
                                      </span>
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <a
                                      href={pdf.pdf_url}
                                      className="d-flex flex-column"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span className="w-100 text-primary domain-name">
                                        {pdf.pdf_url}
                                      </span>
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>

                            {(filter === '4') ? (
                              <>
                                <div className="aioa_dashboard-table-td tcw-200 aioa_dashboard-table-cell-sidegap text-center">
                                  <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Submitted Date")}</div>
                                  <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Submitted Date").toString()}>{pdf.created_at ? parseISODate(pdf.created_at) : "-"}</div>
                                </div>
                                <div className="aioa_dashboard-table-td tcw-200 aioa_dashboard-table-cell-sidegap text-center">
                                  <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Completed Date")}</div>
                                  <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Completed Date").toString()}>{pdf.updated_at ? parseISODate(pdf.updated_at) : "-"}</div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="aioa_dashboard-table-td tcw-150 aioa_dashboard-table-cell-sidegap text-center">
                              <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Pages")}</div>
                              <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Pages").toString()}>{pdf.pages > 0 ? pdf.pages : 'N/A'}</div>
                            </div>
                            <div className="aioa_dashboard-table-td tcw-150 aioa_dashboard-table-cell-sidegap text-center">
                              <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Price(USD)")}</div>
                              <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Price(USD)").toString()}>
                                {pdf.request_status === 3 ? (
                                  <>
                                    -
                                  </>
                                ) : (
                                  <>
                                    {((prePurchasePDFTotalCount > 0) && (prePurchasePDFRemainingCount > 0)) ? (
                                      <>
                                        -
                                      </>
                                    ) : (
                                      <>
                                        ${pdf.total_price}
                                      </>
                                    )}

                                  </>
                                )}
                              </div>
                            </div>
                            {(filter === '0') || (filter === 'all') || (filter === '3') ? (
                              <>
                                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                                  <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Status")}</div>
                                  <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}>
                                    {pdf.request_status === 3 ? (
                                      <Badge bg="success" className="py-1" onClick={() => handleShowStatusPopup(pdf.request_status)} style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}>
                                        <span>{t("Processing")}</span>
                                        <i className={`material-symbols-outlined ms-1`} style={{ fontSize: '1rem' }} role="button" tabIndex={0} aria-label={t("Learn about PDF status").toString()}>info</i>
                                      </Badge>
                                    ) : (pdf.request_status === 0) ? (
                                      <>
                                        -
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>

                              </>
                            )}
                          </div>
                        </>
                      )
                    })}
                  </>
                ) : (
                  <>
                    <NoRecordFound imageType={`pdf`} joyful={(tabStep === 0) ? true : false} noRecordText={`${(tabStep === 0) ? t('No inaccessible PDF found') : t('No PDF are remediated yet')}`} />
                  </>
                )}
              </>
            ) : (
              <>

              </>
            )}

          </div>
          {(currentWebsitePlanStatus.isExpired) ? (
            <>

            </>
          ) : (
            <>

              {selectPdf.length > 0 && tabStep === 0 && (
                <>
                  <div
                    className="position-sticky bottom-0 bg-light py-3"
                    style={{ zIndex: 9 }}
                  >

                    {((prePurchasePDFTotalCount > 0) && (prePurchasePDFRemainingCount > 0) && (prePurchasePDFRemainingCount < selectPdf.length)) && (
                      <>
                        <Alert variant="danger" className="text-white text-center"><strong>{selectPdf.length} PDFs selected. You can select up to {prePurchasePDFRemainingCount} PDFs under your current plan.</strong></Alert>
                      </>
                    )}

                    <div className="d-flex align-items-center justify-content-center">

                      <span className="me-3 ">{selectPdf.length} Selected PDF</span>

                      {((prePurchasePDFTotalCount > 0) && (prePurchasePDFRemainingCount > 0)) ? (
                        <>
                          <Button
                            className="mb-0 ms-3"
                            variant="success"
                            size="lg"
                            disabled={(prePurchasePDFRemainingCount < selectPdf.length) || paymentBtnLoading}
                            onClick={() => handleDirectPaymentRequest(1)}
                          >
                            {t("Request Remediation")}
                          </Button>

                        </>
                      ) : (
                        <>
                          {remediationQuotePaymentButton &&
                            <Button
                              className="mb-0 ms-3"
                              variant="success"
                              size="lg"
                              disabled={paymentBtnLoading}
                              onClick={() => handleDirectPaymentRequest()}
                            >
                              <i className={`material-symbols-outlined`} aria-hidden="true">shield</i>
                              {t("Pay")} ${selectPdfPrice}
                            </Button>
                          }

                          {remediationQuoteRemoveButton &&
                            <Button
                              className="mb-0 ms-3"
                              variant="secondary"
                              size="lg"
                              disabled={paymentBtnLoading}
                              onClick={handleShowRemediationQuotePopup}
                            >
                              <i className={`material-symbols-outlined`} aria-hidden="true">delete</i>
                              {t("Cancel Quote Request")}
                            </Button>
                          }
                        </>
                      )}



                    </div>

                    {showEstimatedPricingMsgFlag && (
                      <>
                        <div className="text-danger mt-2 text-center "><strong>{t("Estimated price warning")}</strong></div>
                      </>
                    )}

                    {/* Request PDF remediation quote Popup */}

                    <Modal
                      show={showModal}
                      onHide={handleRemediationQuotePopupClose}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t("Request PDF remediation quote")}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {/* <RequestPDFRemediationQuote
                            mailTitle={`PDF Remediation Request`}
                            setShowModal={setShowModal}
                            setMainScreenMessage={setMessage}
                            pdfID={selectPdfID.join()}
                          /> */}
                      </Modal.Body>
                    </Modal>

                    {/* Cancel PDF remediation quote Popup */}

                    <Modal
                      show={remediationQuoteRemoveModalFlag}
                      onHide={handleCloseRemediationQuotePopup}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t("Cancel PDF Quote Request")}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {t('Are you sure to cancel quote request for selected PDF?')}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="danger" onClick={handleCancelRemediationQuotePDF}>Yes</Button>
                        <Button variant="success" onClick={handleCloseRemediationQuotePopup}>Cancel</Button>
                      </Modal.Footer>
                    </Modal>


                  </div>
                </>
              )
              }
            </>
          )}
          <div className="aioa_dashboard-table-pagination-main">
            <>
              <ShowRecordItem
                offset={offset}
                limit={limit}
                totalRecord={totalRecord}
              />
              <PaginationComponent
                currentPage={currentPage}
                totalRecords={totalRecord}
                itemsPerPage={limit}
                setCurrentPage={setCurrentPage}
              />
            </>
          </div>

        </div>

      </div>

      {/* Status Code Popup */}
      <Modal
        show={showStatusModalFlag}
        onHide={handleCloseStatusPopup}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {showStatusCode === 1 ? (
              <>
                {t("Requested For Quote")}
              </>
            ) : showStatusCode === 2 ? (
              <>
                {t("Pending Payment")}
              </>
            ) : showStatusCode === 3 ? (
              <>
                {t("Processing")}
              </>
            ) : showStatusCode === 4 ? (
              <>
                {t("Remediated")}
              </>
            ) : showStatusCode === 5 ? (
              <>
                {t("Accessible")}
              </>
            ) : (
              <>
                {t("Pending")}
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showStatusCode === 1 ? (
            <>
              {t("As you submit a request for PDF remediation, status will be displayed as “Requested for Quote”. We’ll review all the submitted PDFs and generate a quote. PDF remediation Pricing is based on the complexity level for each page and vary for each PDF.")}
            </>
          ) : showStatusCode === 2 ? (
            <>
              {t("Once PDF Remediation quote is generated, the status will be changed to “Pending Payment”. You can choose quoted PDFs from the list, and proceed to payment.")}
            </>
          ) : showStatusCode === 3 ? (
            <>
              {t("As soon as we receive the payment for PDF Remediation, we'll start processing your order. The status will be displayed as “Processing”. Usually it takes 2-3 days to complete the remediation process, but it depends on volume and complexity.")}
            </>
          ) : showStatusCode === 4 ? (
            <>
              {t("Remediated")}
            </>
          ) : showStatusCode === 5 ? (
            <>
              {t("Once your order is processed, we'll email you the accessible PDFs. Alternatively, you can also download it from dashboard. You’ll require to upload the accessible PDFs to your website. All in One Accessibility’s PDF monitoring system will identify the accessible PDFs, and its status will be displayed as “Accessible”.")}
            </>
          ) : showStatusCode === 5 ? (
            <>
              {t("All in One Accessibility widget automatically identifies the inaccessible PDF on your website. The identified inaccessible PDF will be displayed as “Pending Request” on the list. Review the list of PDF on your site and choose which to submit for remediation. All in One Accessibility’s PDF monitoring system will monitor your website and notify you of any new PDF in need of remediation.")}
            </>
          ) : (
            <>
              {t("All in One Accessibility widget automatically identifies the inaccessible PDF on your website. The identified inaccessible PDF will be displayed as “Pending Request” on the list. Review the list of PDF on your site and choose which to submit for remediation. All in One Accessibility’s PDF monitoring system will monitor your website and notify you of any new PDF in need of remediation.")}
            </>
          )}
        </Modal.Body>
      </Modal>



    </>
  );
};

export default PDFList;
