import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface PropTypes {
    setChangeWidgetIconPosition: Function,
    setIsChangeWidgetIconCustomPosition: Function,
    setChangeWidgetIconCustomLeftPosition: Function,
    setChangeWidgetIconCustomRightPosition: Function,
    setChangeWidgetIconCustomTopPosition: Function,
    setChangeWidgetIconCustomBottomPosition: Function,
}

const WidgetIconPosition = (props: PropTypes) => {

    // Props
    const { setChangeWidgetIconPosition, setIsChangeWidgetIconCustomPosition, setChangeWidgetIconCustomLeftPosition, setChangeWidgetIconCustomRightPosition, setChangeWidgetIconCustomTopPosition, setChangeWidgetIconCustomBottomPosition } = props;

    // Redux Data
    const { currentWebsite, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    // Stets
    const [widgetIconPosition, setWidgetIconPosition] = useState<string>('bottom_right');
    const [isWidgetIconCustomPosition, setIsWidgetIconCustomPosition] = useState<boolean>(false);
    const [isWidgetIconCustomTopBottomPosition, setIsWidgetIconCustomTopBottomPosition] = useState<number>(2);
    const [widgetIconCustomTopBottomPositionValue, setWidgetIconCustomTopBottomPositionValue] = useState<number>(0);
    const [isWidgetIconCustomLeftRightPosition, setIsWidgetIconCustomLeftRightPosition] = useState<number>(2);
    const [widgetIconCustomLeftRightPositionValue, setWidgetIconCustomLeftRightPositionValue] = useState<number>(0);

    // Set current widget icon type
    useEffect(() => {
        if (currentWebsite) {

            if (currentWebsite.widget_position) {
                setWidgetIconPosition(currentWebsite.widget_position);
            } else {
                setWidgetIconPosition('bottom_right');
            }

            if (currentWebsite.is_widget_custom_position === 1) {
                setIsWidgetIconCustomPosition(true);
            } else {
                setIsWidgetIconCustomPosition(false);
            }

            if (currentWebsite.widget_position_top !== null) {
                setWidgetIconCustomTopBottomPositionValue(currentWebsite.widget_position_top);
                setIsWidgetIconCustomTopBottomPosition(1)
            } else {
                setWidgetIconCustomTopBottomPositionValue(currentWebsite.widget_position_bottom);
                setIsWidgetIconCustomTopBottomPosition(2)
            }

            if (currentWebsite.widget_position_left !== null) {
                setWidgetIconCustomLeftRightPositionValue(currentWebsite.widget_position_left);
                setIsWidgetIconCustomLeftRightPosition(1)
            } else {
                setWidgetIconCustomLeftRightPositionValue(currentWebsite.widget_position_right);
                setIsWidgetIconCustomLeftRightPosition(2)
            }

        }
    }, [currentWebsite]);

    // Check Value Function Functions
    const validateWidgetIconCustomTopBottomPosition = (TopBottomValue: number) => {
        if (widgetIconCustomTopBottomPositionValue > 500) {
            setWidgetIconCustomTopBottomPositionValue(500);
        } else if (widgetIconCustomTopBottomPositionValue < 0) {
            setWidgetIconCustomTopBottomPositionValue(0);
        } else if (isNaN(widgetIconCustomTopBottomPositionValue)) {
            setWidgetIconCustomTopBottomPositionValue(0);
        } else {
            setWidgetIconCustomTopBottomPositionValue(TopBottomValue);
        }
    }
    const validateWidgetIconCustomLeftRightPosition = (TopBottomValue: number) => {
        if (widgetIconCustomLeftRightPositionValue > 500) {
            setWidgetIconCustomLeftRightPositionValue(500);
        } else if (widgetIconCustomLeftRightPositionValue < 0) {
            setWidgetIconCustomLeftRightPositionValue(0);
        } else if (isNaN(widgetIconCustomLeftRightPositionValue)) {
            setWidgetIconCustomLeftRightPositionValue(0);
        } else {
            setWidgetIconCustomLeftRightPositionValue(TopBottomValue);
        }
    }

    // set current icon value in prop function
    useEffect(() => {
        if (widgetIconPosition) {
            setChangeWidgetIconPosition(widgetIconPosition);
        }
    }, [widgetIconPosition]);

    useEffect(() => {
        if (isWidgetIconCustomPosition) {
            setIsChangeWidgetIconCustomPosition(true);
        } else {
            setIsChangeWidgetIconCustomPosition(false);
        }
    }, [isWidgetIconCustomPosition]);

    useEffect(() => {
        if (isWidgetIconCustomLeftRightPosition === 1) {
            setChangeWidgetIconCustomLeftPosition(widgetIconCustomLeftRightPositionValue);
            setChangeWidgetIconCustomRightPosition(null);
        } else {
            setChangeWidgetIconCustomLeftPosition(null);
            setChangeWidgetIconCustomRightPosition(widgetIconCustomLeftRightPositionValue);
        }
    }, [isWidgetIconCustomLeftRightPosition, widgetIconCustomLeftRightPositionValue]);

    useEffect(() => {
        if (isWidgetIconCustomTopBottomPosition === 1) {
            setChangeWidgetIconCustomTopPosition(widgetIconCustomTopBottomPositionValue);
            setChangeWidgetIconCustomBottomPosition(null);
        } else {
            setChangeWidgetIconCustomTopPosition(null);
            setChangeWidgetIconCustomBottomPosition(widgetIconCustomTopBottomPositionValue);
        }
    }, [isWidgetIconCustomTopBottomPosition, widgetIconCustomTopBottomPositionValue]);

    return (
        <>
            {isWidgetIconCustomPosition ? (
                <>
                    <div className="aioa_dashboard-widget-custom-settings border-bottom">
                        <Form.Check
                            type="switch"
                            className="aioa_dashboard-switch"
                            id="custom-switch-position"
                            label={`${t('Enable precise accessibility widget icon position')}`}
                            checked={isWidgetIconCustomPosition}
                            onChange={(e) => setIsWidgetIconCustomPosition(!isWidgetIconCustomPosition)}
                        />
                    </div>
                    <div className="aioa_dashboard-widget-settings-controls">
                        <div className="mb-4 pb-4 border-bottom">
                            <Row>
                                <Col>
                                    <div className={`aioa-dashboard-widget-settings-custom-size-control`}>
                                        <div className="d-flex">
                                            <Form.Control
                                                size="lg"
                                                aria-label={t(`icon position from ${widgetIconCustomTopBottomPositionValue}`).toString()}
                                                aria-describedby="top_bottom_description"
                                                className="border"
                                                type="text"
                                                value={widgetIconCustomTopBottomPositionValue}
                                                onChange={(e) => validateWidgetIconCustomTopBottomPosition(Number(e.target.value))}
                                                onBlur={(e) => validateWidgetIconCustomTopBottomPosition(Number(e.target.value))}
                                                isValid={(widgetIconCustomTopBottomPositionValue >= 0 && widgetIconCustomTopBottomPositionValue <= 500) ? true : false}
                                                isInvalid={(widgetIconCustomTopBottomPositionValue < 0 || widgetIconCustomTopBottomPositionValue > 500) ? true : false}
                                            />
                                            <div className="input-group-text">px</div>
                                        </div>
                                        <div className="text-muted" id="top_bottom_description">{t('position input info text')}</div>
                                    </div>
                                </Col>
                                <Col>
                                    <Form.Select
                                        size="lg"
                                        aria-label={t("Icon vertical position").toString()}
                                        value={isWidgetIconCustomTopBottomPosition}
                                        onChange={(e) => setIsWidgetIconCustomTopBottomPosition(Number(e.target.value))}>
                                        <option value={1}>{t('To the top')}</option>
                                        <option value={2}>{t('To the bottom')}</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col>
                                <div className={`aioa-dashboard-widget-settings-custom-size-control`}>
                                    <div className="d-flex">
                                        <Form.Control
                                            size="lg"
                                            aria-label={t(`icon position from ${widgetIconCustomLeftRightPositionValue}`).toString()}
                                            aria-describedby="left_right_description"
                                            className="border"
                                            type="text"
                                            value={widgetIconCustomLeftRightPositionValue}
                                            onChange={(e) => validateWidgetIconCustomLeftRightPosition(Number(e.target.value))}
                                            onBlur={(e) => validateWidgetIconCustomLeftRightPosition(Number(e.target.value))}
                                            isValid={(widgetIconCustomLeftRightPositionValue >= 0 && widgetIconCustomLeftRightPositionValue <= 500) ? true : false}
                                            isInvalid={(widgetIconCustomLeftRightPositionValue < 0 || widgetIconCustomLeftRightPositionValue > 500) ? true : false}
                                        />
                                        <div className="input-group-text">px</div>
                                    </div>
                                    <div className="text-muted" id="left_right_description">{t('position input info text')}</div>
                                </div>
                            </Col>
                            <Col>
                                <Form.Select
                                    size="lg"
                                    aria-label={t("Icon horizontal position").toString()}
                                    value={isWidgetIconCustomLeftRightPosition}
                                    onChange={(e) => setIsWidgetIconCustomLeftRightPosition(Number(e.target.value))}>
                                    <option value={1}>{t('To the left')}</option>
                                    <option value={2}>{t('To the right')}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </div>
                </>
            ) : (
                <>
                    <div className="aioa_dashboard-widget-settings-btns">
                        <Button onClick={() => setWidgetIconPosition("top_left")} className={`${widgetIconPosition === `top_left` ? 'active' : ''}`} size="lg">{t(`Top Left`)}</Button>
                        <Button onClick={() => setWidgetIconPosition("top_center")} className={`${widgetIconPosition === `top_center` ? 'active' : ''}`} size="lg">{t(`Top Center`)}</Button>
                        <Button onClick={() => setWidgetIconPosition("top_right")} className={`${widgetIconPosition === `top_right` ? 'active' : ''}`} size="lg">{t(`Top Right`)}</Button>
                        <Button onClick={() => setWidgetIconPosition("middle_left")} className={`${widgetIconPosition === `middle_left` ? 'active' : ''}`} size="lg">{t(`Middle Left`)}</Button>
                        <Button onClick={() => setWidgetIconPosition("middle_right")} className={`${widgetIconPosition === `middle_right` ? 'active' : ''}`} size="lg">{t(`Middle Right`)}</Button>
                        <Button onClick={() => setWidgetIconPosition("bottom_left")} className={`${widgetIconPosition === `bottom_left` ? 'active' : ''}`} size="lg">{t(`Bottom Left`)}</Button>
                        <Button onClick={() => setWidgetIconPosition("bottom_center")} className={`${widgetIconPosition === `bottom_center` ? 'active' : ''}`} size="lg">{t(`Bottom Center`)}</Button>
                        <Button onClick={() => setWidgetIconPosition("bottom_right")} className={`${widgetIconPosition === `bottom_right` ? 'active' : ''}`} size="lg">{t(`Bottom Right`)}</Button>
                    </div>

                    <div className="aioa_dashboard-widget-custom-settings border-top">
                        <Form.Check
                            type="switch"
                            className="aioa_dashboard-switch"
                            id="custom-switch-position"
                            label={`${t('Enable precise accessibility widget icon position')}`}
                            checked={isWidgetIconCustomPosition}
                            onChange={(e) => setIsWidgetIconCustomPosition(!isWidgetIconCustomPosition)}
                        />
                    </div>
                </>
            )}
        </>
    )
}

export default WidgetIconPosition;