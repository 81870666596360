import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { getEnableDisableMenuApi, updateEnableDisableMenuApi } from "../../Api/EnableDisableAPI";

const getEnableDisableMenuService = (
    accessToken: string,
    websiteID: number,
    dispatch: Function,
    t: Function,
    setMenuList: Function,
    setLanguagesList: Function,
    setOtherList: Function,
    setAccessibilityProfilesList: Function,
) => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });


    getEnableDisableMenuApi(accessToken, websiteID).then(response => {

        if (response.Data && response.Data.length > 0) {

            let responseData;

            responseData = response.Data.map((responseItem: string) => {
                return JSON.parse(responseItem);
            });

            setMenuList(responseData[0]);
            setLanguagesList(responseData[1]);
            setOtherList(responseData[2]);
            setAccessibilityProfilesList(responseData[3]);

            // Set loading
            dispatch({
                type: LOADING,
                payload: false
            });
        } else {

            // Set loading
            dispatch({
                type: LOADING,
                payload: false
            });

        }

    })
}

const updateEnableDisableMenuService = (
    accessToken: string,
    website_id: number,
    update_reset: number,
    setLoading: Function,
    menuList: any,
    languagesList: any,
    otherList: any,
    accessibilityProfilesList: any,
    setMenuList: Function,
    setLanguagesList: Function,
    setOtherList: Function,
    setAccessibilityProfilesList: Function,
    dispatch: Function,
    t: Function,
    handleClose: Function
) => {
    setLoading(true);

    updateEnableDisableMenuApi(accessToken, website_id, update_reset, menuList, languagesList, otherList, accessibilityProfilesList).then(response => {
        if (response.Data && response.Data.length > 0) {
            handleClose();
            let responseData;
            responseData = response.Data.map((responseItem: string) => {
                return JSON.parse(responseItem);
            });
            setMenuList(responseData[0]);
            setLanguagesList(responseData[1]);
            setOtherList(responseData[2]);
            setAccessibilityProfilesList(responseData[3]);

            if (update_reset === 1) {
                dispatch({
                    type: SET_MESSAGE,
                    payload: {
                        "toast_message": t('All in One Accessibility widget menu settings updated successfully.'),
                        "toast_type": "success",
                        "onclose": null
                    }
                })
            } else {
                dispatch({
                    type: SET_MESSAGE,
                    payload: {
                        "toast_message": t('All in One Accessibility widget menu settings restored to default.'),
                        "toast_type": "success",
                        "onclose": null
                    }
                })
            }

        }
        setLoading(false);
    })
}


export { getEnableDisableMenuService, updateEnableDisableMenuService }