import ProductPlaceholderImage from '../Assets/img/placeholder-image.webp';
import faviconPlaceholder from '../Assets/img/web-icon.webp';


const addDefaultImgSrc = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = ProductPlaceholderImage;
}

const addDefaultFaviconImgSrc = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = faviconPlaceholder;
}

export { addDefaultImgSrc, addDefaultFaviconImgSrc }