import { generatePDFApi } from "../../Api/DashboardPDFReportApi";

const generatePDFService = (
    token: string,
    websiteID: number,
    languageCode: string,
    setLoading: Function,
    setSavePDFLink: Function,
) => {
    setLoading(true);
    generatePDFApi(token, websiteID, languageCode).then(response => {
        if (response.status === 200) {
            const pdfLink = response.data;
            if (pdfLink) {
                setSavePDFLink(pdfLink);
            }
        }
        setLoading(false);
    })
}


export { generatePDFService }