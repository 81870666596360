import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range-ts';
import parseISODate from '../../Helpers/parseISODate';
import { useTranslation } from 'react-i18next';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../../Assets/scss/components/custom-date-range-picker.scss';

import { OnDateRangeChangeProps, MaybeEmptyRange } from 'react-date-range-ts/dist/types';


 interface dateRangePropTypes {
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    key?: string | undefined;
}



interface PropTypes {
    startDate: string,
    setStartDate: Function,
    endDate: string,
    setEndDate: Function
}


const DateRangeFilterBlock = (props: PropTypes) => {

    // Props
    const { startDate, setStartDate, endDate, setEndDate } = props;



    // Functions
    const { t } = useTranslation();

    // State
    const [state, setState] = useState<MaybeEmptyRange>(
        {
            //startDate: new Date(),
            endDate: addDays(new Date(), 0),
            //endDate: new Date(),
            key: 'selection'
        }
    );

    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }

    

    const handleSelect = (ranges: OnDateRangeChangeProps) => {
        console.log("ranges", ranges);
        if (ranges && ranges.selection) {
            setState(ranges.selection)
        }
    }



    const handleClearDateRange = () => {
        setState(
            {
                //startDate: new Date(),
                endDate: addDays(new Date(), 0),
                key: 'selection'
            }
        );
        setStartDate('');
        setEndDate('');
    }

    useEffect(() => {
        if (state.startDate && state.endDate) {
            setStartDate(parseISODate(((state.startDate).toString()), false, true));
            setEndDate(parseISODate(((state.endDate).toString()), false, true));
        }
    }, [state])

    return (
        <>
            <></>
            <Dropdown className='aioa_dashboard-custom-date-range-picker'>
                <div className='aioa_dashboard-custom-date-range-picker-btns'>
                    <Dropdown.Toggle variant="success" id="custom-date-range-picker" className={`${startDate !== '' && endDate !== '' ? `active-btn` : ``}`}>
                        {startDate !== '' && endDate !== '' ? (
                            <>
                                {startDate} to {endDate}
                            </>
                        ) : (
                            <>
                                {t('Select Date Range')}
                            </>
                        )}
                    </Dropdown.Toggle>
                    {startDate !== '' && endDate !== '' ? (
                        <>
                            <Button variant='danger' onClick={() => handleClearDateRange()} className='aioa_dashboard-custom-date-range-picker-clear-btn'>Clear</Button>
                        </>
                    ) : (
                        <>

                        </>
                    )}
                </div>
                <Dropdown.Menu>
                    {/*  <DateRangePicker
                        onChange={item => handleDateRange(item)}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        maxDate={addDays(new Date(), 0)}
                    /> */}
                    <DateRangePicker
                        moveRangeOnFirstSelection={false}
                        months={2}
                        /* ranges={[selectionRange]} */
                        ranges={[selectionRange]}
                        direction="horizontal"
                        onChange={handleSelect}
                        maxDate={addDays(new Date(), 0)}
                    />
                </Dropdown.Menu>
            </Dropdown>

        </>
    )
}

export default DateRangeFilterBlock;