import { Col, Form, InputGroup, Row } from "react-bootstrap"
import ManualAccessibilityAuditBlock from "../ManualAccessibilityAuditBlock"
import { useTranslation } from "react-i18next";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import NoRecordFound from "../NoRecordFound";
import { websiteListPropType } from "../../PropTypes/WebsitePropTypes";
import { debounce } from "lodash";
import { getDomainListWithDetailsService } from "../../Services/DomainService/DomainList";
import { useNavigate } from "react-router-dom";
import useWindowScroll from "../../Helpers/useWindowScroll";
import ExpiredPlanWebsiteListTableCellBlock from "../PartnerWebsiteListTableCell/WebsiteExpiredPlan";
import { DomainDetailsService } from "../../Services/DomainService/DomainDetails";

import "../../Assets/scss/pages/website-list.scss";
import ShowRecordItem from "../ShowRecordItem";
import PaginationComponent from "../Pagination";
import ActiveWebsiteListTableCellBlock from "../PartnerWebsiteListTableCell/WebsiteActivePlan";


const PartnerUserDashboardBlock = () => {

    // Redux Data
    const { user, accessToken } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const windowScrollValue = useWindowScroll('aioa_dashboard-website-list-filter');

    // State
    const [domainList, setDomainList] = useState<websiteListPropType[]>([]);
    const [totalMainRecord, setTotalMainRecord] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);
    const [terms, setTerms] = useState<string>("");
    const [domainFilter, setDomainFilter] = useState<number>(4);
    const [startDate, setStartDate] = useState<string>(``);
    const [endDate, setEndDate] = useState<string>(``);


    const prevLimitRef = useRef<number>(10);
    const prevDomainFilterRef = useRef<number>(4);

    // Pagination Functions
    useEffect(() => {
        setCurrentPage(1);
    }, [limit, terms, domainFilter]);

    useEffect(() => {
        setOffset(0);
    }, [terms, domainFilter]);

    useEffect(() => {
        setOffset(currentPage * limit - limit);
    }, [currentPage, limit]);

    const searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTerms(e.target.value);
    };
    const debouncedSearchChangeHandler = useMemo(() => {
        return debounce(searchChangeHandler, 500);
    }, []);

    // Get Domain List
    useEffect(() => {
        if (accessToken && ((limit === prevLimitRef.current) || (domainFilter === prevDomainFilterRef.current))) {
            getDomainListWithDetailsService(accessToken, dispatch, t, setDomainList, limit, offset, terms, domainFilter, startDate, endDate, setTotalMainRecord, setTotalRecord);
        }
    }, [offset, terms, user]);
    useEffect(() => {
        if ((limit !== prevLimitRef.current) || (domainFilter !== prevDomainFilterRef.current) && offset === 0) {
            if (accessToken) {
                getDomainListWithDetailsService(accessToken, dispatch, t, setDomainList, limit, offset, terms, domainFilter, startDate, endDate, setTotalMainRecord, setTotalRecord);
            }
            prevLimitRef.current = limit;
            prevDomainFilterRef.current = domainFilter;
        }
    }, [offset, limit, domainFilter]);

    // Set Current Website
    const handleSetCurrentWebsite = (websiteID: number) => {
        if (accessToken && websiteID) {
            console.log("from partner user dashboard");
            DomainDetailsService(accessToken, websiteID, dispatch, t).then((res) => {
                if (res.status === 200) {
                    navigate('/front/widget-settings');
                } else {
                    navigate('/front');
                }
            });
        }
    }

    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="aioa_dashboard-website-list aioa_dashboard-partner-website-list-wrapper">

                        <div className={`aioa_dashboard-website-list-filter-wrapper ${windowScrollValue}`} id="aioa_dashboard-website-list-filter">

                            <div className="aioa_dashboard-website-list-filter-block">
                                <Form.Label className='d-lg-none'>{t("Search")}</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        placeholder={t("Search Domain").toString()}
                                        aria-label={t("Search Domain").toString()}
                                        type="text"
                                        onChange={debouncedSearchChangeHandler}
                                    />
                                    <InputGroup.Text><i className="material-symbols-outlined" aria-hidden="true">search</i></InputGroup.Text>
                                </InputGroup>
                            </div>

                            <div className="aioa_dashboard-website-list-filter-block">
                                <Form.Label htmlFor='domain_status'>{t("Filter")}</Form.Label>
                                <Form.Select
                                    onChange={(e) => setDomainFilter(parseInt(e.target.value))}
                                    id="domain_status"
                                    aria-label={t("Filter").toString()}
                                    defaultValue={domainFilter ? domainFilter : 4}
                                >
                                    <option value="4">{t("All")}</option>
                                    <option value="10">{t("Active")}</option>
                                    <option value="1">{t("Pending Activation")}</option>
                                    <option value="2">{t("trials")}</option>
                                    <option value="3">{t("Expired")}</option>
                                </Form.Select>
                            </div>
                            <div className="aioa_dashboard-website-list-filter-block">
                                <Form.Label htmlFor='showPages'>{t("Items Per Page")}</Form.Label>
                                <Form.Select
                                    onChange={(e) => setLimit(parseInt(e.target.value))}
                                    id="showPages"
                                    value={limit}
                                    aria-label={t("Items Per Page").toString()}
                                >
                                    <option value="10">{t("10")}</option>
                                    <option value="20">{t("20")}</option>
                                    <option value="40">{t("40")}</option>
                                    <option value="80">{t("80")}</option>
                                    <option value="100">{t("100")}</option>
                                </Form.Select>
                            </div>

                        </div>

                        <div className="aioa_dashboard-table">

                            <div className="aioa_dashboard-table-thead">
                                <div className="aioa_dashboard-table-tr">
                                    <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap">{t("Sr No")}</div>
                                    <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap">{t("Domain")}</div>
                                    <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Plan")}</div>
                                    <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Status")}</div>
                                    <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Price(USD)")}</div>
                                    <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Renew Date")}</div>
                                    <div className="aioa_dashboard-table-th tcw-100 text-center aioa_dashboard-table-cell-sidegap">&nbsp;</div>
                                </div>
                            </div>

                            <div className="aioa_dashboard-table-body">
                                <div className="aioa_dashboard-table">

                                    {domainList ? (
                                        <>
                                            {domainList.length > 0 ? (
                                                <>
                                                    {domainList.map((domain, index) => {
                                                        return (
                                                            <React.Fragment key={domain.id}>
                                                                {(domain.current_package_only && domain.current_package_only.length > 0) ? (
                                                                    <>
                                                                        {/* {domain.current_package_only[0].is_trial_period === 1 ? (
                                                                            <>
                                                                                <TrialPlanWebsiteListTableCellBlock item={domain} key={domain.id} srno={index + 1 + offset} handleSetCurrentWebsite={handleSetCurrentWebsite} />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <ActiveWebsiteListTableCellBlock item={domain} key={domain.id} srno={index + 1 + offset} handleSetCurrentWebsite={handleSetCurrentWebsite} />
                                                                            </>
                                                                        )} */}
                                                                        <ActiveWebsiteListTableCellBlock item={domain} key={domain.id} srno={index + 1 + offset} handleSetCurrentWebsite={handleSetCurrentWebsite} />
                                                                    </>
                                                                ) : (domain.expired_package_detail && domain.expired_package_detail.length > 0) ? (
                                                                    <>
                                                                        <ExpiredPlanWebsiteListTableCellBlock item={domain} key={domain.id} srno={index + 1 + offset} handleSetCurrentWebsite={handleSetCurrentWebsite} />
                                                                    </>
                                                                ) : (
                                                                    <>

                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    <NoRecordFound joyful={false} imageType={`domain`} noRecordText={`${t('Domain not found')}.`} />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>

                                        </>
                                    )}

                                </div>
                            </div>

                            <div className="aioa_dashboard-table-pagination-main">
                                <>
                                    <ShowRecordItem
                                        offset={offset}
                                        limit={limit}
                                        totalRecord={totalRecord}
                                    />
                                    <PaginationComponent
                                        currentPage={currentPage}
                                        totalRecords={totalRecord}
                                        itemsPerPage={limit}
                                        setCurrentPage={setCurrentPage}
                                    />
                                </>
                            </div>

                        </div>

                    </div>
                </Col>
                {/* <Col xl={3}>
                    <ManualAccessibilityAuditBlock />
                </Col> */}
            </Row>
        </>
    )
}

export default PartnerUserDashboardBlock;