import { useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { getManualAuditReportListService, uploadReportFileService } from "../../Services/ManualReportServices";
import { useDispatch } from "react-redux";
import { SET_MESSAGE } from "../../Actions/Types";

interface PropTypes {
    reportID: number,
    //setLoading: Function,
    setReportList: Function,
    limit: number,
    offset: number,
    setTotalRecord: Function,
    filter: string,
    ispopup?: boolean,
    handleHideFileUploadPopupPopup?: Function,
    addOnID: number
}

const ReportFileUploadElement = (prop: PropTypes) => {

    const { reportID, setReportList, limit, offset, setTotalRecord, filter, ispopup, handleHideFileUploadPopupPopup, addOnID } = prop;

    const { accessToken, user, currentWebsite } = useSelector((store: StoreProptypes) => store);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [reportFileLoading, setReportFileLoading] = useState(false);
    const [reportFileName, setReportFileName] = useState('');
    const [reportFileValidation, setReportFileValidation] = useState(false);
    const [reportFileValidationMsg, setReportFileValidationMsg] = useState('');


    const changeHandler = (e: any) => {
        const target = e.target;
        const fileUploaded = target.files;

        if (e.target.files[0] !== undefined) {
            if ((e.target.files[0].type === 'application/pdf')) {

                setReportFileName(target.files[0].name);
                const fileSize = parseFloat((((e.target.files[0].size) / 1024) / 1024).toFixed(2));

                //console.info("fileSize ==>", fileSize);

                if ((fileSize > 20)) {
                    setReportFileValidation(true);
                    setReportFileValidationMsg('The maximum allowed file size is 20MB.')
                } else {
                    if (fileUploaded) {

                        setReportFileValidation(false);
                        setReportFileValidationMsg('');

                        let reader = new FileReader();
                        reader.readAsDataURL(fileUploaded[0]);

                        reader.onload = (e) => {
                            //console.log("reader.result ==>", fileUploaded[0]);
                            if (accessToken && user && currentWebsite) {
                                uploadReportFileService(accessToken, reportID, user.id, fileUploaded[0], 1, dispatch, t, handleHideFileUploadPopupPopup).then((res) => {
                                    if (res.status === 200) {
                                        getManualAuditReportListService(accessToken, currentWebsite.id, addOnID, dispatch, limit, offset, filter, setTotalRecord, setReportList).then((listRes) => {
                                            if (listRes.status === 200) {
                                                dispatch({
                                                    type: SET_MESSAGE,
                                                    payload: {
                                                        "toast_message": `${t('File upload successfully!')}`,
                                                        "toast_type": "success"
                                                    }
                                                });
                                            }
                                        })
                                    } else {
                                        dispatch({
                                            type: SET_MESSAGE,
                                            payload: {
                                                "toast_message": `${t('File not upload. Please try again.')}`,
                                                "toast_type": "danger"
                                            }
                                        });
                                    }
                                })
                            }
                        }
                    }
                }

            } else {
                setReportFileValidation(true);
                setReportFileValidationMsg('Please upload PDF file.');
                setReportFileName('');
            }
        }

    }

    return (
        <>
            <div className="aioa_dashboard-upload-element">
                <div className={`logo-upload-input ${ispopup ? `` : `m-0`} ${reportFileLoading ? `loading` : ``}`}>
                    <Form.Label htmlFor="upload_file" className="btn btn-primary mb-0">{t("Upload File")}</Form.Label>
                    <InputGroup className="input-group-outline visually-hidden-focusable">
                        <Form.Control id="upload_file" accept=".pdf" type="file" aria-describedby="file_input_description" onChange={(e) => changeHandler(e)} className="ps-0" />
                    </InputGroup>
                </div>
                {ispopup && (
                    <>
                        <div className="file-url">{reportFileName}</div>
                        {reportFileLoading && <Spinner variant="success" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>}
                    </>
                )}
            </div>
            {reportFileValidation && (
                <div className="text-danger mt-1"><strong>{reportFileValidationMsg}</strong></div>
            )}
        </>
    )
}

export default ReportFileUploadElement;