const REGISTER_SUCCESS = "REGISTER_SUCCESS";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";
const LOADING = "LOADING";
const TRANSLATION_LOADING = "TRANSLATION_LOADING";
const API_RECALL = "API_RECALL";
const SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE";
const SET_LANGUAGE_LIST = "SET_LANGUAGE_LIST";
const SET_LANGUAGE_KEYWORDS_LIST = "SET_LANGUAGE_KEYWORDS_LIST";
const SET_TRANSLATIONS = "SET_TRANSLATIONS";
const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
const UPDATE_USER = "UPDATE_USER";
const SET_MESSAGE = "SET_MESSAGE";
const CLEAR_MESSAGE = "CLEAR_MESSAGE";
const SET_WEBSITE_LIST = "SET_WEBSITE_LIST"
const SET_CURRENT_WEBSITE = "SET_CURRENT_WEBSITE";
const SET_CURRENT_WEBSITE_DATA = "SET_CURRENT_WEBSITE_DATA";
const SET_CURRENT_WEBSITE_PLAN_DATA = "SET_CURRENT_WEBSITE_PLAN_DATA";
const SET_CURRENT_WEBSITE_PLAN_LIST = "SET_CURRENT_WEBSITE_PLAN_LIST";
const SET_CURRENT_USER_STATUS = "SET_CURRENT_USER_STATUS";
const SET_CURRENT_USER_DISCOUNT = "SET_CURRENT_USER_DISCOUNT";
const SET_GOOGLE_VOICES = "SET_GOOGLE_VOICES";

export {
    REGISTER_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT,
    LOADING,
    TRANSLATION_LOADING,
    SET_ACTIVE_LANGUAGE,
    SET_LANGUAGE_LIST,
    SET_LANGUAGE_KEYWORDS_LIST,
    SET_TRANSLATIONS,
    SET_ACCESS_TOKEN,
    UPDATE_USER,
    SET_MESSAGE,
    CLEAR_MESSAGE,
    SET_WEBSITE_LIST,
    SET_CURRENT_WEBSITE,
    SET_CURRENT_WEBSITE_DATA,
    SET_CURRENT_WEBSITE_PLAN_DATA,
    SET_CURRENT_WEBSITE_PLAN_LIST,
    SET_CURRENT_USER_STATUS,
    SET_CURRENT_USER_DISCOUNT,
    API_RECALL,
    SET_GOOGLE_VOICES
};