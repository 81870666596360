import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { getManualAuditReportListApi, mailSendReportFileApi, uploadReportFileApi } from "../../Api/ManualReportApi";

const getManualAuditReportListService = async (
    accessToken: string,
    websiteID: number,
    addonID: number,
    dispatch: Function,
    limit: number,
    offset: number,
    filter: string,
    setTotalRecord: Function,
    setReportList: Function,
): Promise<any> => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    const getManualAuditReportListApiRes = await getManualAuditReportListApi(accessToken, websiteID, addonID, limit, offset, filter).then(response => {
        if (response.Data) {

            setReportList(response.Data);
            setTotalRecord(response.total_records);

            // Set loading
            dispatch({
                type: LOADING,
                payload: false
            });

            return { status: 200 };

        } else {

            setReportList([]);
            setTotalRecord(0);

            // Set loading
            dispatch({
                type: LOADING,
                payload: false
            });

            return { status: 400 };

        }
    });

    return getManualAuditReportListApiRes;
}


const uploadReportFileService = async (
    accessToken: string,
    reportItemID: number,
    userId: number,
    file: File,
    reportStatus: number,
    dispatch: Function,
    t: Function,
    handleHideFileUploadPopupPopup?: Function
): Promise<any> => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    const uploadReportFileApiRes = await uploadReportFileApi(accessToken, reportItemID, userId, file, reportStatus).then(res => {

        if (res.Data) {
            handleHideFileUploadPopupPopup && handleHideFileUploadPopupPopup();

            dispatch({
                type: LOADING,
                payload: false
            });

            return { status: 200 };

        } else {

            // Set loading
            dispatch({
                type: LOADING,
                payload: false
            });

            return { status: 400 };
        }

    });

    return uploadReportFileApiRes;
}

const mailSendReportFileService = (
    accessToken: string,
    reportID: number,
    userEmailID: string,
    popupCloseFunction: Function,
    dispatch: Function,
    t: Function
) => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    mailSendReportFileApi(accessToken, reportID, userEmailID).then(res => {

        if (res.Data) {
            popupCloseFunction()

            // Set loading
            dispatch({
                type: LOADING,
                payload: false
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": `${t(`The report is sent to ${userEmailID}. Please check your inbox.`)}`,
                    "toast_type": "success"
                }
            })
        }
    })
}

export { getManualAuditReportListService, uploadReportFileService, mailSendReportFileService }