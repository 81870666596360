const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

const getSurveyDataApi = async (): Promise<any> => {

    const url = `${baseURL}/get-reason-list`;
    
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers
            },
        });

        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const acceptOfferApi = async (
    accessToken: string,
    trialPlanID: number,
    websiteID: number
): Promise<any> => {

    const url = `${baseURL}/accept-cancel-offer`;
    const formData = new FormData();

    formData.append("package_id", trialPlanID.toString());
    formData.append("website_id", websiteID.toString());
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });

        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const packageCancelSubscriptionApi = async (
    accessToken: string, 
    package_id: number, 
    website_id: number, 
    surveyFormReason?: number, 
    surveyFormReasonText?: string, 
    userPackageId?: number
): Promise<any> => {

    const url = `${baseURL}/cancel-subscription`;

    const formData = new FormData();

    formData.append("package_id", package_id.toString());
    formData.append("website_id", website_id.toString());
    surveyFormReason && formData.append("cancel_reason_id", surveyFormReason.toString());
    surveyFormReasonText && formData.append("cancel_reason_description", surveyFormReasonText);
    userPackageId && formData.append("user_package_id", userPackageId.toString());
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });

        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { getSurveyDataApi, acceptOfferApi, packageCancelSubscriptionApi }