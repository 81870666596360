import { useTranslation } from "react-i18next";
import { Form, InputGroup } from "react-bootstrap";


interface PropTypes {
    companyWebsiteURL: string,
    setCompanyWebsiteURL: Function,
    companyName: string,
    setCompanyName: Function
}

const EditCompanyInformation = (props: PropTypes) => {

    const { companyWebsiteURL, setCompanyWebsiteURL, companyName, setCompanyName } = props;

    const { t } = useTranslation();

    return (
        <>
            <div className="border-bottom">
                <div className="card-header">
                    <h2 className="mb-0 h6">{t("Company Information")}</h2>
                </div>
                <div className="card-body">
                    <Form.Label htmlFor="companyName"> {t("Company Name")}</Form.Label>
                    <InputGroup className="mb-3 input-group-outline">
                        <Form.Control
                            size="lg"
                            id="companyName"
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompanyName(e.target.value)}
                            value={companyName}
                            required
                        />
                    </InputGroup>
                    <Form.Label htmlFor="companyWebsiteURL"> {t("Website")}</Form.Label>
                    <InputGroup className="mb-3 input-group-outline">
                        <Form.Control
                            size="lg"
                            id="companyWebsiteURL"
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompanyWebsiteURL(e.target.value)}
                            value={companyWebsiteURL}
                            required
                        />
                    </InputGroup>
                </div>
            </div>
        </>
    )
}
export default EditCompanyInformation;