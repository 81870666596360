import { LOADING, SET_CURRENT_USER_DISCOUNT, SET_CURRENT_WEBSITE_PLAN_LIST } from "../../Actions/Types";
import { getCurrentPackageApi, getPlanListApi } from "../../Api/PlanListApi";

const getPlanListService = (
    token: string,
    planType: number,
    dispatch: Function
) => {

    // Set Loading
    if (planType !== 2) {
        dispatch({
            type: LOADING,
            payload: true,
        });
    }

    getPlanListApi(token, planType).then((planRes) => {
        if (planRes.Data) {

            if (planType === 2) {
                dispatch({
                    type: SET_CURRENT_WEBSITE_PLAN_LIST,
                    payload: planRes.Data,
                });

            }

            if (planType !== 2) {
                // Set Loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });
            }

        }

        if (planRes.discount_details) {
            //console.info("planRes.discount_details ==>", planRes.discount_details);
            dispatch({
                type: SET_CURRENT_USER_DISCOUNT,
                payload: planRes.discount_details,
            });
        }

    });
}

const getCurrentPackageDetails = (
    token: string,
    packageID: number,
    websiteID: number,
    dispatch: Function,
    setCurrentAddOnDetails: Function
) => {

    // Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    getCurrentPackageApi(token, packageID, websiteID).then((currentPackageRes) => {
        if (currentPackageRes.Data) {
            setCurrentAddOnDetails(currentPackageRes.Data);

            // Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

export { getPlanListService, getCurrentPackageDetails };