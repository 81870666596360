
const baseURL = `${process.env.REACT_APP_BASE_URL}/api/website-pdf`
const baseURL1 = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

const getPDFListApi = async (accessToken: string, offset: number, limit: number, website_id: number, terms?: string, filterValue?: string, sortBy?: string): Promise<any> => {

    const url = `${baseURL}/list`;

    const data = new FormData();

    terms && data.append("terms", terms);
    data.append("offset", offset.toString());
    data.append("limit", limit.toString());
    data.append("website_id", website_id.toString());
    filterValue && data.append("filter_value", filterValue);
    sortBy && data.append("short_by", sortBy);


    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}


const getQuotesPDFListApi = async (accessToken: string, website_id: number, remediatedPDFsStatus?:number): Promise<any> => {

    const url = `${baseURL}/quotes`;

    const data = new FormData();

    data.append("website_id", website_id.toString());
    remediatedPDFsStatus && data.append("status", remediatedPDFsStatus.toString());
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

const directPaymentApi = async (accessToken: string, website_id: number, pdfIds:string, flag?:number): Promise<any> => {

    const url = `${baseURL}/direct-payment-request`;

    const data = new FormData();

    data.append("website_id", website_id.toString());
    data.append("website_pdf_ids", pdfIds);
    flag && data.append("pre_payment_pdfs", flag.toString());
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const getPaymentLinkApi = async (accessToken: string, website_id: number, pdfIds:string): Promise<any> => {

    const url = `${baseURL}/get-payment-link`;

    const data = new FormData();

    data.append("website_id", website_id.toString());
    data.append("pdf_ids", pdfIds);
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}


const cancelRemediationQuotePDFApi = async (
    accessToken: string,
    name: string | null,
    email: string,
    phone: string | null,
    subject: string,
    website_id: number,
    plan_id: number,
    is_pdf_remediation: number,
    is_quote_removed: number,
    pdfRemoveID: string
): Promise<any> => {

    const url = `${baseURL1}/contact-us`;

    const Formdata = new FormData();

    Formdata.append("name", name ? name : "");
    Formdata.append("email", email);
    Formdata.append("phone", phone ? phone : "");
    Formdata.append("subject", subject);
    Formdata.append("website_id", website_id.toString());
    Formdata.append("plan_id", plan_id.toString());
    Formdata.append("is_pdf_remediation", is_pdf_remediation.toString());
    Formdata.append("is_quote_removed", is_quote_removed.toString());
    Formdata.append("pdf_ids", pdfRemoveID);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: Formdata
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

export { getPDFListApi, getQuotesPDFListApi, directPaymentApi, getPaymentLinkApi, cancelRemediationQuotePDFApi };