import { useDispatch, useSelector } from "react-redux";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import WebsiteListTableCellBlock from "../../Components/WebsiteListTableCell/WebsiteActivePlan";

import "../../Assets/scss/pages/website-list.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getDomainListWithDetailsService } from "../../Services/DomainService/DomainList";
import { websiteListPropType } from "../../PropTypes/WebsitePropTypes";
import PlatformWebsiteTableCellBlock from "../../Components/WebsiteListTableCell/PlatformWebsitePlan";
import ExpiredPlanWebsiteListTableCellBlock from "../../Components/WebsiteListTableCell/WebsiteExpiredPlan";
import ShowRecordItem from "../../Components/ShowRecordItem";
import PaginationComponent from "../../Components/Pagination";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { debounce } from "lodash";
import TrialPlanWebsiteListTableCellBlock from "../../Components/WebsiteListTableCell/WebsiteTrialPlan";
import { SET_MESSAGE } from "../../Actions/Types";
import CopyWidgetScriptCode from "../../Components/ScriptCode";
import useWindowScroll from "../../Helpers/useWindowScroll";
import { DomainDetailsService } from "../../Services/DomainService/DomainDetails";
import { useNavigate } from "react-router-dom";
import CancelPlanWebsiteListTableCellBlock from "../../Components/WebsiteListTableCell/WebsiteCancelPlan";
import DateRangeFilterBlock from "../../Components/DateRangeFilterBlock";
import NoRecordFound from "../../Components/NoRecordFound";

const websiteAutoLoginURL = `${process.env.REACT_APP_BASE_URL}/front/autologin`

const WebsiteListPage = () => {

  // Redux Data
  const { user, accessToken } = useSelector((store: StoreProptypes) => store);

  // Functions
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowScrollValue = useWindowScroll('aioa_dashboard-website-list-filter');

  // State
  const [domainList, setDomainList] = useState<websiteListPropType[]>([]);
  const [totalMainRecord, setTotalMainRecord] = useState<number>(0);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [terms, setTerms] = useState<string>("");
  const [domainFilter, setDomainFilter] = useState<number>(4);
  const [startDate, setStartDate] = useState<string>(``);
  const [endDate, setEndDate] = useState<string>(``);

  const [isAutoLoginModal, setIsAutoLoginModal] = useState<boolean>(false);
  const [isCopyLinkSuccess, setIsCopyLinkSuccess] = useState<boolean>(false);
  const [autoLoginModalToken, setAutoLoginModalToken] = useState<string>("");
  const [autoLoginModalWebsiteURL, setAutoLoginModalWebsiteURL] = useState<string>("");

  const [isWidgetScriptCodeModal, setIsWidgetScriptCodeModal] = useState<boolean>(false);
  const [widgetScriptCodeModalPosition, setWidgetScriptCodeModalPosition] = useState<string>("");
  const [widgetScriptCodeModalColor, setWidgetScriptCodeModalColor] = useState<string>("");
  const [widgetScriptCodeModalAPIKey, setWidgetScriptCodeModalAPIKey] = useState<string>("");
  const [widgetScriptCodeModalWebsiteURL, setWidgetScriptCodeModalWebsiteURL] = useState<string>("");

  const prevLimitRef = useRef<number>(10);
  const prevDomainFilterRef = useRef<number>(4);

  // Pagination Functions
  useEffect(() => {
    setCurrentPage(1);
  }, [limit, terms, domainFilter]);

  useEffect(() => {
    setOffset(0);
  }, [terms, domainFilter]);

  useEffect(() => {
    setOffset(currentPage * limit - limit);
  }, [currentPage, limit]);

  const searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerms(e.target.value);
  };
  const debouncedSearchChangeHandler = useMemo(() => {
    return debounce(searchChangeHandler, 500);
  }, []);

  // Get Domain List
  useEffect(() => {
    if (accessToken && ((limit === prevLimitRef.current) || (domainFilter === prevDomainFilterRef.current))) {
      getDomainListWithDetailsService(accessToken, dispatch, t, setDomainList, limit, offset, terms, domainFilter, startDate, endDate, setTotalMainRecord, setTotalRecord);
    }
  }, [offset, terms, user, startDate, endDate]);

  useEffect(() => {
    if ((limit !== prevLimitRef.current) || (domainFilter !== prevDomainFilterRef.current) && offset === 0) {
      if (accessToken) {
        getDomainListWithDetailsService(accessToken, dispatch, t, setDomainList, limit, offset, terms, domainFilter, startDate, endDate, setTotalMainRecord, setTotalRecord);
      }
      prevLimitRef.current = limit;
      prevDomainFilterRef.current = domainFilter;
    }
  }, [offset, limit, domainFilter]);

  // Auto Login Model Function
  const autoLinkRef = useRef(null);
  const handleShowAutoLoginModal = (token: string, websiteURL: string) => {
    setAutoLoginModalToken(`${websiteAutoLoginURL}/${token}`);
    setAutoLoginModalWebsiteURL(websiteURL);
    setIsAutoLoginModal(true);
  }
  const handleHideAutoLoginModal = () => {
    setIsAutoLoginModal(false);
    setAutoLoginModalToken('');
    setAutoLoginModalWebsiteURL('');
  }
  const handleAutoLoginCopyCode = () => {
    navigator.clipboard.writeText(autoLoginModalToken);
    setIsCopyLinkSuccess(true);
    dispatch({
      type: SET_MESSAGE,
      payload: {
        "toast_message": t('Copied'),
        "toast_type": "success"
      }
    })
    setTimeout(() => {
      setIsCopyLinkSuccess(false);
      handleHideAutoLoginModal();
    }, 1000)
  }

  // Auto Login Model Function
  const handleShowWidgetScriptCodeModal = (widgetPosition: string, widgetColor: string, widgetAPIKey: string, websiteURL: string) => {
    setWidgetScriptCodeModalPosition(widgetPosition);
    setWidgetScriptCodeModalColor(widgetColor);
    setWidgetScriptCodeModalAPIKey(widgetAPIKey);
    setWidgetScriptCodeModalWebsiteURL(websiteURL);
    setIsWidgetScriptCodeModal(true);
  }
  const handleHideWidgetScriptCodeModal = () => {
    setWidgetScriptCodeModalPosition('');
    setWidgetScriptCodeModalColor('');
    setWidgetScriptCodeModalAPIKey('');
    setWidgetScriptCodeModalWebsiteURL('');
    setIsWidgetScriptCodeModal(false);
  }

  // Set Current Website
  const handleSetCurrentWebsite = (websiteID: number) => {
    if (accessToken && websiteID) {
      console.log("from website list page");
      DomainDetailsService(accessToken, websiteID, dispatch, t);
      navigate('/front');
    }
  }

  return (
    <>
      <DashboardPagesTemplate>
        <DashboardPageTitle
          pageTitle={`${t("Websites")}`}
          subPageTitle={`${t(`Total Domain`, { domainCount: totalMainRecord })}`}
        />

        <div className="aioa_dashboard-website-list">
          <div className={`aioa_dashboard-website-list-filter-wrapper ${windowScrollValue}`} id="aioa_dashboard-website-list-filter">

            {user && user.department === 1 && (
              <>
                <div className="aioa_dashboard-website-list-filter-block">
                  <DateRangeFilterBlock
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </div>
              </>
            )}

            <div className="aioa_dashboard-website-list-filter-block">
              <Form.Label className='d-lg-none'>{t("Search")}</Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder={t("Search Domain").toString()}
                  aria-label={t("Search Domain").toString()}
                  type="text"
                  onChange={debouncedSearchChangeHandler}
                />
                <InputGroup.Text><i className="material-symbols-outlined" aria-hidden="true">search</i></InputGroup.Text>
              </InputGroup>
            </div>

            <div className="aioa_dashboard-website-list-filter-block">
              <Form.Label htmlFor='domain_status'>{t("Filter")}</Form.Label>
              <Form.Select
                onChange={(e) => setDomainFilter(parseInt(e.target.value))}
                id="domain_status"
                aria-label={t("Filter").toString()}
                defaultValue={domainFilter ? domainFilter : 4}
              >
                <option value="4">{t("All")}</option>
                <option value="10">{t("Active")}</option>
                <option value="1">{t("Pending Activation")}</option>
                <option value="2">{t("trials")}</option>
                <option value="3">{t("Expired")}</option>
                <option value="5">{t("Canceled")}</option>
              </Form.Select>
            </div>

            <div className="aioa_dashboard-website-list-filter-block">
              <Form.Label htmlFor='showPages'>{t("Items Per Page")}</Form.Label>
              <Form.Select
                onChange={(e) => setLimit(parseInt(e.target.value))}
                id="showPages"
                value={limit}
                aria-label={t("Items Per Page").toString()}
              >
                <option value="10">{t("10")}</option>
                <option value="20">{t("20")}</option>
                <option value="40">{t("40")}</option>
                <option value="80">{t("80")}</option>
                <option value="100">{t("100")}</option>
              </Form.Select>
            </div>

          </div>

          <div className="aioa_dashboard-table">

            <div className="aioa_dashboard-table-thead">
              <div className="aioa_dashboard-table-tr">
                <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap">{t("Sr No")}</div>
                <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap">{t("Domain")}</div>
                <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Plan")}</div>
                <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Status")}</div>
                <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Price(USD)")}</div>
                <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Date")}</div>
                {user && user.department === 1 ? (
                  <>
                    <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Total/Scan URLs")}</div>
                    <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Used Ai Credits")}</div>
                  </>
                ) : (
                  <>
                    {user && (
                      <>
                        <div className="aioa_dashboard-table-th tcw-250 text-center aioa_dashboard-table-cell-sidegap">{t("Manage Plan")}</div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="aioa_dashboard-table-body" aria-live='polite'>

              {domainList ? (
                <>
                  {domainList.length > 0 ? (
                    <>
                      {domainList.map((domain, index) => {
                        return (
                          <React.Fragment key={domain.id}>
                            {(!domain.is_from_dashboard && (domain.platform === "wix" || domain.platform === 'shopify' || domain.platform === "Opencart-Pro" || domain.platform === "Magento-Pro")) ? (
                              <>
                                <PlatformWebsiteTableCellBlock item={domain} key={domain.id} srno={index + 1 + offset} handleShowAutoLoginModal={handleShowAutoLoginModal} handleShowWidgetScriptCodeModal={handleShowWidgetScriptCodeModal} handleSetCurrentWebsite={handleSetCurrentWebsite} />
                              </>
                            ) : (domain.current_package_only && domain.current_package_only.length > 0) ? (
                              <>
                                {domain.current_package_only[0].is_trial_period === 1 ? (
                                  <>
                                    <TrialPlanWebsiteListTableCellBlock item={domain} key={domain.id} srno={index + 1 + offset} handleShowAutoLoginModal={handleShowAutoLoginModal} handleShowWidgetScriptCodeModal={handleShowWidgetScriptCodeModal} handleSetCurrentWebsite={handleSetCurrentWebsite} />
                                  </>
                                ) : (
                                  <>
                                    <WebsiteListTableCellBlock item={domain} key={domain.id} srno={index + 1 + offset} handleShowAutoLoginModal={handleShowAutoLoginModal} handleShowWidgetScriptCodeModal={handleShowWidgetScriptCodeModal} handleSetCurrentWebsite={handleSetCurrentWebsite} />
                                  </>
                                )}
                              </>
                            ) : (domain.expired_package_detail && domain.expired_package_detail.length > 0) ? (
                              <>
                                <ExpiredPlanWebsiteListTableCellBlock item={domain} key={domain.id} srno={index + 1 + offset} handleShowAutoLoginModal={handleShowAutoLoginModal} handleSetCurrentWebsite={handleSetCurrentWebsite} />
                              </>
                            ) : (domain.current_package_only && domain.current_package_only.length === 0 && domain.canceled_package_only && domain.canceled_package_only.length > 0) ? (
                              <>
                                <CancelPlanWebsiteListTableCellBlock item={domain} key={domain.id} srno={index + 1 + offset} handleShowAutoLoginModal={handleShowAutoLoginModal} handleSetCurrentWebsite={handleSetCurrentWebsite} />
                              </>
                            ) : (
                              <>

                              </>
                            )}

                          </React.Fragment>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      <NoRecordFound joyful={false} imageType={`domain`} noRecordText={`${t('Domain not found')}.`} />
                    </>
                  )}
                </>
              ) : (
                <>

                </>
              )}

            </div>

            <div className="aioa_dashboard-table-pagination-main">
              <>
                <ShowRecordItem
                  offset={offset}
                  limit={limit}
                  totalRecord={totalRecord}
                />
                <PaginationComponent
                  currentPage={currentPage}
                  totalRecords={totalRecord}
                  itemsPerPage={limit}
                  setCurrentPage={setCurrentPage}
                />
              </>
            </div>

          </div>

        </div>

        {/* Auto Login popup */}
        <Modal show={isAutoLoginModal} onHide={() => handleHideAutoLoginModal()} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>{t('Auto-Login Link')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='aioa_dashboard-copy-widget-code aioa_dashboard-widget-code-modal'>
              <div className='mb-3'><strong>{t('Website')}</strong> : {autoLoginModalWebsiteURL}</div>
              <div className='aioa_dashboard-widget-code '>
                <div className='aioa_dashboard-widget-code-copy-btn'>
                  <Button aria-label={t("Copy widget embed code").toString()} className='mb-0' variant={`${isCopyLinkSuccess ? 'success' : 'info'}`} onClick={handleAutoLoginCopyCode}><i className={`material-symbols-outlined`} aria-hidden="true">{isCopyLinkSuccess ? 'done' : 'content_copy'}</i></Button>
                </div>
                <pre ref={autoLinkRef}>{autoLoginModalToken}</pre>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Copy widget script code popup */}
        <Modal show={isWidgetScriptCodeModal} onHide={() => handleHideWidgetScriptCodeModal()} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>{t('Widget Code Popup Title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'><strong>{t('Website')}</strong> : {widgetScriptCodeModalWebsiteURL}</div>
            <CopyWidgetScriptCode
              widgetAPIKey={widgetScriptCodeModalAPIKey}
              widgetPosition={widgetScriptCodeModalPosition !== 'null' ? widgetScriptCodeModalPosition : `bottom_right`}
              widgetColor={widgetScriptCodeModalColor !== 'null' ? widgetScriptCodeModalColor : '#420083'}
            />
          </Modal.Body>
        </Modal>



      </DashboardPagesTemplate>
    </>
  )
}

export default WebsiteListPage;