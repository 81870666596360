import { Button, Form } from "react-bootstrap";
import UserActionPagesTemplate from "../../Template/UserActionPagesTemplate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { SET_MESSAGE } from "../../Actions/Types";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { forgetPasswordService } from "../../Services/UserAction/ForgotPasswordService";

const ForgotPasswordPage = () => {

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const emailRef = useRef<HTMLInputElement | null>(null);

    //Get app parameter
    const app = searchParams.get("app");

    // Redux
    const { activeLanguage, user } = useSelector((store: StoreProptypes) => store);

    // State
    const [userEmail, setUserEmail] = useState<string>('');

    // navigate if user found
    useEffect(() => {
        if (user) {

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("You're already logged In. You can change your password via profile settings.", { returnObjects: true }),
                    "toast_type": "success",
                    "onclose": null
                }
            });

            navigate('/front/edit-profile/');
        }
    }, [user]);

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (userEmail === '') {

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Please enter your email address", { returnObjects: true }),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        }
        else if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(userEmail) === false) {

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Please enter valid email address", { returnObjects: true }),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        }
        else {
            if (userEmail) {
                forgetPasswordService(userEmail, handleFocus, t, dispatch, navigate);
            }
        }
    }

    const handleFocus = () => {
        //console.log("handle Close called");
        if (emailRef && emailRef.current) {
            emailRef.current.focus();
        }
    }

    return (
        <>
            <UserActionPagesTemplate>
                <h1>{t('forgot password label')}</h1>
                <Form>
                    <Form.Group className="form-group" controlId="user_email">
                        <Form.Label>{t('form email filed label')}</Form.Label>
                        <Form.Control
                            type="email"
                            size="lg"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserEmail(e.target.value)}
                            value={userEmail}
                            aria-required="true"
                            autoComplete="email"
                            ref={emailRef}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" size="lg" className="w-100" onClick={(e) => handleSubmit(e)}>{t('Forgot Password')}</Button>
                </Form>
                <div className="aioa_dashboard-login-link">
                    <Link to={`/front/login${(app && app === "readwritemadesimple") ? '?app=readwritemadesimple' : ''}`} className="aioa_free-trial-link">{t("Login")}</Link>
                </div>
            </UserActionPagesTemplate>

        </>
    )
}

export default ForgotPasswordPage;