import { Button } from 'react-bootstrap';
import '../../Assets/scss/components/sidebar.scss';
import DashboardLogo from '../DashboardLogo';
import DomainPlanInfo from '../DomainPlanName';
import DashboardSidebarNavigation from '../SidebarNavigation';

interface PropTypes {
    mobileMenu: boolean,
    handelMobileMenu: Function,
    handelCloseMobileMenu: Function,
}

const DashboardSidebarMenu = (props: PropTypes) => {
    const { mobileMenu, handelMobileMenu, handelCloseMobileMenu } = props;
    return (
        <>
            <aside className={`aioa_dashboard-sidebar ${mobileMenu ? `active` : ``}`}>
                <div className='aioa_dashboard-sidebar-close-btn d-xxl-none'>
                    <Button variant='danger' className='btn-close' onClick={() => handelMobileMenu()}></Button>
                </div>
                <div className='aioa_dashboard-sidebar-logo'>
                    <DashboardLogo />
                    <DomainPlanInfo />
                </div>
                <div className='aioa_dashboard-sidebar-navigation'>
                    <DashboardSidebarNavigation handelMobileMenu={handelCloseMobileMenu} />
                </div>
            </aside>
        </>
    )
}

export default DashboardSidebarMenu;