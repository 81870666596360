const setCookie = (name: string, value: string, expirydays: number) => {
    const date = new Date();
    date.setTime(date.getTime() + (expirydays * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
}

const removeCookie = (name:string) => {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

const getCookie = (name: string) => {
    const cookiename = `${name}=`;
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];

        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(cookiename.length, cookie.length);
        }
    }
    return "";
}


export { setCookie, getCookie, removeCookie }