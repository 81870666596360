const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

const getViolationsCountApi = async (
    token: string,
    websiteID: number
): Promise<any> => {

    const url = `${baseURL}/scan-history-data`;

    const data = new FormData();

    data.append("website_id", websiteID.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}


const getViolationsListApi = async (
    token: string,
    websiteID: number,
    lang: string,
    isFixedByWidget: number
): Promise<any> => {

    const url = `${baseURL}/accessibility/violations-list`;

    const data = new FormData();

    data.append("website_id", websiteID.toString());
    data.append("is_fixed_by_widget", isFixedByWidget.toString());
    data.append("langcode", lang);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { getViolationsCountApi, getViolationsListApi }