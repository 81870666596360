import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { acceptOfferApi, getSurveyDataApi, packageCancelSubscriptionApi } from "../../Api/SurveyDataApi";
import { DomainDetailsService } from "../DomainService/DomainDetails";
import ReactGA from "react-ga4";
import { getUserDetailsService } from "../UserAction/LoginActionService";

const getSurveyDataService = (
    setSurveyData: Function
) => {
    getSurveyDataApi().then(response => {
        if (response.Data && response.Data.length > 0) {
            setSurveyData(response.Data);
        } else {

        }
    })
}

const acceptOfferService = (
    accessToken: string,
    trialPlanID: number,
    websiteID: number,
    handleCloseSurveyFormModal: Function,
    dispatch: Function,
    t: Function,
    navigate: Function
) => {

    // Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    acceptOfferApi(accessToken, trialPlanID, websiteID).then(async (response) => {
        if (response.status === 200) {

            getUserDetailsService(accessToken, dispatch, t).then(async (userRes) => {
                if (userRes.status === 200) {
                    console.log("from survey data service");

                    DomainDetailsService(accessToken, websiteID, dispatch, t).then(async (res) => {
                        if (res.status === 200) {

                            handleCloseSurveyFormModal();

                            navigate('/front/manage-plan');

                            // Loading
                            dispatch({
                                type: LOADING,
                                payload: false,
                            });

                            dispatch({
                                type: SET_MESSAGE,
                                payload: {
                                    "toast_message": t("Subscription successfully updated.", { returnObjects: true }),
                                    "toast_type": "success"
                                }
                            });

                        }
                    });

                }
            });
        }
    })
}

const planCancelSubscriptionService = (
    accessToken: string,
    trialPlanID: number,
    websiteID: number,
    dispatch: Function,
    t: Function,
    surveyFormReason: number,
    surveyFormReasonText: string,
    userPackageId?: number
) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    packageCancelSubscriptionApi(accessToken, trialPlanID, websiteID, surveyFormReason, surveyFormReasonText, userPackageId).then( async (response) => {
        if (response.status === 200) {

            ReactGA.event({
                category: "event",
                action: "click",
                label: "Cancel Subscription Added - All In One Accessibility", // optional
            }, {
                params: {
                    page_title: 'Cancel Subscription Save - All In One Accessibility',
                    website_id: websiteID,
                    page_location: '/front/',
                    page_path: '/front/',
                    send_to: 'G-85C9EMDGMJ'
                }
            });


            getUserDetailsService(accessToken, dispatch, t).then(async (userRes) => {
                if (userRes.status === 200) {
                    console.log("from survey data service 1");

                    DomainDetailsService(accessToken, websiteID, dispatch, t).then(async (res) => {
                        if (res.status === 200) {

                            dispatch({
                                type: SET_MESSAGE,
                                payload: {
                                    "toast_message": t('Plan subscription canceled Success Msg'),
                                    "toast_type": 'success'
                                }
                            })

                            dispatch({
                                type: LOADING,
                                payload: false,
                            });
                            
                        }
                    });

                }
            });

        } else {

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Plan subscription canceled Failed Msg'),
                    "toast_type": 'danger'
                }
            })

            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

export { getSurveyDataService, acceptOfferService, planCancelSubscriptionService }