import { ImageAltListPropTypes, SelectedImageAltListPropTypes } from "../../PropTypes/ImageAltPropTypes";

const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

const getImageAltTextApi = async (accessToken: string, offset:number, limit:number, website_id:number, terms?: string, filterValue?: string, sortByValue?:string, pageID?: string): Promise<any> => {

    const url = `${baseURL}/alt-text/list`;

    const data = new FormData();

    terms && data.append("terms", terms);
    data.append("offset", offset.toString());
    data.append("limit", limit.toString());
    data.append("website_id", website_id.toString());
    filterValue && data.append("filter_value", filterValue);
    sortByValue && data.append("short_by", sortByValue);
    pageID && data.append("page_id", pageID);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

const AllUpdateImageAltTextApi = async (
    accessToken: string, 
    website_id:number, 
    imageAltTextList:SelectedImageAltListPropTypes[]
    ): Promise<any> => {

    const url = `${baseURL}/alt-text/update`;

    const data = new FormData();
    data.append("website_id", website_id.toString());
    
    imageAltTextList && imageAltTextList.filter(item => item.is_selected === 1).map((imageAltTextListElement:SelectedImageAltListPropTypes)=>{
        return(
            <>
                {(imageAltTextListElement.is_decorative === 1) ? (
                    <>
                        {data.append(`update_alt_text[${imageAltTextListElement.id}]`, imageAltTextListElement.alt_text_updated ? imageAltTextListElement.alt_text_updated : '' )}
                    </>
                ) : (
                    <>
                        {data.append(`update_alt_text[${imageAltTextListElement.id}]`, imageAltTextListElement.temp_alt_text_updated ? imageAltTextListElement.temp_alt_text_updated : '' )}
                    </>
                )}
                {data.append(`is_decorative[${imageAltTextListElement.id}]`, (imageAltTextListElement.is_decorative).toString())}
                {data.append(`is_updated_from[${imageAltTextListElement.id}]`, (imageAltTextListElement.is_updated_from ? imageAltTextListElement.is_updated_from : 0 ).toString())}
            </>
        )
    })


    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}


const updateImageAltTextApi = async (
    accessToken: string, 
    website_id:number, 
    imageAltTextList:ImageAltListPropTypes[]
    ): Promise<any> => {

    const url = `${baseURL}/alt-text/update`;

    const data = new FormData();
    data.append("website_id", website_id.toString());
    
    imageAltTextList && imageAltTextList.map((imageAltTextListElement:ImageAltListPropTypes)=>{
        return(
            <>
                {data.append(`update_alt_text[${imageAltTextListElement.id}]`, imageAltTextListElement.alt_text_updated)}
                {data.append(`is_decorative[${imageAltTextListElement.id}]`, imageAltTextListElement.is_decorative.toString())}
            </>
        )
    })
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

const pageWiseImageAltApi = async (
    accessToken: string, 
    website_id:number, 
    offset?: number,
    limit?: number,
    filter?: string,
    terms?: string
): Promise<any> => {

    const url = `${baseURL}/alt-text/page-list`;

    const data = new FormData();
    data.append("website_id", website_id.toString());
    offset && data.append("offset", offset.toString());
    limit && data.append("limit", limit.toString());
    filter && data.append("filter_value", filter);
    terms && data.append("terms", terms);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}


export {getImageAltTextApi, updateImageAltTextApi, pageWiseImageAltApi, AllUpdateImageAltTextApi};