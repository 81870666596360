const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

const getPlanListApi = async (
    token: string,
    planType: number
): Promise<any> => {

    const url = `${baseURL}/package-list`;

    const data = new FormData();
    data.append("product_type", planType.toString());


    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const getCurrentPackageApi = async (
    token: string,
    packageID: number,
    websiteID: number,
): Promise<any> => {

    const url = `${baseURL}/package-detail`;

    const data = new FormData();
    data.append("id", packageID.toString());
    data.append("website_id", websiteID.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const packageEnableDisableApi = async (
    accessToken: string,
    website_id: number,
    package_id: number,
    package_status: number
): Promise<any> => {

    const url = `${baseURL}/partner-adon-status-change`;

    const formData = new FormData();

    formData.append("website_id", website_id.toString());
    formData.append("adon_id", package_id.toString());
    formData.append("status", package_status.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });

        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { getPlanListApi, getCurrentPackageApi, packageEnableDisableApi }