import { LOADING, LOGOUT, SET_MESSAGE } from "../../Actions/Types";
import { UserLogoutActionApi } from "../../Api/UserAction/LogoutActionApi";

const UserLogoutActionService = (
    accessToken: string,
    dispatch: Function,
    navigate: Function,
    t: Function,
    handleLogOutConfirmModalClose: Function
) => {

    // Loading true
    dispatch({
        type: LOADING,
        payload: true,
    });

    UserLogoutActionApi(accessToken).then((response) => {

        if (response.status === 200) {

            // Close Modal
            handleLogOutConfirmModalClose()

            // Logout
            dispatch({
                type: LOGOUT,
            });

            // Logout Msg
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Logout Success Msg", { returnObjects: true }),
                    "toast_type": "success",
                    "onclose": null
                }
            });

            // Redirect
            navigate("/front/login");

            // Loading false
            dispatch({
                type: LOADING,
                payload: false,
            });

        }

    }
    );
};

export default UserLogoutActionService;