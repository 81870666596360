const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

const forgetPasswordApi = async (email: string): Promise<any> => {

    const url = `${baseURL}/forgot`;

    const data = new FormData();

    data.append("email", email);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await response.status;
    } catch (error) {
        return Promise.reject;
    }

}

export { forgetPasswordApi }