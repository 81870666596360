

const key = process.env.REACT_APP_TTS_KEY;


const GoogleGetVoicesAPI = async (): Promise<any> => {
    const url = `https://texttospeech.googleapis.com/v1/voices?key=${key}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}


const GoogleSynthesizeSpeechAPI  = async (text: string, voiceName: string, languageCode:string,): Promise<any> => {
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${key}`;

    const payload = {
        input: { text },
        voice: { name: voiceName, languageCode },
        audioConfig: { audioEncoding: 'MP3' },
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload)
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}


export { GoogleGetVoicesAPI, GoogleSynthesizeSpeechAPI };