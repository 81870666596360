const baseURL = `${process.env.REACT_APP_BASE_URL}/api`;
let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};


// Save Card
const addNewCardApi = async (
    accessToken: string, 
    cardToken: string
): Promise<any> => {

    const url = `${baseURL}/save-card`;
    const data = new FormData();
    data.append("card_token", cardToken);
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// Get Card
const getCardListApi = async (
    accessToken: string, 
): Promise<any> => {

    const url = `${baseURL}/saved-card-list`;
    const data = new FormData();
    //data.append("card_token", cardToken);
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            //body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// Delete Card
const deleteCardListApi = async (
    accessToken: string, 
    deleteCardID: number
): Promise<any> => {

    const url = `${baseURL}/delete-card`;
    const data = new FormData();
    data.append("id", deleteCardID.toString());
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// Delete Card
const makeDefaultCardApi = async (
    accessToken: string, 
    defaultCardID: number
): Promise<any> => {

    const url = `${baseURL}/make-card-default`;
    const data = new FormData();
    data.append("id", defaultCardID.toString());
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { addNewCardApi, getCardListApi, deleteCardListApi, makeDefaultCardApi };