import { LOADING, LOGIN_SUCCESS, SET_MESSAGE } from "../../Actions/Types";
import { getWhiteLabelServiceDomainListAPI, updateBrandDetailsAPI, updateWhiteLabelServiceDomainStatusAPI } from "../../Api/WhiteLabelAPI";
import { WhiteLabelWebsitePropTypes } from "../../PropTypes/WebsitePropTypes";

const updateBrandDetailsService = (
    accessToken: string,
    userID: number,
    websiteURL: string,
    setLoading: Function,
    dispatch: Function,
    t: Function,
    brandLogo?: File,
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    updateBrandDetailsAPI(accessToken, userID, websiteURL, brandLogo).then(response => {
        if (response.Data) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: response.Data },
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Update successfully."),
                    "toast_type": "success"
                }
            });

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
            
        }
        else {

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("White label service setting not updated. Please try again."),
                    "toast_type": "danger"
                }
            })

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

const getWhiteLabelServiceDomainList = (
    accessToken: string,
    addOnID: number,
    status: number,
    limit: number,
    offset: number,
    terms: string,
    setTotalRecord: Function,
    setDomainMainTotalRecord: Function,
    setWebsiteList: Function,
    setLoading: Function
) => {
    setLoading(true);
    getWhiteLabelServiceDomainListAPI(accessToken, addOnID, status, limit, offset, terms).then(res => {
        if (res.Data) {
            setWebsiteList(res.Data);
            setTotalRecord(res.total_records);
            setDomainMainTotalRecord(res.total_records_main);
            setLoading(false);
        }
    })
}

const UpdateWhiteLabelServiceDomainStatus = (
    accessToken: string,
    addOnID: number,
    statusObj: WhiteLabelWebsitePropTypes[],
    setApiRecall: Function,
    dispatch: Function,
    t: Function,
    setSelectedWebsiteList: Function
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    updateWhiteLabelServiceDomainStatusAPI(accessToken, addOnID, statusObj).then(res => {
        if (res.Data) {

            setSelectedWebsiteList([]);
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Update successfully."),
                    "toast_type": "success"
                }
            });

            setApiRecall(true);

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

        }
    })
}

export { getWhiteLabelServiceDomainList, UpdateWhiteLabelServiceDomainStatus, updateBrandDetailsService }