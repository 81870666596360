import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface PropTypes {
    isType: number, // 1 - Desktop, 2 - Mobile
    setSelectedWidgetIconCustomSize: Function,
    selectedWidgetIconCustomSize: number
}

const WidgetCustomSizeControl = (props: PropTypes) => {
    // PropTypes
    const { setSelectedWidgetIconCustomSize, selectedWidgetIconCustomSize, isType } = props;

    // Functions
    const { t } = useTranslation();

    const handleWidgetIconCustomSizeForMobile = (size: number) => {
        setSelectedWidgetIconCustomSize(size);
    }

    const validateWidgetIconCustomSizeForMobile = () => {
        if (selectedWidgetIconCustomSize > 150) {
            setSelectedWidgetIconCustomSize(150);
        }
        else if (selectedWidgetIconCustomSize < 20) {
            setSelectedWidgetIconCustomSize(20);
        } else if (isNaN(selectedWidgetIconCustomSize)) {
            setSelectedWidgetIconCustomSize(20);
        }
    }

    return (
        <>
            <div className={`aioa-dashboard-widget-settings-custom-size-control`}>
                <Form.Label htmlFor={(isType && isType === 1) ? `custom_icon_size` : `custom_icon_size_mobile`}>{t('Select exact icon size')}</Form.Label>
                <div className="d-flex">
                    <Form.Control
                        size="lg"
                        aria-label={t('Select exact icon size').toString()}
                        aria-describedby={t("custom_icon_description").toString()}
                        id={(isType && isType === 1) ? `custom_icon_size` : `custom_icon_size_mobile`}
                        className={`border`}
                        isValid={(selectedWidgetIconCustomSize >= 20 && selectedWidgetIconCustomSize <= 150) ? true : false}
                        isInvalid={(selectedWidgetIconCustomSize < 20 || selectedWidgetIconCustomSize > 150) ? true : false}
                        type="text"
                        value={selectedWidgetIconCustomSize}
                        onChange={(e) => { handleWidgetIconCustomSizeForMobile(Number(e.target.value)); }}
                        onBlur={() => validateWidgetIconCustomSizeForMobile()} />
                    <div className="input-group-text">px</div>
                </div>
                <div className="text-muted" id="custom_icon_description">{t('Icon size value')}</div>
            </div>
        </>
    )
}

export default WidgetCustomSizeControl;