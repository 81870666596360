import { useTranslation } from "react-i18next";

interface PropTypes {
    offset: number,
    limit: number,
    totalRecord: number,
}
const ShowRecordItem = (prop: PropTypes) => {
    const { t } = useTranslation();
    const { offset, limit, totalRecord } = prop;
    return (
        <>
            <div className="record-count"><strong>{t('record count', { currentItem: (1 + offset), limitItem: ((offset + limit) > totalRecord) ? totalRecord : (offset + limit), totalItem: totalRecord })}</strong></div>
        </>
    )
}

export default ShowRecordItem;