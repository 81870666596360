import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Col, Form, Row } from "react-bootstrap";

import aioaIconType01 from "../../Assets/img/widget-icon/aioa-icon-type-1.svg";
import aioaIconType02 from "../../Assets/img/widget-icon/aioa-icon-type-2.svg";
import aioaIconType03 from "../../Assets/img/widget-icon/aioa-icon-type-3.svg";
import aioaIconType04 from "../../Assets/img/widget-icon/aioa-icon-type-4.svg";
import aioaIconType05 from "../../Assets/img/widget-icon/aioa-icon-type-5.svg";
import aioaIconType06 from "../../Assets/img/widget-icon/aioa-icon-type-6.svg";
import aioaIconType07 from "../../Assets/img/widget-icon/aioa-icon-type-7.svg";
import aioaIconType08 from "../../Assets/img/widget-icon/aioa-icon-type-8.svg";
import aioaIconType09 from "../../Assets/img/widget-icon/aioa-icon-type-9.svg";
import aioaIconType10 from "../../Assets/img/widget-icon/aioa-icon-type-10.svg";
import aioaIconType11 from "../../Assets/img/widget-icon/aioa-icon-type-11.svg";
import aioaIconType12 from "../../Assets/img/widget-icon/aioa-icon-type-12.svg";
import aioaIconType13 from "../../Assets/img/widget-icon/aioa-icon-type-13.svg";
import aioaIconType14 from "../../Assets/img/widget-icon/aioa-icon-type-14.svg";
import aioaIconType15 from "../../Assets/img/widget-icon/aioa-icon-type-15.svg";
import aioaIconType16 from "../../Assets/img/widget-icon/aioa-icon-type-16.svg";
import aioaIconType17 from "../../Assets/img/widget-icon/aioa-icon-type-17.svg";
import aioaIconType18 from "../../Assets/img/widget-icon/aioa-icon-type-18.svg";
import aioaIconType19 from "../../Assets/img/widget-icon/aioa-icon-type-19.svg";
import aioaIconType20 from "../../Assets/img/widget-icon/aioa-icon-type-20.svg";
import aioaIconType21 from "../../Assets/img/widget-icon/aioa-icon-type-21.svg";
import aioaIconType22 from "../../Assets/img/widget-icon/aioa-icon-type-22.svg";
import aioaIconType23 from "../../Assets/img/widget-icon/aioa-icon-type-23.svg";
import aioaIconType24 from "../../Assets/img/widget-icon/aioa-icon-type-24.svg";
import aioaIconType25 from "../../Assets/img/widget-icon/aioa-icon-type-25.svg";
import aioaIconType26 from "../../Assets/img/widget-icon/aioa-icon-type-26.svg";
import aioaIconType27 from "../../Assets/img/widget-icon/aioa-icon-type-27.svg";
import aioaIconType28 from "../../Assets/img/widget-icon/aioa-icon-type-28.svg";
import aioaIconType29 from "../../Assets/img/widget-icon/aioa-icon-type-29.svg";
import { useEffect, useState } from "react";
import useWindowSize from "../../Helpers/useWindowSize";

const widgetIconJson = [
    {
        id: 1,
        //iconImage: aioaIconType01
        iconImage: '<svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55"><path d="M27.488,0A27.5,27.5,0,1,1,8.052,8.054,27.5,27.5,0,0,1,27.488,0Zm-.174,33.19-4,11.065a2.2,2.2,0,0,1-1.154,1.266,2.237,2.237,0,0,1-2.975-1.074,2.264,2.264,0,0,1-.076-1.718L21.895,35a11.382,11.382,0,0,0,.523-1.79,18.746,18.746,0,0,0,.242-1.888c.107-1.132.183-2.358.242-3.535s.1-2.318.13-3.262c.04-1.177-.277-1.253-1.221-1.476l-.2-.045-8.054-1.517a2.237,2.237,0,1,1,.814-4.385l8.653,1.624c.345.031.68.072,1.034.112a25.736,25.736,0,0,0,3.212.237,36.2,36.2,0,0,0,4-.282c.4-.045.783-.094,1.163-.13l8.165-1.53a2.21,2.21,0,0,1,2.277,3.423v.027a2.237,2.237,0,0,1-1.436.935l-7.821,1.468c-.26.058-.492.1-.7.13-.814.139-1.217.21-1.168,1.369.036.846.139,1.857.273,2.913.157,1.239.362,2.555.577,3.758a18.344,18.344,0,0,0,.447,2.036c.179.608.353,1.23.622,1.978l2.734,7.561a2.237,2.237,0,1,1-4.2,1.526L28.186,33.212l-.447-.819-.447.8Zm.174-23.946A3.951,3.951,0,1,1,24.7,10.4a3.951,3.951,0,0,1,2.792-1.159Zm16.264,1.982a23.006,23.006,0,1,0,6.711,16.264,23.006,23.006,0,0,0-6.711-16.264Z" transform="translate(0.004)" fill="#fff"/></svg>'
    },
    {
        id: 2,
        iconImage: aioaIconType02
    },
    {
        id: 3,
        iconImage: aioaIconType03
    },
    {
        id: 4,
        iconImage: aioaIconType04
    },
    {
        id: 5,
        iconImage: aioaIconType05
    },
    {
        id: 6,
        iconImage: aioaIconType06
    },
    {
        id: 7,
        iconImage: aioaIconType07
    },
    {
        id: 8,
        iconImage: aioaIconType08
    },
    {
        id: 9,
        iconImage: aioaIconType09
    },
    {
        id: 10,
        iconImage: aioaIconType10
    },
    {
        id: 11,
        iconImage: aioaIconType11
    },
    {
        id: 12,
        iconImage: aioaIconType12
    },
    {
        id: 13,
        iconImage: aioaIconType13
    },
    {
        id: 14,
        iconImage: aioaIconType14
    },
    {
        id: 15,
        iconImage: aioaIconType15
    },
    {
        id: 16,
        iconImage: aioaIconType16
    },
    {
        id: 17,
        iconImage: aioaIconType17
    },
    {
        id: 18,
        iconImage: aioaIconType18
    },
    {
        id: 19,
        iconImage: aioaIconType19
    },
    {
        id: 20,
        iconImage: aioaIconType20
    },
    {
        id: 21,
        iconImage: aioaIconType21
    },
    {
        id: 22,
        iconImage: aioaIconType22
    },
    {
        id: 23,
        iconImage: aioaIconType23
    },
    {
        id: 24,
        iconImage: aioaIconType24
    },
    {
        id: 25,
        iconImage: aioaIconType25
    },
    {
        id: 26,
        iconImage: aioaIconType26
    },
    {
        id: 27,
        iconImage: aioaIconType27
    },
    {
        id: 28,
        iconImage: aioaIconType28
    },
    {
        id: 29,
        iconImage: aioaIconType29
    },
];

interface PropTypes {
    setChangeWidgetIconType: Function,
    changeWidgetIconColor: string
}

const WidgetIconType = (props: PropTypes) => {

    // PropTypes
    const { setChangeWidgetIconType, changeWidgetIconColor } = props;

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();
    const windowWidth = useWindowSize();

    // State
    const [widgetIconType, setWidgetIconType] = useState<number>(1);

    // Set current widget icon type
    useEffect(() => {
        if (currentWebsite) {
            if (currentWebsite.widget_icon_type) {
                setWidgetIconType(currentWebsite.widget_icon_type);
            } else {
                setWidgetIconType(1);
            }
        }
    }, [currentWebsite])

    // set current icon value in prop function
    useEffect(() => {
        if (widgetIconType) {
            setChangeWidgetIconType(widgetIconType);
        }
    }, [widgetIconType])

    return (
        <>
            <fieldset className="aioa_dashboard-widget-settings-fieldset">
                <legend className="visually-hidden">{t("Select Widget Icon Type")}</legend>
                <Row xs={2} sm={3} md={4} lg={5} xl={4} xxl={3} className={`g-4 ${(windowWidth.windowSize > 1800) ? `row-cols-xxxl-5` : `row-cols-xxxl-4`}`}>
                    {widgetIconJson.map((iconType, index) => {
                        return (
                            <Col key={iconType.id}>
                                <div className={`aioa_dashboard-widget-setting-icon-box ${iconType.id === 13 ? `flat-logo` : ``} ${(iconType.id === widgetIconType) ? 'active' : ''}`}>

                                    <Form.Check type="radio" id={`icon_type_${iconType.id}`} aria-label={t(`Icon type`, { widgetIconType: iconType.id }).toString()}>

                                        <Form.Check.Input type="radio" name="widget_icon_type" id="widget_icon_type" checked={iconType.id === widgetIconType} className="" onChange={() => setWidgetIconType(iconType.id)} />

                                        <Form.Check.Label>
                                            <span className="visually-hidden">{t(`Icon type`, { widgetIconType: iconType.id }).toString()}</span>

                                            <div className={`aioa_dashboard-widget-icon-box aioaIconType${iconType.id}`}></div>

                                            <div className="aioa_dashboard-widget-setting-icon-active-icon">
                                                <i className="material-symbols-outlined" aria-hidden="true">done</i>
                                            </div>
                                        </Form.Check.Label>

                                    </Form.Check>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </fieldset>
        </>
    )
}

export default WidgetIconType;