import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { savePrePurchasedDomainApi } from "../../Api/DomainApi/SavePrePurchasedDomainApi";
import { getUserDetailsService } from "../UserAction/LoginActionService";
import { DomainDetailsService } from "./DomainDetails";

const AddPrePurchasedDomainService = async (
    token: string,
    newWebsiteURL: string,
    userID: number,
    newWebsitePlanID: number,
    dispatch: Function,
    t: Function,
    navigate: Function
): Promise<any> => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    const savePrePurchasedDomainApiRes = savePrePurchasedDomainApi(token, newWebsiteURL, userID, newWebsitePlanID).then(async (res) => {
        if (res.status === 200 && res.Data) {
            //console.info("savePrePurchasedDomainApiRes called", res);
            const getUserDetailsServiceRes = getUserDetailsService(token, dispatch, t).then(async (userRes) => {
                //console.info("getUserDetailsServiceRes called", userRes);
                if (userRes.status === 200) {
                    console.log("from add pre purchased domain");
                    const DomainDetailsServiceRes = DomainDetailsService(token, res.Data.id, dispatch, t).then(async (websiteDetailsRes) => {
                        if (websiteDetailsRes.status === 200) {

                            // Show Msg
                            dispatch({
                                type: SET_MESSAGE,
                                payload: {
                                    "toast_message": t('Domain add successfully.'),
                                    "toast_type": "success"
                                }
                            });

                            navigate('/front');

                            // Set Loading
                            dispatch({
                                type: LOADING,
                                payload: false,
                            });

                            // return Statement
                            return websiteDetailsRes;

                        }
                    });

                    // return Statement
                    return DomainDetailsServiceRes;

                } else {

                    // Show Msg
                    dispatch({
                        type: SET_MESSAGE,
                        payload: {
                            "toast_message": t('Error'),
                            "toast_type": "danger"
                        }
                    });

                    // Set Loading
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });

                    // return Statement
                    return userRes;
                }

            });

            return getUserDetailsServiceRes;

        } else {

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { status: 400 };
        }
    });

    return savePrePurchasedDomainApiRes;

}

export { AddPrePurchasedDomainService }