import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";

interface PropTypes {
    setChangeWidgetIconColor: Function,
    changeWidgetIconColor: string
}

const WidgetIconColor = (props: PropTypes) => {

    // Props
    const { setChangeWidgetIconColor, changeWidgetIconColor } = props;

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    // Stets
    const [widgetColor, setWidgetColor] = useState<string>('#420083');

    // set change color
    useEffect(() => {
        if(changeWidgetIconColor) {
            setWidgetColor(changeWidgetIconColor);
        } else {
            setWidgetColor('#420083')
        }
    },[changeWidgetIconColor])

    // Set current widget icon type
    useEffect(() => {
        if (currentWebsite) {
            if (currentWebsite.widget_color_code) {
                setChangeWidgetIconColor(currentWebsite.widget_color_code);
            } else {
                setChangeWidgetIconColor('#420083');
            }
        }
    }, [currentWebsite]);




    return (
        <>
            <SketchPicker
                disableAlpha={true}
                color={widgetColor}
                onChange={(colorObject, e) => {
                    setChangeWidgetIconColor(colorObject.hex);
                }}
            />
        </>
    )
}

export default WidgetIconColor;