import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources: {},
    lng: "en", //default language
    debug: false,
    fallbackLng: "en",
    keySeparator: false,
    nsSeparator: "|",
    interpolation: {
      escapeValue: false,
    },
  }, () => {
    //console.info("i18n initialized");
  });

export default i18n;
