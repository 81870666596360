const baseURL = `${process.env.REACT_APP_BASE_URL}/api/websites`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

const updateWidgetSettingsApi = async (
    token: string,
    websiteID: number,
    changeWidgetIconType: number,
    isChangeWidgetIconCustomSize: boolean,
    changeWidgetCustomIconSize: number,
    isShowCustomWidgetIconSizeMobile: boolean,
    changeWidgetIconSizeMobile: Number,
    isChangeWidgetIconCustomSizeMobile: boolean,
    changeWidgetIconCustomSizeMobile: Number,
    changeWidgetIconSize: number,
    changeWidgetIconColor: string,
    changeWidgetIconPosition: string,
    isChangeWidgetIconCustomPosition: boolean,
    changeWidgetIconCustomLeftPosition: number | null,
    changeWidgetIconCustomRightPosition: number | null,
    changeWidgetIconCustomTopPosition: number | null,
    changeWidgetIconCustomBottomPosition: number | null,
    changeWidgetSize: number,
    changeWidgetAccessibilityStatementLink: string,
    changeGaID: string,
    changeAdobeID: string,
    defaultLanguage: string,
    defaultVoice: string,
): Promise<any> => {

    const url = `${baseURL}/widget-setting-update`;

    const data = new FormData();

    data.append("id", websiteID.toString());
    data.append("widget_icon_type", changeWidgetIconType.toString());
    data.append("widget_icon_size", changeWidgetIconSize.toString());
    data.append("is_widget_custom_size", (isChangeWidgetIconCustomSize ? `1` : `0`));
    isChangeWidgetIconCustomSize && data.append("widget_icon_size_custom", changeWidgetCustomIconSize.toString());
    data.append("is_mobile_icon", (isShowCustomWidgetIconSizeMobile ? `1` : `0`));
    if (isShowCustomWidgetIconSizeMobile) {
        data.append("widget_icon_size_mobile", changeWidgetIconSizeMobile.toString())
        data.append("is_widget_custom_size_mobile", (isChangeWidgetIconCustomSizeMobile ? `1` : `0`))
        if (isChangeWidgetIconCustomSizeMobile) {
            data.append("widget_icon_size_custom_mobile", changeWidgetIconCustomSizeMobile.toString())
        }
    }
    data.append("widget_color_code", changeWidgetIconColor);
    data.append("widget_position", changeWidgetIconPosition);
    data.append("is_widget_custom_position", (isChangeWidgetIconCustomPosition ? `1` : `0`));
    data.append("widget_position_top", changeWidgetIconCustomTopPosition !== null ? changeWidgetIconCustomTopPosition.toString() : ``);
    data.append("widget_position_bottom", changeWidgetIconCustomBottomPosition !== null ? changeWidgetIconCustomBottomPosition.toString() : ``);
    data.append("widget_position_right", changeWidgetIconCustomRightPosition !== null ? changeWidgetIconCustomRightPosition.toString() : ``);
    data.append("widget_position_left", changeWidgetIconCustomLeftPosition !== null ? changeWidgetIconCustomLeftPosition.toString() : ``);
    data.append("widget_size", changeWidgetSize.toString());
    data.append("statement_link", changeWidgetAccessibilityStatementLink);
    data.append("ga_key", changeGaID);
    data.append("adobe_key", changeAdobeID);
    data.append("default_language", defaultLanguage);
    data.append("default_voice", defaultVoice);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { updateWidgetSettingsApi }