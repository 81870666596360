const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};


const getManualAuditReportListApi = async (
    accessToken: string,
    websiteId: number,
    addonID: number,
    limit: number,
    offset: number,
    filter: string
): Promise<any> => {

    const url = `${baseURL}/manual-audit-vpat-list`;

    const formData = new FormData();

    formData.append("website_id", websiteId.toString());
    filter !== '-1' && formData.append("vpatStatus", filter);
    formData.append("limit", limit.toString());
    formData.append("offset", offset.toString());
    formData.append("package_id", addonID.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}


const uploadReportFileApi = async (
    accessToken: string,
    reportID: number,
    userId: number,
    file: File,
    vpatStatus: number
): Promise<any> => {

    const url = `${baseURL}/manual-audit-vpat-upload`;

    const formData = new FormData();

    formData.append("id", reportID.toString());
    formData.append("user_id", userId.toString());
    formData.append("file_upload", file);
    formData.append("vpat_status", vpatStatus.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}


const mailSendReportFileApi = async (
    accessToken: string,
    reportID: number,
    email: string
): Promise<any> => {

    const url = `${baseURL}/manual-audit-vpat-sendmail`;

    const formData = new FormData();

    formData.append("id", reportID.toString());
    formData.append("email", email.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

export { getManualAuditReportListApi, uploadReportFileApi, mailSendReportFileApi }