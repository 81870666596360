import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { packageEnableDisableApi } from "../../Api/PlanListApi";
import { DomainDetailsService } from "../DomainService/DomainDetails";


const addOnEnableDisableService = (
    accessToken: string,
    website_id: number,
    package_id: number,
    package_status: number,
    dispatch: Function,
    t: Function,
) => {

    // Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    packageEnableDisableApi(accessToken, website_id, package_id, package_status).then(response => {
        console.log("from package service");
        if (response.Data) {

            DomainDetailsService(accessToken, website_id, dispatch, t).then((res) => {
                if (res.status === 200) {

                    // Loading
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });

                    dispatch({
                        type: SET_MESSAGE,
                        payload: {
                            "toast_message": (package_status === 1) ? t('Add-On Enable') : t('Add-On Disable'),
                            "toast_type": (package_status === 1) ? 'success' : 'danger'
                        }
                    });
                }
            });

        } else {

            // Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Error sending request for Add-On enable/disable. Please try again later.", { returnObjects: true }),
                    "toast_type": "danger"
                }
            });
        }
    })
}



export { addOnEnableDisableService }