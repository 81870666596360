import { SET_MESSAGE } from "../../Actions/Types";
import { addNewCardApi, deleteCardListApi, getCardListApi, makeDefaultCardApi } from "../../Api/CardAPI";

const getCardListService = (
    accessToken: string,
    setCardListData: Function,
    setLoadingCardList: Function,
) => {

    setLoadingCardList(true);

    getCardListApi(accessToken).then(response => {
        if (response.Data) {
            setCardListData(response.Data);
        }
        setLoadingCardList(false);
    })
}

const addNewCardService = (
    accessToken: string,
    cardToken: string,
    setLoading: Function,
    setCardListData: Function,
    setLoadingCardList: Function,
    setShowCardPopup: Function,
    dispatch: Function
) => {

    setLoading(true);

    addNewCardApi(accessToken, cardToken).then(response => {
        if (response.Data && response.status === 200) {
            getCardListService(accessToken, setCardListData, setLoadingCardList);
            setShowCardPopup(false)

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": response.msg,
                    "toast_type": "success"
                }
            })
            setLoading(false);
        } else {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": response.msg,
                    "toast_type": "danger"
                }
            })
            setLoading(false);
        }
        
    })
}

const deleteCardService = (
    accessToken: string,
    deleteCardID: number,
    setShowDeleteCardPopupLoading: Function,
    setCardListData: Function,
    setLoadingCardList: Function,
    setDeleteCardPopup: Function,
    dispatch: Function,
    t: Function
) => {

    setShowDeleteCardPopupLoading(true);

    deleteCardListApi(accessToken, deleteCardID ).then(response => {
        if (response.Data) {
            //setCardListData(response.Data);
            getCardListService(accessToken, setCardListData, setLoadingCardList);
            setDeleteCardPopup(false)
            
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Card Delete Msg'),
                    "toast_type": "success"
                }
            })
        }
        setShowDeleteCardPopupLoading(false);
    })
}


const makeDefaultCardService = (
    accessToken: string,
    defaultCardID: number,
    //setShowDeleteCardPopupLoading: Function,
    setCardListData: Function,
    setLoadingCardList: Function,
    setDeleteCardPopup: Function
) => {

    //setShowDeleteCardPopupLoading(true);

    makeDefaultCardApi(accessToken, defaultCardID ).then(response => {
        if (response.Data) {
            //setCardListData(response.Data);
            getCardListService(accessToken, setCardListData, setLoadingCardList);
            setDeleteCardPopup(false)
        }
        //setShowDeleteCardPopupLoading(false);
    })
}


export { addNewCardService, getCardListService, deleteCardService, makeDefaultCardService }