import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";

import "../../Assets/scss/pages/payment-page.scss";

const baseURL = `${process.env.REACT_APP_BASE_URL}`

const PDFPaymentPage = () => {

    const { token } = useParams();

    const { t } = useTranslation();

    const bodyClassList = ['add-new-domain-screen'];
    useEffect(() => {
        document.body.classList.remove(...bodyClassList);
    }, [])

    const [iframLoader, setIframLoader] = useState(true);

    const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';

    const iframeHeight = () => {
        let frame = document.getElementById('myIframe');
        setTimeout(() => {
            if (isIFrame(frame) && frame.contentWindow) {
                frame.style.height = frame.contentWindow.document.body.scrollHeight + 250 + 'px';
                setIframLoader(false);
            }
        }, 3000);
    }

    const iframeLoader = () => {
        iframeHeight();
    }

    return (
        <>
            <DashboardPagesTemplate>

                <div className="aioa_dashboard-payment-screen-main">
                    
                        {(token) && (
                            <>
                                <iframe title={t("Payment for PDF").toString()} onLoad={iframeLoader} id="myIframe" src={`${baseURL}/pdf-remediat-purchase/${token}/1`} style={{ width: '100%' }}></iframe>
                            </>
                        )}
                    
                </div>

            </DashboardPagesTemplate>
        </>
    )
}

export default PDFPaymentPage;