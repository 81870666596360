import DashboardPageTitle from "../../Components/InnerPageTitle";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import WidgetSettings from "../../Components/WidgetSettings";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";



const WidgetSettingsPage = () => {

  const { t } = useTranslation();

  // Redux Data
  const { currentWebsite} = useSelector((store: StoreProptypes) => store);

  return (
    <>
      <DashboardPagesTemplate>
        <DashboardPageTitle
          pageTitle={`${t("Widget Settings")}`}
          subPageTitle={`${t('page subtitle', { domainName: currentWebsite?.domain })}`}
        />
        <WidgetSettings />
      </DashboardPagesTemplate>

    </>
  )
}

export default WidgetSettingsPage;