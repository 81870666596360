import React, { forwardRef } from "react";

interface PropTypes {
    length: number,
    children: any,
    refMain: any
}

const MenuButtonListMainElement = forwardRef((prop: PropTypes) => {
    const {length, children, refMain} = prop;
    return (
        <div className={`menu-list ${length > 10 ? 'scroll' : ``}`} ref={refMain}>
            {children}
        </div>
    )
});

export default MenuButtonListMainElement;