import { SET_GOOGLE_VOICES } from "../../Actions/Types";
import { GoogleGetVoicesAPI } from "../../Api/GoogleTTSAPI"

const GoogleGetVoicesService = (dispatch: Function) => {
    GoogleGetVoicesAPI().then(response => {
        //console.log("voice list response", response);
        if (response) {
            dispatch({ type: SET_GOOGLE_VOICES, payload: response.voices })
        }
    })
}

export { GoogleGetVoicesService };