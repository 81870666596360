const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

const generatePDFApi = async (token: string, websiteID: number, languageCode: string): Promise<any> => {

    const url = `${baseURL}/generate-pdf-report`;

    const data = new FormData();
    data.append("website_id", websiteID.toString());
    data.append("langcode", languageCode);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}




export { generatePDFApi };