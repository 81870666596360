import { useEffect, useRef, useState } from "react";
import DashboardFooter from "../Components/Footer";
import DashboardHeader from "../Components/Header";
import DashboardSidebarMenu from "../Components/SidebarMenu";
import WebsitePlanStatusMsg from "../Components/WebsitePlanStatusMsg";
import MainPageTemplate from "./MainPageTemplate";
import useWindowSize from "../Helpers/useWindowSize";

interface PropTypes {
    children: any
}

const DashboardPagesTemplate = (prop: PropTypes) => {
    // Set Props
    const { children } = prop;

    // Function
    const closeButtonRef = useRef<HTMLButtonElement | null>(null);
    const { windowSize } = useWindowSize();

    // State
    const [firstLoadTime, setFirstTimeLoad] = useState(true);
    const [mobileMenu, setMobileMenu] = useState(true);


    // Navigation Menu class
    const bodyClassList = ['ada_dashboard-sidebar-navigation-open', 'overflow-hidden']
    const handelMobileMenu = () => {
        if (document.body.classList.contains('ada_dashboard-sidebar-navigation-open')) {
            document.body.classList.remove(...bodyClassList);
            setMobileMenu(false);
        } else {
            document.body.classList.add(...bodyClassList);
            setMobileMenu(true);
        }
    }

    const handelCloseMobileMenu = () => {
        document.body.classList.remove(...bodyClassList);
        setMobileMenu(false);
    }

    useEffect(() => {
        if (windowSize <= 1399 && !mobileMenu && closeButtonRef && closeButtonRef.current) {
            if (!firstLoadTime) {
                closeButtonRef.current.focus();
            } else {
                setFirstTimeLoad(false);
            }
        }
    }, [mobileMenu]);

    return (
        <>
            <MainPageTemplate>
                <DashboardSidebarMenu mobileMenu={mobileMenu} handelMobileMenu={handelMobileMenu} handelCloseMobileMenu={handelCloseMobileMenu} />
                <div className="aioa_dashboard-content-wrapper">
                    <DashboardHeader handelMobileMenu={handelMobileMenu} />
                    <main id="main">
                        <WebsitePlanStatusMsg />
                        <div className="aioa_dashboard-page-content aioa_dashboard-pages">
                            {children}
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </MainPageTemplate>
        </>
    )
}

export default DashboardPagesTemplate;