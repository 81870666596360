import { useTranslation } from "react-i18next";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import EditPersonalInformation from "../../Components/Profile/ProfileInformation";
import EditPassword from "../../Components/Profile/UpdatePassword";

import "../../Assets/scss/pages/profile.scss";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useSelector } from "react-redux";
import DashboardBlankPagesTemplate from "../../Template/DashboardBlankPagesTemplate";

const ProfilePage = () => {

  // Functions
  const { t } = useTranslation();

  // Redux Data
  const { currentUserStatus } = useSelector((store: StoreProptypes) => store);

  return (
    <>
      {currentUserStatus && (
        <>
          {currentUserStatus.isInitUser ? (
            <>
              <DashboardBlankPagesTemplate>

                <div className="aioa_dashboard-blank-pages-content-inner-wrapper">

                  <DashboardPageTitle
                    pageTitle={`${t("Profile")}`}
                    subPageTitle={`${t('Update your profile information')}`}
                  />

                  <div className="aioa-dashboard-profile-content">
                    <EditPersonalInformation />
                    <EditPassword />
                  </div>

                </div>

              </DashboardBlankPagesTemplate>
            </>
          ) : (
            <>
              <DashboardPagesTemplate>

                <DashboardPageTitle
                  pageTitle={`${t("Profile")}`}
                  subPageTitle={`${t('Update your profile information')}`}
                />

                <div className="aioa-dashboard-profile-content">
                  <EditPersonalInformation />
                  <EditPassword />
                </div>

              </DashboardPagesTemplate>
            </>
          )}
        </>
      )}


    </>
  )
}

export default ProfilePage;