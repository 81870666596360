import { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap';

interface PropsTypes {
    currentPage: number,
    totalRecords: number,
    itemsPerPage: number,
    setCurrentPage: Function,
    key?: number
}

const PaginationComponent = (props: PropsTypes) => {
    const { currentPage, totalRecords, itemsPerPage, setCurrentPage, key } = props;

    const totalPages = Math.ceil(totalRecords / itemsPerPage);
    const [midpoint, setMidpoint] = useState<number>(0);

    useEffect(() => {
        //setMidpoint(Math.ceil(totalPages / 2));
        setMidpoint(4);
    }, [totalPages])

    const paginationItems = [];
    const createPaginationItem = (item: number) => {
        return <Pagination.Item key={item} active={item === currentPage} onClick={() => setCurrentPage(item)}>
            {item}
        </Pagination.Item>
    };

    useEffect(() => {
        if (currentPage > 5 && currentPage < totalPages - 2) {
            setMidpoint(currentPage);
        }
        else if (currentPage > 6 && currentPage >= totalPages - 2) {
            setMidpoint(totalPages - 3);
        }
    }, [currentPage, totalPages])

    const shiftMidpointDown = () => {
        if (midpoint - 5 <= 3) {
            setMidpoint(4);
        }
        else {
            setMidpoint(midpoint - 5);
        }
    }

    const shiftMidpointUp = () => {
        if (midpoint + 5 >= totalPages - 1) {
            setMidpoint(totalPages - 3);
        }
        else {
            setMidpoint(midpoint + 5);
        }
    }

    if (midpoint !== 0) {
        if (totalPages < 10) {
            for (let item = 1; item <= totalPages; item++) {
                paginationItems.push(
                    createPaginationItem(item)
                );

            }
        }
        else {
            
            paginationItems.push(createPaginationItem(1));
            //paginationItems.push(createPaginationItem(currentPage > 1 ? currentPage : currentPage));
            paginationItems.push(
                <Pagination.Ellipsis key="shiftPaginationLeft"
                    disabled={midpoint <= 4}
                    onClick={() => shiftMidpointDown()}
                    className={`${midpoint <= 4 ? 'd-none' : ''}`} />);

            for (let item = midpoint - 2; item <= midpoint + 2; item++) {
                paginationItems.push(createPaginationItem(item));
            }
            paginationItems.push(
                <Pagination.Ellipsis
                    key="shiftPaginationRight"
                    disabled={midpoint >= totalPages - 3}
                    onClick={() => shiftMidpointUp()}
                    className={`${midpoint >= totalPages - 3 ? 'd-none' : ''}`}
                />);
            paginationItems.push(createPaginationItem(totalPages));
        }
    }

    return (
        <>
            {totalPages > 1 &&
                <Pagination className='justify-content-center align-items-center flex-wrap mb-0'>
                    <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1}  />
                    <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}  />
                    {paginationItems}
                    <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} />
                    <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />

                </Pagination>
            }
        </>
    )
}

export default PaginationComponent
