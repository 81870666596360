import React from "react";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../PropTypes/StoreProptypes";
import { routesList } from '../Routes';
import ErrorPage from '../Pages/ErrorPage';
import ProtectedRoute from '../Routes/ProtectedRoute';
import { BrowserRouter, Routes, Route } from "react-router-dom";



function BrowserRouterComponents() {
    const { user } = useSelector((state: StoreProptypes) => state);

    return (
        <>

            <BrowserRouter>
                <React.Suspense>
                    <Routes>
                        {routesList.map(
                            (route) => {
                                let index = (route.path === '/' ? true : false);
                                return (
                                    <Route
                                        index={index}
                                        path={route.path}
                                        element={route.protected ? <ProtectedRoute userid={user && user?.id}>{route.element}</ProtectedRoute> : route.element}
                                        key={route.path}
                                    />
                                )
                            }
                        )}

                        <Route path="*" element={<ErrorPage />}>

                        </Route>

                    </Routes>
                </React.Suspense>

            </BrowserRouter>
        </>
    );
}

export default BrowserRouterComponents;
