import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { domainValidationAPI } from "../../Api/DomainApi/DomainValidationApi";

const domainValidationService = async (
    newWebsiteURL: string,
    dispatch: Function,
    t: Function
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    const domainValidationRes = await domainValidationAPI(newWebsiteURL).then(async (validationRes) => {
        //console.info("Condition ==>", newWebsiteURL.replaceAll(" ", ""), (newWebsiteURL.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]), (newWebsiteURL.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0] === validationRes.data.domain))
        if (validationRes.data && newWebsiteURL.replaceAll(" ", "").replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0] === validationRes.data.domain && validationRes.status === 1) {
            
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Website Exist Msg'),
                    "toast_type": "danger"
                }
            });

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { states: 300 };

        } else if ((validationRes.data === null || validationRes.data.length === 0) && validationRes.status === 1) {
            
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Website Not Exist Msg'),
                    "toast_type": "danger"
                }
            });

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { states: 400 };
            
        } else {

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { states: 200 };
        }
    });

    return domainValidationRes;
}

export { domainValidationService }