import { useTranslation } from 'react-i18next';
import '../../Assets/scss/components/init-msg-block.scss';
import { Link } from 'react-router-dom';

const FreeVersionOverlay = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className='aioa_dashboard-init-msg-block d-flex align-items-center justify-content-center'>
                <div className='upgrade-to-paid-popup-wrapper text-center rounded-4 bg-white shadow-lg p-5'>
                    <p className='fs-4 fw-bold'>{t("Upgrade to the paid version to view all the Dashboard features")}</p>
                    <Link to="/front/manage-plan" className='btn btn-primary btn-lg'>{t("Upgrade Plan Button")}</Link>
                </div>
            </div>

        </>
    )
}

export default FreeVersionOverlay;