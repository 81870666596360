import { Button, Form, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useEffect, useState } from "react";
import { invoicePDFService, sendInvoiceEmailService } from "../../Services/UserOrderService";
import { SET_MESSAGE } from "../../Actions/Types";

interface PropTypes {
    paymentStatus: string,
    purchaseURL: string,
    orderItemIndex: number,
    orderItemID: number,
    totalOrderItems: number
}
const OrderListCellActionButtons = (props: PropTypes) => {

    // Redux Data
    const { user, accessToken } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // props
    const { paymentStatus, purchaseURL, orderItemIndex, orderItemID, totalOrderItems } = props;

    // state
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [loadingDownload, seLoadingDownload] = useState<boolean[]>([]);
    const [sendInvoiceEmail, setSendInvoiceEmail] = useState<string>('');
    const [isSendInvoiceEmailModel, setIsSendInvoiceEmailModel] = useState<boolean>(false);

    // Create Array
    useEffect(() => {
        if (totalOrderItems) {
            seLoadingDownload(new Array(totalOrderItems).fill(false));
        }
    }, [totalOrderItems])

    // Disabled Button Functions
    useEffect(() => {
        if (user && user.department === 1) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }

        if(user) {
            setSendInvoiceEmail(user.email);
        }

    }, [user])

    // Retry Payment
    const handleRetryPlanPayment = () => {
        window.open(purchaseURL, '_blank');
    }

    // Get Invoice URL and open New Tab
    const handleGetInvoiceSubmit = () => {
        if (accessToken && orderItemID && totalOrderItems) {
            invoicePDFService(accessToken, orderItemID, loadingDownload, seLoadingDownload, orderItemIndex, totalOrderItems)
        }
    }

    // Send Invoice to mail
    const setConfirmEmailModelShow = () => {
        setIsSendInvoiceEmailModel(true);
    }
    const setConfirmEmailModelHide = () => {
        setIsSendInvoiceEmailModel(false);
    }

    const handleSendInvoiceSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (sendInvoiceEmail === '') {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t(`user email validation msg`, { returnObjects: true }),
                    "toast_type": "danger"
                }
            })
        } else {
            if (accessToken && sendInvoiceEmail && orderItemID) {
                sendInvoiceEmailService(accessToken, orderItemID, sendInvoiceEmail, dispatch, t, setConfirmEmailModelHide);
            }
        }
    }

    return (
        <>
            {(paymentStatus === "Failed") ? (
                <>
                    <div className='text-center'>
                        {purchaseURL ? (
                            <>
                                <Button disabled={disabledButton} onClick={() => handleRetryPlanPayment()}>{t('Retry Payment')}</Button>
                            </>
                        ) : "-"}
                    </div>
                </>
            ) : (
                <>
                    <div className='text-center aioa_dashboard-order-list-action-btns'>
                        <OverlayTrigger
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="email-tooltip">{t("Download Invoice")}</Tooltip>}
                        >
                            <Button variant={`${(loadingDownload && loadingDownload[orderItemIndex]) ? `primary` : `outline-primary`}`} aria-label={t("Download Invoice").toString()} onClick={() => handleGetInvoiceSubmit()}>
                                {(loadingDownload && loadingDownload[orderItemIndex]) ? <Spinner variant="light" size="sm" animation='grow' /> : <i className="material-symbols-outlined" aria-hidden="true">download</i>}
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="email-tooltip">{t("Email Invoice")}</Tooltip>}
                        >
                            <Button variant='outline-secondary' aria-label={t("Email Invoice").toString()} onClick={() => setConfirmEmailModelShow()}>
                                <i className="material-symbols-outlined" aria-hidden="true">mail</i>
                            </Button>
                        </OverlayTrigger>

                        <Modal show={isSendInvoiceEmailModel} onHide={setConfirmEmailModelHide} centered size="lg" backdrop="static" fullscreen="lg-down">
                            <Modal.Header closeButton>
                                <Modal.Title>Email PDF (Invoice)</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="text-danger mb-3 fw-semibold">Would you like to send a copy of this document to your email address, </div>
                                <Form>
                                    <Form.Group className="form-group" controlId="user_email">
                                        <Form.Control type="email" size="lg" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSendInvoiceEmail(e.target.value)} value={sendInvoiceEmail} aria-required="true" autoComplete="email"/>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" size="lg" onClick={setConfirmEmailModelHide}>
                                    {t('Cancel')}
                                </Button>
                                <Button variant="primary" size="lg" onClick={handleSendInvoiceSubmit}>
                                    {t('Email PDF')}
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </>
            )}
        </>
    )
}

export default OrderListCellActionButtons;