import { useState, useEffect, useMemo } from "react";
import { debounce } from 'lodash';
import { useTranslation } from "react-i18next";
import { Form, InputGroup, Button, Spinner, Alert, Modal, Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useNavigate, useParams } from 'react-router';
import DownloadIcon from "../../Assets/img/download-icn.svg";
import NoRecordFound from "../../Components/NoRecordFound";
import encodeBase64 from "../../Helpers/encodeBase64";
import { validBulkDomainListPropTypes } from "../../PropTypes/WebsitePropTypes";
import { bulkDomainListActionService, checkBulkDomainListService } from "../../Services/BulkDomainService";
import { domainValidationService } from "../../Services/DomainService/DomainValidationService";
import { websitePlanPropType } from "../../PropTypes/WebsitePlanPropType";

const baseURL = `${process.env.REACT_APP_BASE_URL}`

const SaveBulkDomain = () => {

    // Function
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Redux data
    const { accessToken, websiteList, activeLanguage, user, currentWebsite, currentWebsitePlanList } = useSelector((store: StoreProptypes) => store);

    // State
    const [bulkDomainStep01, setBulkDomainStep01] = useState<boolean>(true);
    const [bulkDomainStep02, setBulkDomainStep02] = useState<boolean>(false);

    const [loadingFileUpload, setLoadingFileUpload] = useState(false);
    const [customXlsxFile, setCustomXlsxFile] = useState<File | null>();
    const [customXlsxFileName, setCustomXlsxFileName] = useState('');
    const [customXlsxFileValidation, setCustomXlsxFileValidation] = useState<boolean>(true);
    const [customXlsxFileValidationMsg, setCustomXlsxFileValidationMsg] = useState('');
    const [fileKey, setFileKey] = useState(0);
    const [validDomainTotalPrice, setValidDomainTotalPrice] = useState<number>(0);

    const [validDomain, setValidDomain] = useState<validBulkDomainListPropTypes[]>();
    const [validDomain02, setValidDomain02] = useState<validBulkDomainListPropTypes[]>();
    const [inValidDomain, setInValidDomain] = useState<string[]>();
    const [existDomain, setExistDomain] = useState<string[]>();
    const [duplicateDomain, setDuplicateDomain] = useState<string[]>();
    const [planList, setPlanList] = useState<websitePlanPropType[]>([]);

    const [isShowInfoModal, setIsShowInfoModal] = useState<boolean>(false);
    const [infoModalType, setInfoModalType] = useState<number>(1);
    const [infoModalDomainObj, setInfoModalDomainObj] = useState<validBulkDomainListPropTypes[]>([]);

    const [isShowDomainListModal, setIsShowDomainListModal] = useState<boolean>(false);
    const [domainListModalType, setDomainListModalType] = useState<number>(0);
    const [modalValidDomainList, setModalValidDomainList] = useState<validBulkDomainListPropTypes[]>([]);
    const [modalOtherDomainList, setModalOtherDomainList] = useState<string[]>([]);

    const [errorMsg, setErrorMsg] = useState('');


    // Step
    const bulkDomainPopupStep01 = () => {
        if (validDomain && validDomain.length > 0) {
            setBulkDomainStep01(!bulkDomainStep01);
            setBulkDomainStep02(!bulkDomainStep02);
        }
    }

    // Info Modal Function
    const handleShowInfoModal = (type: number, domainObj?: validBulkDomainListPropTypes) => {
        setInfoModalType(type);
        if (type === 2 && domainObj) {
            setInfoModalDomainObj([domainObj]);
        }
        setIsShowInfoModal(true);
    }
    const handleHideInfoModal = () => {
        setIsShowInfoModal(false);
        setInfoModalType(1);
        setInfoModalDomainObj([]);
    }

    // Domain List Modal Function
    const handleShowDomainListModal = (type: number, validDomainList?: validBulkDomainListPropTypes[], otherDomainList?: string[]) => {
        setDomainListModalType(type);
        if (type === 1 && validDomainList) {
            setModalValidDomainList(validDomainList);
            setModalOtherDomainList([]);
        } else {
            if (otherDomainList) {
                setModalOtherDomainList(otherDomainList);
                setModalValidDomainList([]);
            }
        }
        setIsShowDomainListModal(true);
    }
    const handleHideDomainListModal = () => {
        setIsShowDomainListModal(false);
        setDomainListModalType(0);
        setModalValidDomainList([]);
        setModalOtherDomainList([]);
    }

    // Upload File Function
    const changeHandler = (e: any) => {
        const target = e.target;
        const fileUploaded = target.files;

        if (e.target.files[0] !== undefined) {
            if ((e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                setCustomXlsxFileValidation(false);
                const fileSize = parseFloat((((e.target.files[0].size) / 1024) / 1024).toFixed(2));

                if ((fileSize > 2)) {
                    setCustomXlsxFileValidation(true);
                    setCustomXlsxFileValidationMsg(`${t('file size msg')}`);
                } else {
                    setCustomXlsxFile(e.target.files[0]);
                    setCustomXlsxFileName(e.target.files[0].name)

                    if (fileUploaded) {
                        let reader = new FileReader();
                        reader.readAsDataURL(fileUploaded[0]);

                        reader.onload = (e) => {
                            if (reader && typeof reader.result === "string") {
                                if (accessToken) {
                                    checkBulkDomainListService(accessToken, target.files[0], dispatch, t, setValidDomain, setInValidDomain, setExistDomain, setDuplicateDomain);
                                }
                            }
                        }
                    }
                }
            } else {
                setCustomXlsxFileValidation(true);
                setCustomXlsxFileValidationMsg(`${t('file validation msg')}`)
            }
        }

        setFileKey(fileKey + 1);
    };

    // Error Handle Function
    const handleDomainError = (objID: number, domain: string) => {
        if (objID && validDomain) {
            const selectedItem = validDomain.map((item) => {
                if (item.id === objID) {
                    const tempObj = {
                        ...item,
                        "domain": domain,
                        'is_error': 1
                    }
                    return tempObj
                } else {
                    const tempObj = {
                        ...item,
                        'is_error': 2
                    }
                    return tempObj
                }
            })
            selectedItem && setValidDomain(selectedItem);
        }
    }

    // handle Domain Action
    const handleDomainAction = (objID: number, domain: string, planId: number, action: string) => {
        if (accessToken) {
            bulkDomainListActionService(accessToken, objID, domain, planId, action, dispatch, t, setValidDomain, handleHideInfoModal);
        }
    }

    // Change Domain Name 
    const handelDomainNameUpdate = (objID: number, domain: string, planId: number, action: string) => {

        var httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
        const tempObj = validDomain?.filter((filterItem) => (filterItem.id === objID && filterItem.domain === domain && filterItem.plan_id === planId));

        if (validDomain && validDomain.length > 0) {
            if (domain === '') {
                setErrorMsg(`${t('website invalid msg')}`);
                handleDomainError(objID, domain);
            } else if (httpRegex.test(domain)) {
                setErrorMsg(`${t('website invalid msg')}`);
                handleDomainError(objID, domain);
            } else if (validDomain && validDomain.filter((domainFilter) => domainFilter.domain === domain && domainFilter.id !== objID).length > 0) {
                setErrorMsg(`${t('website already exists msg')}`);
                handleDomainError(objID, domain);
            } else {
                if (tempObj && tempObj.length > 0) {
                    //console.log("No change");
                } else {
                    domainValidationService(domain.replace(/\s+/g, ''), dispatch, t).then((res) => {
                        //console.info("res.states ==>", res.states)
                        if (res.states === 300) {
                            setErrorMsg(`${t('Website Exist Msg')}`);
                            handleDomainError(objID, domain);
                        } else if (res.states === 400) {
                            setErrorMsg(`${t('Website Not Exist Msg')}`);
                            handleDomainError(objID, domain);
                        } else {
                            handleDomainAction(objID, domain, planId, action);
                        }
                    });
                }
            }
        }
    }

    // Get Plan List
    useEffect(() => {
        if (currentWebsitePlanList && Object.keys(currentWebsitePlanList).length > 0) {
            let tempPlanList: websitePlanPropType[] = [];

            currentWebsitePlanList[`normal`].map((planList) => {
                tempPlanList = [...tempPlanList, planList];
            });

            setPlanList(tempPlanList);
        }
    }, [accessToken]);

    // Price Sum
    useEffect(() => {
        if (validDomain && validDomain.length > 0) {
            // Price  
            const sum = validDomain.reduce((accumulator, object) => {
                return accumulator + object.price;
            }, 0);
            validDomain && setValidDomainTotalPrice(sum);
        } else {
            setValidDomainTotalPrice(0);
        }
    }, [validDomain]);

    // Bulk Domain Payment 
    const handleBulkDomainPaymentProcess = () => {
        if (user && activeLanguage) {
            const urlString = `1|1|1|${user.id}|${activeLanguage.code}|4`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        }
    }

    // close Bulk Domain Modal
    const handleDomainListBackButton = () => {
        setValidDomain([]);
        setInValidDomain([]);
        setExistDomain([]);
        setDuplicateDomain([]);

        setCustomXlsxFileName('');

        setBulkDomainStep02(false);
        setBulkDomainStep01(true);
    }

    return (
        <>
            <Form>

                <div className="aioa_dashboard-bulk-domain-wrapper">

                    {/* Screen 1 */}

                    {bulkDomainStep01 && (
                        <>
                            <div className="aioa_dashboard-bulk-domain-modal-screen-01">

                                <div className="aioa_dashboard-bulk-domain-modal-sample-download">
                                    <Button className="aioa_dashboard-bulk-domain-modal-sample-download-btn" variant="link" aria-label={t("Learn more about how to add domain in xlxs file.").toString()} onClick={() => handleShowInfoModal(1)}><i className={`material-symbols-outlined`} aria-hidden="true">info</i></Button>
                                    <a href={(user && user.id === 1101) ? `${baseURL}/bulk_import_sample.xlsx` : `${baseURL}/Domain_import_sample.xlsx`} className="download-btn" download>
                                        <span className={`btn-text ${activeLanguage?.is_rtl ? `ms-2` : `me-2`}`}>{t('download sample file btn text')}</span>
                                        <span className="btn-icon"><img src={DownloadIcon} alt={``} /></span>
                                    </a>
                                </div>

                                <div className="aioa_dashboard-bulk-domain-upload-form">

                                    <div className="upload-form-input">
                                        <div className={`logo-upload-input ${customXlsxFileValidation && customXlsxFileValidationMsg ? `error` : customXlsxFileName ? `success` : ``}`}>
                                            <Form.Label className="btn btn-primary" htmlFor="file_upload">{t('upload domain bulk file label text')}</Form.Label>
                                            <InputGroup className="input-group-outline ">
                                                <Form.Control
                                                    isValid={customXlsxFileValidation && customXlsxFileValidationMsg ? false : true}
                                                    isInvalid={customXlsxFileValidation && customXlsxFileValidationMsg ? true : false}
                                                    aria-describedby="file_upload_description"
                                                    id="file_upload"
                                                    type="file"
                                                    key={fileKey}
                                                    onChange={(e) => changeHandler(e)} className="ps-0"
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="file-url">{customXlsxFileName}</div>
                                    </div>

                                    <div id="file_upload_description">
                                        {!customXlsxFileValidation && (
                                            <>
                                                <div className="mt-3">
                                                    <strong className="text-danger">{customXlsxFileValidationMsg}</strong>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    {(inValidDomain && inValidDomain.length > 0) && (
                                        <>
                                            <Alert variant='danger' className="mt-3">
                                                {inValidDomain.length === 1 ? (
                                                    <>
                                                        {t('single domain not valid msg', { domainName: inValidDomain[0] })}
                                                    </>
                                                ) : inValidDomain.length === 2 ? (
                                                    <>
                                                        {t('two domain not valid msg', { domainName1: inValidDomain[0], domainName2: inValidDomain[1] })}
                                                    </>
                                                ) : inValidDomain.length === 3 ? (
                                                    <>
                                                        {t('three domain not valid msg', { domainName1: inValidDomain[0], domainName2: inValidDomain[1], domainName3: inValidDomain[2] })}
                                                    </>
                                                ) : (
                                                    <>
                                                        {inValidDomain[0]}, {inValidDomain[1]} and <strong role="button" style={{ textDecoration: 'underline' }} onClick={() => handleShowDomainListModal(2, [], inValidDomain)}>{inValidDomain.length - 2} other</strong> are not valid.
                                                    </>
                                                )}
                                            </Alert>
                                        </>
                                    )}

                                    {(existDomain && existDomain.length > 0) && (
                                        <>
                                            <Alert variant='danger' className="mt-3">
                                                {existDomain.length === 1 ? (
                                                    <>
                                                        {t('single domain exist msg', { domainName: existDomain[0] })}
                                                    </>
                                                ) : existDomain.length === 2 ? (
                                                    <>
                                                        {t('two domain exist msg', { domainName1: existDomain[0], domainName2: existDomain[1] })}
                                                    </>
                                                ) : existDomain.length === 3 ? (
                                                    <>
                                                        {t('three domain exist msg', { domainName1: existDomain[0], domainName2: existDomain[1], domainName3: existDomain[2] })}
                                                    </>
                                                ) : (
                                                    <>
                                                        {existDomain[0]}, {existDomain[1]} and <strong role="button" style={{ textDecoration: 'underline' }} onClick={() => handleShowDomainListModal(2, [], existDomain)}>{existDomain.length - 2} other</strong> are already exist.
                                                    </>
                                                )}
                                            </Alert>
                                        </>
                                    )}

                                    {(duplicateDomain && duplicateDomain.length > 0) && (
                                        <>
                                            <Alert variant='danger' className="mt-3">
                                                {duplicateDomain.length === 1 ? (
                                                    <>
                                                        {t('single domain duplicate msg', { domainName: duplicateDomain[0] })}
                                                    </>
                                                ) : duplicateDomain.length === 2 ? (
                                                    <>
                                                        {t('two domain duplicate msg', { domainName1: duplicateDomain[0], domainName2: duplicateDomain[1] })}
                                                    </>
                                                ) : duplicateDomain.length === 3 ? (
                                                    <>
                                                        {t('three domain duplicate msg', { domainName1: duplicateDomain[0], domainName2: duplicateDomain[1], domainName3: duplicateDomain[2] })}
                                                    </>
                                                ) : (
                                                    <>
                                                        {duplicateDomain[0]}, {duplicateDomain[1]} and <strong style={{ textDecoration: 'underline' }} role="button" onClick={() => handleShowDomainListModal(2, [], duplicateDomain)}>{duplicateDomain.length - 2} other</strong> are duplicate domain.
                                                    </>
                                                )}
                                            </Alert>
                                        </>
                                    )}

                                    {(validDomain && validDomain.length > 0) && (
                                        <>
                                            <Alert variant='success' className="mt-3">
                                                {validDomain.length === 1 ? (
                                                    <>
                                                        {t('single domain valid msg', { domainName: validDomain[0].domain })}
                                                    </>
                                                ) : validDomain.length === 2 ? (
                                                    <>
                                                        {t('two domain valid msg', { domainName1: validDomain[0].domain, domainName2: validDomain[1].domain })}
                                                    </>
                                                ) : validDomain.length === 3 ? (
                                                    <>
                                                        {t('three domain valid msg', { domainName1: validDomain[0].domain, domainName2: validDomain[1].domain, domainName3: validDomain[2].domain })}
                                                    </>
                                                ) : (
                                                    <>
                                                        {validDomain[0].domain}, {validDomain[1].domain} and <strong role="button" style={{ textDecoration: 'underline' }} onClick={() => handleShowDomainListModal(1, validDomain, [])}>{validDomain.length - 2} other</strong> are valid domain.
                                                    </>
                                                )}
                                            </Alert>
                                        </>
                                    )}

                                    {validDomain && validDomain.length > 0 && (
                                        <>
                                            <div className="mt-4 step-submit-btn">

                                                <Button variant="primary" onClick={() => bulkDomainPopupStep01()} size="lg" className="aioa_dashboard-icon-btn icon-right">
                                                    <div className="aioa_dashboard-btn-text">{t('next button label')}</div>
                                                    <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">arrow_right_alt</i></div>
                                                </Button>

                                            </div>
                                        </>
                                    )}

                                </div>

                            </div>
                        </>
                    )}

                    {/* Screen 02 */}

                    {bulkDomainStep02 && (
                        <>
                            <div className="aioa_dashboard-bulk-domain-modal-screen-02">
                                <div className="aioa_dashboard-table">
                                    <div className="aioa_dashboard-table-thead">
                                        <div className="aioa_dashboard-table-tr">
                                            <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap">{t("Sr No")}</div>
                                            <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap">{t("Domain")}</div>
                                            <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Select Plan")}</div>
                                            <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Price(USD)")}</div>
                                            <div className="aioa_dashboard-table-th tcw-100 text-center aioa_dashboard-table-cell-sidegap">{t("action table cell label")}</div>
                                        </div>
                                    </div>
                                    <div className="aioa_dashboard-table-body" aria-live='polite'>
                                        {validDomain ? (
                                            <>
                                                {validDomain.length > 0 ? (
                                                    <>
                                                        {validDomain.map((validDomainItem, index) => {
                                                            return (
                                                                <div className="aioa_dashboard-table-tr" key={validDomainItem.id}>

                                                                    <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                                                                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Sr No")}</div>
                                                                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{index + 1}</div>
                                                                    </div>

                                                                    <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                                                                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Domain")}</div>
                                                                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                                                                            <div className="domain-name">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    aria-label={t("Domain").toString()}
                                                                                    defaultValue={validDomainItem.domain}
                                                                                    onBlur={(e) => handelDomainNameUpdate(validDomainItem.id, e.target.value, validDomainItem.plan_id, 'edit')}
                                                                                    disabled={validDomainItem.is_error === 2 ? true : false}
                                                                                    className={`${validDomainItem.is_error === 1 ? `error` : ``}`}
                                                                                />
                                                                                {validDomainItem.is_error === 1 ? (
                                                                                    <>
                                                                                        <div className="error-msg text-danger mt-1">{errorMsg}</div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>

                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                                                                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Select Plan")}</div>
                                                                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Select Plan").toString()}>
                                                                            <Form.Select
                                                                                aria-label={t("Select Plan").toString()}
                                                                                onChange={(e) => handleDomainAction(validDomainItem.id, validDomainItem.domain, parseInt(e.target.value), 'edit')}
                                                                                disabled={(validDomainItem.is_error === 2 || validDomainItem.is_error === 1) ? true : false}
                                                                            >
                                                                                {planList && planList.map((planItem) => {
                                                                                    return (
                                                                                        <option value={planItem.id} selected={planItem.id === validDomainItem.plan_id ? true : false}>{planItem.name}</option>
                                                                                    )
                                                                                })}
                                                                            </Form.Select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                                                                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Price(USD)")}</div>
                                                                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Price(USD)").toString()}>
                                                                            <div className="original-price">
                                                                                <del><strong>${validDomainItem.price + validDomainItem.discount}/{t('Per Year')}</strong></del>
                                                                            </div>
                                                                            <div className="offer-price">
                                                                                <strong className="mt-1">${validDomainItem.price}/{t('Per Year')}</strong>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="aioa_dashboard-table-td tcw-100 text-center aioa_dashboard-table-cell-sidegap">
                                                                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("action table cell label")}</div>
                                                                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("action table cell label").toString()}>
                                                                            <Button
                                                                                aria-label={t("Delete").toString()}
                                                                                onClick={() => handleShowInfoModal(2, validDomainItem)}
                                                                                variant="danger"
                                                                                className={`action-btn ${(validDomainItem.is_error === 2) ? `opacity-5` : ``}`}
                                                                                disabled={(validDomainItem.is_error === 2) ? true : false}>
                                                                                <i className="material-symbols-outlined" aria-hidden="true">delete</i>
                                                                            </Button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )
                                                        })}

                                                        <div className="aioa_dashboard-table-tr final-price">

                                                            <div className="aioa_dashboard-table-td tcw-auto text-end aioa_dashboard-table-cell-sidegap">
                                                                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Total Cost")}</div>
                                                                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Total Cost").toString()}>
                                                                    <div className="fs-5"><strong>{t("Total Cost")}</strong></div>
                                                                </div>
                                                            </div>

                                                            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                                                                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Total Cost")}</div>
                                                                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Total Cost").toString()}>
                                                                    <div className="fs-5"><strong>${validDomainTotalPrice.toFixed(2)}</strong></div>
                                                                </div>
                                                            </div>

                                                            <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap" aria-hidden="true">
                                                                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Total Cost")}</div>
                                                                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Total Cost").toString()}>&nbsp;</div>
                                                            </div>

                                                        </div>

                                                    </>
                                                ) : (
                                                    <>
                                                        <div className='p-4'>
                                                            <NoRecordFound imageType={`domain`} joyful={false} noRecordText={`${t('Domain not found')}`} />
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>

                                            </>
                                        )}
                                    </div>
                                </div>


                                {validDomain && validDomain.length > 0 ? (
                                    <>
                                        <div className="aioa_dashboard-bulk-domain-modal-pay-btn">
                                            <Button
                                                variant="primary"
                                                onClick={() => handleBulkDomainPaymentProcess()}
                                                size="lg" className="aioa_dashboard-icon-btn icon-right"
                                                disabled={validDomain.filter((filterItem) => filterItem.is_error === 1).length > 0 ? true : false}
                                            >
                                                <div className="aioa_dashboard-btn-text">{t('Pay Now')}</div>
                                                <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">arrow_right_alt</i></div>
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="aioa_dashboard-bulk-domain-modal-pay-btn">
                                            <Button
                                                variant="danger"
                                                onClick={() => handleDomainListBackButton()}
                                                size="lg"
                                                className="aioa_dashboard-icon-btn"
                                            >
                                                <div className="aioa_dashboard-btn-icon">
                                                    <i className="material-symbols-outlined" aria-hidden="true">keyboard_backspace</i>
                                                </div>
                                                <div className="aioa_dashboard-btn-text">{t('Back')}</div>
                                            </Button>

                                        </div>
                                    </>
                                )}
                            </div>


                        </>
                    )}

                    {/* Info Popup */}
                    <Modal show={isShowInfoModal} className="aioa_dashboard-info-modal" onHide={() => handleHideInfoModal()} backdropClassName="aioa_dashboard-info-modal-overlay" size="lg" fullscreen="lg-down" backdrop="static" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {infoModalType === 1 ? (
                                    <>
                                        {t('bulk domain import instructions popup title')}
                                    </>
                                ) : infoModalType === 2 ? (
                                    <>
                                        {t("Delete Domain")}
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}

                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="aioa_dashboard-info-modal-content">
                                {infoModalType === 1 ? (
                                    <>
                                        {t(`Please add multiple domains and select plan for respective domains. Save template file and upload. Make sure your file header must have domain and plan.`)}
                                    </>
                                ) : infoModalType === 2 ? (
                                    <>
                                        {t(`Are you sure you want to remove ${infoModalDomainObj[0].domain}?`)}
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                            </div>
                        </Modal.Body>
                        {infoModalType === 2 ? (
                            <>
                                <Modal.Footer>
                                    <Button variant="danger" size="lg" onClick={() => handleDomainAction(infoModalDomainObj[0].id, infoModalDomainObj[0].domain, infoModalDomainObj[0].plan_id, 'delete')}>
                                        {t('Delete')}
                                    </Button>
                                    <Button variant="primary" size="lg" onClick={() => handleHideInfoModal()}>
                                        {t('Cancel')}
                                    </Button>
                                </Modal.Footer>
                            </>
                        ) : (
                            <>

                            </>
                        )}
                    </Modal>

                    {/* Domain List */}
                    <Modal centered className="aioa_dashboard-info-modal" show={isShowDomainListModal} onHide={() => handleHideDomainListModal()} backdropClassName="aioa_dashboard-info-modal-overlay">
                        <Modal.Header closeButton>
                            <Modal.Title>{t('bulk domain popup title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <div className="aioa_dashboard-table">
                                <div className="aioa_dashboard-table-thead">
                                    <div className="aioa_dashboard-table-tr">
                                        <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap">{t("Sr No")}</div>
                                        <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap">{t("Domain")}</div>
                                    </div>
                                </div>
                                <div className="aioa_dashboard-table-body" aria-live='polite'>
                                    {domainListModalType === 1 ? (
                                        <>
                                            {modalValidDomainList && modalValidDomainList.length > 0 ? (
                                                <>
                                                    {modalValidDomainList.map((validDomainItem, index) => {
                                                        return (
                                                            <div className="aioa_dashboard-table-tr" key={validDomainItem.id}>

                                                                <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                                                                    <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Sr No")}</div>
                                                                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{index + 1}</div>
                                                                </div>

                                                                <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                                                                    <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Domain")}</div>
                                                                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                                                                        <div className="domain-name">{validDomainItem.domain}</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            ) : (
                                                <>

                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {modalOtherDomainList && modalOtherDomainList.length > 0 ? (
                                                <>
                                                    {modalOtherDomainList.map((validDomainItem, index) => {
                                                        return (
                                                            <div className="aioa_dashboard-table-tr" key={index + 1}>

                                                                <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                                                                    <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Sr No")}</div>
                                                                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{index + 1}</div>
                                                                </div>

                                                                <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                                                                    <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Domain")}</div>
                                                                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                                                                        <div className="domain-name">{validDomainItem}</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            ) : (
                                                <>

                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>

            </Form >
        </>
    )
}
export default SaveBulkDomain;