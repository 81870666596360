import DashboardDomainDropdown from "../DomainDropdown";
import DashboardLanguageButton from "../LanguageButton";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useSelector } from "react-redux";
import DashboardLogo from "../DashboardLogo";

import "../../Assets/scss/components/header.scss";
import AddNewDomainButton from "../AddNewDomainButton";
import DashboardSupportButton from "../SupportButton";
import UserDropdownButton from "../UserDropdownButton";

const DashboardBlankPageHeader = () => {

    // Redux Data
    const { user } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            <header className={`aioa_dashboard-header not-logged-in`}>
                <div className="aioa_dashboard-header-domain-wrapper">
                    <DashboardLogo />
                </div>

                <div className="aioa_dashboard-header-user-wrapper">
                    {/* {user && (
                        <DashboardSupportButton />
                    )} */}
                    <DashboardLanguageButton />
                    {user && (
                        <UserDropdownButton />
                    )}
                </div>
            </header>
        </>
    )
}

export default DashboardBlankPageHeader;