import { debounce } from 'lodash';
import { useState, useEffect, useCallback } from 'react';

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

    const handleResize = useCallback(debounce(() => {
        setWindowSize(window.innerWidth);
    }, 500), []);

    useEffect(() => {
        window.addEventListener('resize', handleResize, { passive: true });
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return { windowSize }

}

export default useWindowSize;