import { useEffect, useState } from "react";
import "../../Assets/scss/components/domain-dropdown.scss";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { addDefaultFaviconImgSrc } from "../../Helpers/addDefaultImgSrc";
import { DomainDetailsService } from "../../Services/DomainService/DomainDetails";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DashboardDomainDropdown = () => {

    // Redux Data
    const { currentWebsite, websiteList, accessToken } = useSelector((store: StoreProptypes) => store);

    // Function
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // States

    const [showDomainButtonFlag, setShowDomainButtonFlag] = useState<boolean>(false);
    const [domainButtonActiveFlag, setDomainButtonActiveFlag] = useState<boolean>(false);

    // Dropdown Manage
    useEffect(() => {
        if (websiteList && websiteList.length === 1) {
            setShowDomainButtonFlag(true);
        } else {
            setShowDomainButtonFlag(false);
        }
    }, [websiteList])

    // Dropdown Show Hide Function
    const handleDomainDropdownShow = () => {
        setDomainButtonActiveFlag(true);
    }
    const handleDomainDropdownHide = () => {
        setDomainButtonActiveFlag(false);
    }

    // Set Current Website
    const handleSetCurrentWebsite = (websiteID: number) => {
        if (accessToken && websiteID) {
            console.log("from domainDropdown");
            DomainDetailsService(accessToken, websiteID, dispatch, t);
            handleDomainDropdownHide();
        }
    }

    return (
        <>
            <div className="aioa_dashboard-domain-dropdown-wrapper">

                {domainButtonActiveFlag ? (
                    <>
                        {/* Temp Show */}
                        {currentWebsite && (
                            <>
                                <div className={`aioa_dashboard-domain-dropdown-btn ${showDomainButtonFlag ? `single-site` : ``}`} role="button" tabIndex={0} onClick={() => handleDomainDropdownHide()} onKeyDown={(e) => (e.key === 'Enter' || e.key === " ") && handleDomainDropdownHide()}>

                                    {currentWebsite.fav_icon ? (
                                        <>
                                            <div className="aioa_dashboard-domain-dropdown-favicon">
                                                <img
                                                    onError={addDefaultFaviconImgSrc}
                                                    src={currentWebsite.fav_icon}
                                                    alt=""
                                                //style={{ width: 35, height: 35 }}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="aioa_dashboard-domain-dropdown-favicon no-icon">
                                                {currentWebsite.title ? currentWebsite.title.charAt(0) : currentWebsite.domain.charAt(0)}
                                            </div>
                                        </>
                                    )}

                                    <div className="aioa_dashboard-domain-dropdown-text">{currentWebsite.domain}</div>
                                </div>
                            </>
                        )}
                        {/* Temp Show */}

                        <div className="aioa_dashboard-domain-dropdown-list-wrapper">
                            <div className="aioa_dashboard-domain-dropdown-list">

                                {websiteList && websiteList.length > 0 ? (
                                    <>
                                        {websiteList.map((websiteItem) => {
                                            return (
                                                <div className="aioa_dashboard-domain-dropdown-btn-box" key={websiteItem.id}>
                                                    <div className={`aioa_dashboard-domain-dropdown-btn ${websiteItem.id === currentWebsite?.id ? `active` : ``}`} role="button" tabIndex={websiteItem.id === currentWebsite?.id ? -1 : 0} onClick={() => { handleSetCurrentWebsite(websiteItem.id); handleDomainDropdownHide() }} onKeyDown={(e) => (e.key === 'Enter' || e.key === " ") && handleSetCurrentWebsite(websiteItem.id)}>
                                                        {websiteItem.fav_icon ? (
                                                            <>
                                                                <div className="aioa_dashboard-domain-dropdown-favicon">
                                                                    <img
                                                                        onError={addDefaultFaviconImgSrc}
                                                                        src={websiteItem.fav_icon}
                                                                        alt=""
                                                                        style={{ width: 35, height: 35 }}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="aioa_dashboard-domain-dropdown-favicon no-icon">
                                                                    {websiteItem.title ? websiteItem.title.charAt(0) : websiteItem.domain.charAt(0)}
                                                                </div>
                                                            </>
                                                        )}

                                                        <div className="aioa_dashboard-domain-dropdown-text">{websiteItem.domain}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-view-all-domain">
                                <Link to="/front/websites">{t('view all website button label')}</Link>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {currentWebsite && (
                            <>
                                <div className={`aioa_dashboard-domain-dropdown-btn ${showDomainButtonFlag ? `single-site` : ``}`} role="button" tabIndex={showDomainButtonFlag ? -1 : 0} onClick={() => handleDomainDropdownShow()} onKeyDown={(e) => (e.key === 'Enter' || e.key === " ") && handleDomainDropdownShow()}>

                                    {currentWebsite.fav_icon ? (
                                        <>
                                            <div className="aioa_dashboard-domain-dropdown-favicon">
                                                <img
                                                    onError={addDefaultFaviconImgSrc}
                                                    src={currentWebsite.fav_icon}
                                                    alt=""
                                                    style={{ width: 35, height: 35 }}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="aioa_dashboard-domain-dropdown-favicon no-icon">
                                                {currentWebsite.title ? currentWebsite.title.charAt(0) : currentWebsite.domain.charAt(0)}
                                            </div>
                                        </>
                                    )}

                                    <div className="aioa_dashboard-domain-dropdown-text">{currentWebsite.domain}</div>
                                </div>
                            </>
                        )}
                    </>
                )}

            </div>
        </>
    )
}

export default DashboardDomainDropdown;