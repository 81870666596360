import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { getPDFListApi, getQuotesPDFListApi, directPaymentApi, getPaymentLinkApi, cancelRemediationQuotePDFApi } from "../../Api/PDFLApi";


const getPDFListService = (
    accessToken: string,
    dispatch: Function,
    setList: Function,
    setTotalRecord: Function,
    setTotalInaccessiblePdfs: Function,
    setTotalQuotePdfs: Function,
    setTotalRemediatedPdfs: Function,
    setTotalQuotesPDFsPrice: Function,
    setPurchaseUrl: Function,
    offset: number,
    limit: number,
    website_id: number,
    setTotalRecordWithoutFilter: Function,
    setPrePurchasePDFTotalCount: Function,
    setPrePurchasePDFRemainingCount: Function,
    terms?: string,
    filterValue?: string,
    sortBy?: string,
) => {
    // Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    getPDFListApi(accessToken, offset, limit, website_id, terms, filterValue, sortBy).then(response => {
        if (response.Data) {
            setPrePurchasePDFTotalCount(response.total_pre_purchased_pages);
            setPrePurchasePDFRemainingCount(response.total_pre_purchased_remaining_pages);
            setList(response.Data);
            setTotalRecord(response.all_records_count);
            setTotalInaccessiblePdfs(response.total_inaccessible_pdfs);
            setTotalQuotePdfs(response.total_quote_pdfs);
            setTotalRemediatedPdfs(response.total_remediated_pdfs);
            setTotalQuotesPDFsPrice(response.totalQuotesPDFsPrice);
            setPurchaseUrl(response.purchase_url);
            setTotalRecordWithoutFilter(response.total_main_records);

            // Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        } else {
            // Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }

    })
}

const getQuotesPDFListService = (
    accessToken: string,
    website_id: number,
    setQuotesPDFLoading: Function,
    setQuotesPDFList: Function,
    setTotalQuotesPDFs: Function,
    setTotalQuotesPDFsPrice: Function,
    setQuotesPDFsPurchaseURL: Function,
    remediatedPDFsStatus: number
) => {
    setQuotesPDFLoading(true);

    getQuotesPDFListApi(accessToken, website_id, remediatedPDFsStatus).then(response => {
        if (response.Data) {
            setQuotesPDFList(response.Data);
            setTotalQuotesPDFs(response.total_record);
            setTotalQuotesPDFsPrice(response.total_price)
            setQuotesPDFsPurchaseURL(response.purchase_url)
        }
        setQuotesPDFLoading(false);
    })
}


const getRemediatedPDFListService = (
    accessToken: string,
    website_id: number,
    setRemediatedPDFLoading: Function,
    setRemediatedPDFList: Function,
    setTotalRemediatedPDFs: Function,
    remediatedPDFsStatus?: number
) => {
    setRemediatedPDFLoading(true);
    getQuotesPDFListApi(accessToken, website_id, remediatedPDFsStatus).then(response => {
        if (response.Data) {
            setRemediatedPDFList(response.Data);
            setTotalRemediatedPDFs(response.total_record);
        }
        setRemediatedPDFLoading(false);
    })
}


const directPaymentService = (
    accessToken: string,
    website_id: number,
    pdfIds: string,
    setPaymentBtnLoading: Function,
    setSelectPdf: Function,
    navigate: Function,
    setLoading: Function,
    setList: Function,
    setTotalRecord: Function,
    setTotalInaccessiblePdfs: Function,
    setTotalQuotePdfs: Function,
    setTotalRemediatedPdfs: Function,
    setTotalQuotesPDFsPrice: Function,
    setPurchaseUrl: Function,
    offset: number,
    limit: number,
    setTotalRecordWithoutFilter: Function,
    setPrePurchasePDFTotalCount: Function,
    setPrePurchasePDFRemainingCount: Function,
    flag?: number
) => {
    setPaymentBtnLoading(true);
    setLoading(true);
    directPaymentApi(accessToken, website_id, pdfIds, flag).then(response => {
        if (flag && flag === 1) {
            getPDFListApi(accessToken, offset, limit, website_id).then(response => {
                if (response.Data) {
                    setPrePurchasePDFTotalCount(response.total_pre_purchased_pages);
                    setPrePurchasePDFRemainingCount(response.total_pre_purchased_remaining_pages);
                    setList(response.Data);
                    setTotalRecord(response.all_records_count);
                    setTotalInaccessiblePdfs(response.total_inaccessible_pdfs);
                    setTotalQuotePdfs(response.total_quote_pdfs);
                    setTotalRemediatedPdfs(response.total_remediated_pdfs);
                    setTotalQuotesPDFsPrice(response.totalQuotesPDFsPrice);
                    setPurchaseUrl(response.purchase_url);
                    setTotalRecordWithoutFilter(response.total_main_records);
                }
                setLoading(false);
            })
        } else {
            if (response.purchase_token) {
                //window.open(response.purchase_url, '_blank', 'noreferrer');
                navigate(`/front/pdf-package/${response.purchase_token}`)
            }
        }

        setPaymentBtnLoading(false);
        setSelectPdf([]);
    })
}


const getPaymentLinkService = (
    accessToken: string,
    website_id: number,
    pdfIds: string,
    setPaymentBtnLoading: Function,
    setSelectPdf: Function
) => {
    setPaymentBtnLoading(true);
    getPaymentLinkApi(accessToken, website_id, pdfIds).then(response => {
        if (response.purchase_url) {
            window.open(response.purchase_url, '_blank', 'noreferrer');
        }
        setPaymentBtnLoading(false);
        setSelectPdf([]);
    })
}

const cancelRemediationQuotePDF = (
    accessToken: string,
    name: string | null,
    email: string,
    phone: string | null,
    subject: string,
    website_id: number,
    plan_id: number,
    is_pdf_remediation: number,
    is_quote_removed: number,
    pdfRemoveID: string,
    setLoading: Function,
    apiRecall: boolean,
    setRecallAPI: Function,
    setShowModal: Function,
    t: Function,
    dispatch: Function
) => {
    setLoading(true);
    cancelRemediationQuotePDFApi(accessToken, name, email, phone, subject, website_id, plan_id, is_pdf_remediation, is_quote_removed, pdfRemoveID).then(response => {
        if (response.Data) {
            setShowModal(false);
            dispatch({
                type: SET_MESSAGE,
                //payload: t("PDF quote request cancelled successfully.")
                payload: {
                    "toast_message": t("PDF quote request cancelled successfully."),
                    "toast_type": "success"
                }
            })
            setRecallAPI(!apiRecall);
        }
        else {
            dispatch({
                type: SET_MESSAGE,
                //payload: t("Cancelling PDF quote request failed. Please try again later.")
                payload: {
                    "toast_message": t("Cancelling PDF quote request failed. Please try again later."),
                    "toast_type": "danger"
                }
            })
        }
        setLoading(false);
    })
}

export { getPDFListService, getQuotesPDFListService, getRemediatedPDFListService, directPaymentService, getPaymentLinkService, cancelRemediationQuotePDF }