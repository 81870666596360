import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { getImageAltTextApi, updateImageAltTextApi, pageWiseImageAltApi, AllUpdateImageAltTextApi } from "../../Api/ImageAltTextApi";
import { ImageAltListPropTypes, SelectedImageAltListPropTypes } from "../../PropTypes/ImageAltPropTypes";
import { DomainDetailsService } from "../DomainService/DomainDetails";

const getAllImageAltTextService = (
    accessToken: string,
    websiteID: number,
    offset: number,
    limit: number,
    terms: string,
    filterValue: string,
    sortByValue: string,
    dispatch: Function,
    t: Function,
    setImageAltTextList: Function,
) => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    getImageAltTextApi(accessToken, offset, limit, websiteID, terms, filterValue, sortByValue).then(response => {
        if (response.Data) {
            setImageAltTextList(response.Data);
        }

        // Set loading
        dispatch({
            type: LOADING,
            payload: false
        });
    })
}

const getImageAltTextService = (
    accessToken: string,
    setLoading: Function,
    setImageAltTextList: Function,
    offset: number,
    limit: number,
    website_id: number,
    setTotalRecord: Function,
    setImageMissingAltTextListRecord: Function,
    setImageDecorativeAltTextListRecord: Function,
    setAllImageListRecord: Function,
    setCurrentPageDetails: Function,
    terms?: string,
    filterValue?: string,
    sortByValue?: string,
    pageID?: string) => {
    setLoading(true);

    getImageAltTextApi(accessToken, offset, limit, website_id, terms, filterValue, sortByValue, pageID).then(response => {
        if (response.Data) {
            setImageAltTextList(response.Data);
            setTotalRecord(response.all_records_count);
            setAllImageListRecord(response.total_record);
            setImageMissingAltTextListRecord(response.total_missing_alt_tag);
            setImageDecorativeAltTextListRecord(response.total_decorative);
            setCurrentPageDetails(response.page_detail);
        }
        setLoading(false);
    })
}

const AllUpdateImageAltTextService = async (
    accessToken: string,
    websiteID: number,
    dispatch: Function,
    t: Function,
    imageAltTextList: SelectedImageAltListPropTypes[],
): Promise<any> => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    const AllUpdateImageAltTextServiceRes = AllUpdateImageAltTextApi(accessToken, websiteID, imageAltTextList).then(async (response) => {

        if (response.status === 200) {
            console.log("from image alt text service");

            const DomainDetailsServiceRes = DomainDetailsService(accessToken, websiteID, dispatch, t).then(async (domainDetailsRes) => {
                if (domainDetailsRes.status === 200) {

                    // Set loading
                    dispatch({
                        type: LOADING,
                        payload: false
                    });

                    // Show Msg
                    dispatch({
                        type: SET_MESSAGE,
                        payload: {
                            "toast_message": `${response.updated_count ? `Total ${response.updated_count} Records updated.` : ``} ${response.not_updated_count ? `${response.not_updated_count} records not updated.` : ``}`,
                            "toast_type": "success"
                        }
                    });
                }

                return { response, status: 200 };

            });

            return DomainDetailsServiceRes;
        }
    });

    return AllUpdateImageAltTextServiceRes
}

const updateImageAltTextService = (
    accessToken: string,
    website_id: number,
    imageAltTextList: ImageAltListPropTypes[],
    setBtnLoading: Function,
    setAltImageAPIRecall: Function,
    altImageAPIRecall: boolean
) => {
    setBtnLoading(true);
    updateImageAltTextApi(accessToken, website_id, imageAltTextList).then(response => {
        setBtnLoading(false);
        setAltImageAPIRecall(!altImageAPIRecall);
    })
}


const pageWiseImageAltTextService = (
    accessToken: string,
    website_id: number,
    setPageWiseImageAltTextList: Function,
    setLoading: Function,
    setTotalRecord: Function,
    setTotalMissingAltTagRecord: Function,
    setTotalUpdateAltTagRecord: Function,
    setTotalMissingAltTextPageRecord: Function,
    setTotalPageRecord: Function,
    setTotalUpdateAltTextPagesRecord: Function,
    setTotalAllAltTextPagesRecord: Function,
    setTotalDecorativePageRecord: Function,
    setTotalOtherAltTextPagesRecord: Function,
    offset?: number,
    limit?: number,
    filter?: string,
    terms?: string
) => {
    setLoading(true);
    pageWiseImageAltApi(accessToken, website_id, offset, limit, filter, terms).then(res => {
        if (res.Data) {
            setTotalRecord(res.total_record);
            setTotalPageRecord(res.total_filter_records)
            setPageWiseImageAltTextList(res.Data);
            setTotalMissingAltTagRecord(res.total_missing_alt_tag);
            setTotalUpdateAltTagRecord(res.total_update_alt_text);


            setTotalAllAltTextPagesRecord(res.total_pages);
            setTotalMissingAltTextPageRecord(res.error_pages);
            setTotalUpdateAltTextPagesRecord(res.remedidate_pages);
            setTotalDecorativePageRecord(res.decorative_pages);
            setTotalOtherAltTextPagesRecord(res.other_pages);


            setLoading(false);
        }
    })
}

export { getImageAltTextService, updateImageAltTextService, AllUpdateImageAltTextService, pageWiseImageAltTextService, getAllImageAltTextService }