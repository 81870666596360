const baseURL = `${process.env.REACT_APP_BASE_URL}/api/websites`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}


const getDomainListWithDetailsApi = async (
    token: string,
    limit: number,
    offset: number,
    terms: string,
    statusFilter: number,
    startDate : string, 
    endDate : string,
): Promise<any> => {

    const url = `${baseURL}/list`;

    const data = new FormData();
    data.append("alldetail", 'true');
    limit !== null && data.append("limit", limit.toString());
    offset !== null && data.append("offset", offset.toString());
    terms !== '' && data.append("terms", terms);
    statusFilter !== 4 && data.append("filter_status", (statusFilter === 10 ? 0 : statusFilter).toString());
    if(startDate !== '' && endDate !== '') {
        data.append("start_date", startDate);
        data.append("end_date", endDate);
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const getDomainListApi = async (
    token: string,
): Promise<any> => {

    const url = `${baseURL}/list`;
    const data = new FormData();

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { getDomainListWithDetailsApi, getDomainListApi }