import i18next from "i18next";
import { LOADING, LOGIN_SUCCESS, LOGOUT, SET_ACCESS_TOKEN, SET_CURRENT_USER_STATUS, SET_CURRENT_WEBSITE, SET_CURRENT_WEBSITE_DATA, SET_CURRENT_WEBSITE_PLAN_DATA, SET_MESSAGE, SET_WEBSITE_LIST } from "../../Actions/Types";
import { DomainDetailsApi } from "../../Api/DomainApi/DomainDetailsApi";
import { UserLoginActionApi, getUserDetailsApi } from "../../Api/UserAction/LoginActionApi";
import { getViolationsCountApi, getViolationsListApi } from "../../Api/ViolationsApi";
import decodeBase64 from "../../Helpers/decodeBase64";
import encodeBase64 from "../../Helpers/encodeBase64";
import parseISODate from "../../Helpers/parseISODate";
import { getPlanListService } from "../PlanListService";

const UserLoginActionService = (
    username: string,
    password: string,
    dispatch: Function,
    navigate: Function,
    t: Function,
    woocommerceToken?: string
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // Login API Call
    UserLoginActionApi(username, password).then((userResponse) => {

        if (userResponse.Data && userResponse.token) {

            let loginWebsiteID: number;

            loginWebsiteID = (userResponse.Data.website_lists.length > 0 ? userResponse.Data.website_lists[0].id : 0);

            // Current User Plans
            getPlanListService(userResponse.token, 2, dispatch);

            //console.info("loginWebsiteID ==>", loginWebsiteID, (loginWebsiteID !== 0));

            let currentUserType: number;

            if (userResponse.Data.is_extension_user === 1) {
                currentUserType = 5
            } else if (userResponse.Data.department === 4) {
                if (userResponse.Data.is_multiple_domain === 1) {
                    currentUserType = 3
                } else {
                    currentUserType = 2
                }
            } else if (userResponse.Data.is_multiple_domain === 2) {
                currentUserType = 4
            } else {
                currentUserType = 1
            }

            // Set User Status
            dispatch({
                type: SET_CURRENT_USER_STATUS,
                payload: {
                    currentUserType: currentUserType, // 1 - normal user, 2 - normal partner, 3 - pre purchased, 4 - multi store, 5 - extension
                    isInitUser: loginWebsiteID === 0 ? true : false
                },
            });

            // Domain ID Check
            if (loginWebsiteID !== 0) {

                // Domain Details
                DomainDetailsApi(userResponse.token, loginWebsiteID).then((domainResponse) => {

                    // Check Domain Details Res
                    if (domainResponse.status === 200) {

                        // Scan Count
                        getViolationsCountApi(userResponse.token, loginWebsiteID).then((scanDataRes) => {

                            if (scanDataRes.status === 200) {

                                // Scan Violations Data API
                                getViolationsListApi(userResponse.token, loginWebsiteID, i18next.language, 0).then((scanErrorViolationRes) => {

                                    if (scanErrorViolationRes.status === 200) {

                                        getViolationsListApi(userResponse.token, loginWebsiteID, i18next.language, 1).then((scanSuccessViolationRes) => {

                                            // Scan Data
                                            if (scanSuccessViolationRes.status === 200) {

                                                // Accessibility Score Percentage
                                                let accessibilityScore: number;
                                                let successAccessibilityChecks: number = scanDataRes.data[0].successAccessibilityChecks;
                                                let failingAccessibilityChecks: number = scanDataRes.data[0].failingAccessibilityChecks;

                                                if ((successAccessibilityChecks + failingAccessibilityChecks) > 0) {
                                                    accessibilityScore = (parseFloat((((successAccessibilityChecks) / (successAccessibilityChecks + failingAccessibilityChecks)) * 100).toFixed(2)))
                                                } else {
                                                    accessibilityScore = 0;
                                                }

                                                // Violations Remediated Percentage
                                                let violationsRemediatedPercentage: number;
                                                let successViolations: number = scanSuccessViolationRes.total_counts;
                                                let failedViolations: number = scanErrorViolationRes.total_counts;

                                                if ((successViolations + failedViolations) > 0) {
                                                    violationsRemediatedPercentage = (parseFloat((((successViolations) / (successViolations + failedViolations)) * 100).toFixed(2)))
                                                } else {
                                                    violationsRemediatedPercentage = 0;
                                                }

                                                // Image Alt Remediated Percentage
                                                let imageAltRemediatedPercentage: number;
                                                let successImageAlt: number = scanDataRes.data[0].total_update_alt_text;
                                                let failedImageAlt: number = scanDataRes.data[0].missing_alt_text;

                                                if ((successImageAlt + failedImageAlt) > 0) {
                                                    imageAltRemediatedPercentage = (parseFloat((((successImageAlt) / (successImageAlt + failedImageAlt)) * 100).toFixed(2)))
                                                } else {
                                                    imageAltRemediatedPercentage = 0;
                                                }

                                                // Set Current Website Data
                                                dispatch({
                                                    type: SET_CURRENT_WEBSITE_DATA,
                                                    payload: {
                                                        "accessibilityScore": accessibilityScore,
                                                        "violationsRemediatedPercentage": violationsRemediatedPercentage,
                                                        "successViolations": successViolations,
                                                        "failedViolations": failedViolations,
                                                        "imageAltRemediatedPercentage": imageAltRemediatedPercentage,
                                                        "successImageAlt": successImageAlt,
                                                        "failedImageAlt": failedImageAlt,
                                                        "successViolationsList": scanSuccessViolationRes.Data,
                                                        "failedViolationsList": scanErrorViolationRes.Data,
                                                    }
                                                });

                                                // Set Current Website Plan Details

                                                let isExpiredFlag: boolean = false;
                                                let isTrialFlag: boolean = false;
                                                let isInitFlag: boolean = false;
                                                let isFreeWidget: boolean = false;
                                                let isProPlanFlag: boolean = false;
                                                let isCanceledPlanFlag: boolean = false;
                                                let tempPlanName: string | null = null;
                                                let tempPlanExpiredData: string | null = null;

                                                if (domainResponse.Data && domainResponse.Data.length > 0) {
                                                    if (domainResponse.Data[0].is_expired === 1) { // Expired Plan

                                                        if (domainResponse.Data[0].expired_package_detail) {
                                                            isExpiredFlag = true;
                                                            isTrialFlag = false;
                                                            isInitFlag = false;
                                                            isFreeWidget = false;
                                                            isCanceledPlanFlag = false;
                                                            isProPlanFlag = false;
                                                            tempPlanName = domainResponse.Data[0].expired_package_detail[0].name;
                                                            tempPlanExpiredData = parseISODate(domainResponse.Data[0].expired_package_detail[0].end_date);
                                                        }

                                                    } else if ((domainResponse.Data[0].is_expired === 3) && (domainResponse.Data[0].current_package_only && domainResponse.Data[0].current_package_only.length > 0)) { // Trial Plan

                                                        isExpiredFlag = false;
                                                        isTrialFlag = true;
                                                        isCanceledPlanFlag = false;
                                                        isProPlanFlag = false;
                                                        isFreeWidget = false;
                                                        isInitFlag = (successViolations === 0 && failedViolations === 0) ? true : false;
                                                        tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                                        tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);

                                                    } else if (domainResponse.Data[0].canceled_package_only && domainResponse.Data[0].canceled_package_only.length > 0) { // Canceled Plan

                                                        isExpiredFlag = false;
                                                        isTrialFlag = false;
                                                        isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                                        isFreeWidget = false;
                                                        isProPlanFlag = false;
                                                        isCanceledPlanFlag = true;
                                                        tempPlanName = domainResponse.Data[0].canceled_package_only[0].name;
                                                        tempPlanExpiredData = parseISODate(domainResponse.Data[0].canceled_package_only[0].end_date);

                                                    } else {

                                                        if (domainResponse.Data[0].current_package_only && domainResponse.Data[0].current_package_only.length > 0) {

                                                            if (domainResponse.Data[0].current_package_only[0].is_free_widget === 1) {

                                                                // Free Plan Widget
                                                                isExpiredFlag = false;
                                                                isTrialFlag = false;
                                                                isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                                                isFreeWidget = true;
                                                                isProPlanFlag = false;
                                                                isCanceledPlanFlag = false;
                                                                tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                                                tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);

                                                            } else {

                                                                // Pro Plan Widget
                                                                isExpiredFlag = false;
                                                                isTrialFlag = false;
                                                                isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                                                isFreeWidget = false;
                                                                isCanceledPlanFlag = false;
                                                                isProPlanFlag = true;
                                                                tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                                                tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);
                                                            }

                                                        } else {

                                                            // No Plan Found
                                                            isExpiredFlag = false;
                                                            isTrialFlag = false;
                                                            isInitFlag = false;
                                                            isFreeWidget = false;
                                                            isProPlanFlag = false;
                                                            isCanceledPlanFlag = false;
                                                            tempPlanName = null;
                                                            tempPlanExpiredData = null;
                                                        }

                                                    }
                                                }

                                                dispatch({
                                                    type: SET_CURRENT_WEBSITE_PLAN_DATA,
                                                    payload: {
                                                        isExpired: isExpiredFlag,
                                                        isTrial: isTrialFlag,
                                                        isInit: isInitFlag,
                                                        isFreeWidget: isFreeWidget,
                                                        isProPlan: isProPlanFlag,
                                                        isCanceledPlan: isCanceledPlanFlag,
                                                        planName: tempPlanName,
                                                        planExpiredData: tempPlanExpiredData
                                                    }
                                                });

                                                // Save Access Token
                                                dispatch({
                                                    type: SET_ACCESS_TOKEN,
                                                    payload: userResponse.token,
                                                });

                                                // Save User Data
                                                dispatch({
                                                    type: LOGIN_SUCCESS,
                                                    payload: { user: userResponse.Data },
                                                });

                                                // Set Website List
                                                dispatch({
                                                    type: SET_WEBSITE_LIST,
                                                    payload: userResponse.Data.website_lists
                                                })

                                                // Set Current Website Data
                                                dispatch({
                                                    type: SET_CURRENT_WEBSITE,
                                                    payload: domainResponse.Data[0]
                                                });

                                                // navigate
                                                if (woocommerceToken && woocommerceToken !== '' && domainResponse.Data.length > 0) {
                                                    let tokenObj = decodeBase64(woocommerceToken).split("|");

                                                    let redirectID = tokenObj[0];
                                                    let planID = tokenObj[1];
                                                    let PlanSubID = tokenObj[2];
                                                    let planInterval = tokenObj[3];
                                                    let domainURL = domainResponse.Data[0].domain;
                                                    let userID = userResponse.Data.id;
                                                    let activeLanguageCode = 'en';

                                                    if (redirectID === 'ad1') {
                                                        if (domainResponse.Data[0].white_label_enabled) {
                                                            navigate("/front/addon-services/white-label-service");
                                                        } else {
                                                            const urlString = `${planID}|${domainURL}|${planInterval}|${userID}|${activeLanguageCode}|1`;
                                                            const encodedString = encodeBase64(urlString);
                                                            navigate(`/front/package/${encodedString}`)
                                                        }
                                                    } else if (redirectID === 'ad2') {
                                                        navigate("/front/addon-services/custom-branding-service");
                                                    } else if (redirectID === 'ad3') {
                                                        if (domainResponse.Data[0].menu_features_enabled) {
                                                            navigate("/front/addon-services/modify-accessibility-menu");
                                                        } else {
                                                            const urlString = `${planID}|${domainURL}|${planInterval}|${userID}|${activeLanguageCode}|1`;
                                                            const encodedString = encodeBase64(urlString);
                                                            navigate(`/front/package/${encodedString}`)
                                                        }
                                                    } else if (redirectID === 'ad4') {
                                                        if (domainResponse.Data[0].live_site_translation_enabled) {
                                                            navigate("/front/addon-services/live-site-translation");
                                                        } else {
                                                            const urlString = `${planID}|${domainURL}|${planInterval}|${userID}|${activeLanguageCode}|2|${PlanSubID}`;
                                                            const encodedString = encodeBase64(urlString);
                                                            navigate(`/front/package/${encodedString}`)
                                                        }
                                                    } else if (redirectID === 'ad5') {
                                                        navigate("/front/addon-services/pdfs");
                                                    } else if (redirectID === 'ad6') {
                                                        navigate("/front/addon-services/manual-audit-report");
                                                    } else if (redirectID === 'ad7') {
                                                        navigate("/front/addon-services/vpat-report");
                                                    } else if (redirectID === 'ad8') {
                                                        navigate("/front/addon-services/manual-remediation");
                                                    } else if (redirectID === 'ad9') {
                                                        navigate("/front/addon-services/design-audit");
                                                    } else if (redirectID === 'ad10') {
                                                        navigate("/front/addon-services/native-mobile-app-audit");
                                                    } else if (redirectID === 'ad11') {
                                                        navigate("/front/addon-services/web-app-spa-audit");
                                                    } else {
                                                        navigate("/front");
                                                    }
                                                } else {
                                                    navigate('/front');
                                                }

                                                // Set Loading
                                                dispatch({
                                                    type: LOADING,
                                                    payload: false,
                                                });

                                                // Res Msg
                                                dispatch({
                                                    type: SET_MESSAGE,
                                                    payload: {
                                                        "toast_message": t('Login Success Msg'),
                                                        "toast_type": "success",
                                                        "onclose": null
                                                    }
                                                })
                                            }
                                        })

                                    }

                                })

                            }
                        })

                    } else {

                        // Logout
                        dispatch({
                            type: LOGOUT,
                        });

                        // Set Loading
                        dispatch({
                            type: LOADING,
                            payload: false,
                        });

                        // navigate
                        if (woocommerceToken && woocommerceToken !== '') {
                            navigate(`/front/login?token=${woocommerceToken}`);
                        } else {
                            navigate("/front/login");
                        }

                        // Res Msg
                        dispatch({
                            type: SET_MESSAGE,
                            payload: {
                                "toast_message": t('Login Fail Msg'),
                                "toast_type": "danger",
                                "onclose": null
                            }
                        })
                    }

                });

            } else {

                //console.info("loginWebsiteID ==>", loginWebsiteID, 'Else Condition');

                // navigation
                navigate(`/front/getting-started`);

                // Save Access Token
                dispatch({
                    type: SET_ACCESS_TOKEN,
                    payload: userResponse.token,
                });

                // Save User Data
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: userResponse.Data },
                });

                // Set Website List
                dispatch({
                    type: SET_WEBSITE_LIST,
                    payload: null
                });

                // Set Current Website Data
                dispatch({
                    type: SET_CURRENT_WEBSITE,
                    payload: null
                });

                // Set Loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });


            }

        } else {

            // Logout
            dispatch({
                type: LOGOUT,
            });

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // navigate
            if (woocommerceToken && woocommerceToken !== '') {
                navigate(`/front/login?token=${woocommerceToken}`);
            } else {
                navigate("/front/login");
            }

            // Res Msg
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Login Fail Msg'),
                    "toast_type": "danger",
                    "onclose": null
                }
            })

        }

    })
}

const getUserDetailsService = async (
    token: string,
    dispatch: Function,
    t: Function,
    isTokenSave?: boolean
): Promise<any> => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    const userDetailsStatus = getUserDetailsApi(token).then(async (userDetails) => {

        if (userDetails.Data) {

            let currentUserType: number;

            if (userDetails.Data.is_extension_user === 1) {
                currentUserType = 5
            } else if (userDetails.Data.department === 4) {
                if (userDetails.Data.is_multiple_domain === 1) {
                    currentUserType = 3
                } else {
                    currentUserType = 2
                }
            } else if (userDetails.Data.is_multiple_domain === 2) {
                currentUserType = 4
            } else {
                currentUserType = 1
            }

            // Set User Status
            dispatch({
                type: SET_CURRENT_USER_STATUS,
                payload: {
                    currentUserType: currentUserType,
                    isInitUser: userDetails.Data.website_lists.length === 0 ? true : false
                },
            });

            // Save User token
            if (isTokenSave) {
                dispatch({
                    type: SET_ACCESS_TOKEN,
                    payload: userDetails.token,
                });
            }

            // Save User Data
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: userDetails.Data },
            });

            // Set Website List
            dispatch({
                type: SET_WEBSITE_LIST,
                payload: userDetails.Data.website_lists
            })

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { status: 200 }

        } else {

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { status: 400 }

        }
    });

    return userDetailsStatus;
}

export { UserLoginActionService, getUserDetailsService }
