import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ViolationsListPropTypes } from "../../PropTypes/ViolationsPropTypes";
import NoRecordFound from "../NoRecordFound";

interface propTypes {
    violationsList: ViolationsListPropTypes[],
    violationsType: number
}

const ViolationsTableData = (prop: propTypes) => {

    const { violationsList, violationsType } = prop;

    const { t } = useTranslation();

    return (
        <>
            <div className="aioa_dashboard-table">

                <div className="aioa_dashboard-table-thead">
                    <div className="aioa_dashboard-table-tr">
                        <div className="aioa_dashboard-table-th aioa_dashboard-table-cell-sidegap">{t('violation description cell label')}</div>
                        <div className="aioa_dashboard-table-th aioa_dashboard-table-cell-sidegap tcw-200 text-center">{t('violations number cell label')}</div>
                    </div>
                </div>

                <div className="aioa_dashboard-table-body">
                    {violationsList ? (
                        <>
                            {violationsList.length > 0 ? (
                                <>
                                    {violationsList.map((violationItem) => {
                                        return (
                                            <div className="aioa_dashboard-table-tr" key={violationItem.id}>
                                                <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                                                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("violation description cell label")}:</div>
                                                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("violation description cell label").toString()}>{violationItem.check}</div>
                                                </div>
                                                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                                                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("violations number cell label")}:</div>
                                                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("violations number cell label").toString()}><Badge bg={`${violationsType === 1 ? `success` : `danger`}`}>{violationItem.fail_checks}</Badge></div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            ) : (
                                <>
                                    {violationsType === 1 ? (
                                        <>
                                            <NoRecordFound joyful={false} imageType={`checks`} noRecordText={`${t('No violations fixed on this website. Please check again later')}.`} />
                                        </>
                                    ) : (
                                        <>
                                            <NoRecordFound joyful={true} imageType={`checks`} noRecordText={`${t("Kudos! No violations required manual actions")}.`} />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>

                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default ViolationsTableData;