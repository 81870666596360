const baseURL = `${process.env.REACT_APP_BASE_URL}/api/websites`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

const getEnableDisableMenuApi = async (accessToken: string, website_id: number): Promise<any> => {

    const url = `${baseURL}/widget-features-get`;

    const data = new FormData();


    data.append("website_id", website_id.toString());


    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

const updateEnableDisableMenuApi = async (accessToken: string, website_id: number, update_reset: number, menuList:any, languagesList:any, otherList:any, accessibilityProfilesList: any): Promise<any> => {

    const url = `${baseURL}/widget-features-update`;

    const data = new FormData();


    data.append("website_id", website_id.toString());
    data.append("update_reset", (update_reset === 1 ? '0' : '1' ));
    data.append(`feature_data[0]`, JSON.stringify(menuList));
    data.append(`feature_data[1]`, JSON.stringify(languagesList));
    data.append(`feature_data[2]`, JSON.stringify(otherList));
    data.append(`feature_data[3]`, JSON.stringify(accessibilityProfilesList));

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}




export { getEnableDisableMenuApi, updateEnableDisableMenuApi };