import DashboardFooter from "../Components/Footer";
import DashboardHeader from "../Components/Header";
import MainPageTemplate from "./MainPageTemplate";

import UserPageImg from "../Assets/img/user-action-pages-image.svg";

import "../Assets/scss/template/useractiontemplate.scss";
import DashboardBlankPageHeader from "../Components/Header/DashboardBlankPageHeader";

interface PropTypes {
    children: any
}

const UserActionPagesTemplate = (prop: PropTypes) => {
    const { children } = prop;
    return (
        <>
            <MainPageTemplate>

                {/* <DashboardSidebarMenu /> */}

                <div className="aioa_dashboard-content-wrapper">
                    <DashboardBlankPageHeader />
                    <main id="main">
                        <div className="aioa_dashboard-page-content">
                            <div className="aioa_dashboard-user-module-wrapper">
                                <div className="container">
                                    <div className="aioa_dashboard-user-module-inner-wrapper shadow">
                                        <div className="aioa_dashboard-user-module-form border">
                                            {children}
                                        </div>
                                        <div className="aioa_dashboard-user-module-video">
                                            <img src={UserPageImg} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>

            </MainPageTemplate>
        </>
    )
}

export default UserActionPagesTemplate;