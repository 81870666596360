import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { bulkDomainListActionAPI, checkBulkDomainListAPI } from "../../Api/BulkDomainAPI";


const checkBulkDomainListService = (
    accessToken: string,
    domainListFile: File,
    dispatch: Function,
    t: Function,
    setValidDomain: Function,
    setInValidDomain: Function,
    setExistDomain: Function,
    setDuplicateDomain: Function
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    checkBulkDomainListAPI(accessToken, domainListFile).then(response => {

        if ((response.data && response.data.length > 0) || (response.invalidDomain && response.invalidDomain.length > 0) || (response.existDomain && response.existDomain.length > 0) || (response.duplicateDomains && response.duplicateDomains.length > 0)) {

            // Set All Data
            setValidDomain(response.data);
            setInValidDomain(response.invalidDomain);
            setExistDomain(response.existDomain);
            setDuplicateDomain(response.duplicateDomains);

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    toast_message: t("File upload successfully!"),
                    toast_type: 'success'
                }
            });
        }

        else {

            // Set All Data
            setValidDomain([]);
            setInValidDomain([]);
            setExistDomain([]);
            setDuplicateDomain([]);

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    toast_message: t("File is blank or invalid. Please check with sample file. Make sure your file header must be domain and plan."),
                    toast_type: 'danger'
                }
            });
        }

    })
}

const bulkDomainListActionService = (
    accessToken: string,
    objId: number,
    domain: string,
    planId: number,
    action: string,
    dispatch: Function,
    t: Function,
    setValidDomain: Function,
    callBackFunction?: Function
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    bulkDomainListActionAPI(accessToken, objId, domain, planId, action).then(response => {
        if (response.data) {

            setValidDomain(response.data);
            callBackFunction && callBackFunction();

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    toast_message: t("Domain successfully updated."),
                    toast_type: 'success'
                }
            })
        }
        else {

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    toast_message: t("Domain not updated. please try again."),
                    toast_type: 'danger'
                }
            });

        }

    })
}

export { checkBulkDomainListService, bulkDomainListActionService }