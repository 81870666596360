import { useParams } from "react-router";
import decodeBase64 from "../../Helpers/decodeBase64";
import { useEffect, useState } from "react";
import encodeBase64 from "../../Helpers/encodeBase64";
import { toInteger } from "lodash";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import { LOADING } from "../../Actions/Types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import DashboardBlankPagesTemplate from "../../Template/DashboardBlankPagesTemplate";

import "../../Assets/scss/pages/payment-page.scss";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

const PlanPaymentPage = () => {

    // Function
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Redux Data
    const { currentUserStatus, isLoading } = useSelector((store: StoreProptypes) => store);

    // URL Token
    const { token } = useParams();

    const [iframURLType, setIframURLType] = useState<number | null>();
    const [packageID, setPackageID] = useState<number | null>(null);
    const [subPackageID, setSubPackageID] = useState<number | null>(null);
    const [domain, setDomain] = useState('');
    const [termPlan, setTermPlan] = useState('');
    const [userID, setUserID] = useState<number | null>(null);
    const [activeLanguageCode, setActiveLanguageCode] = useState('');
    const [planEncodedString, setPlanEncodedString] = useState('');
    const [iframLoader, setIframLoader] = useState(true);

    useEffect(() => {
        if (!isLoading) {
            //console.info("isLoading call in useEffect...");
            dispatch({
                type: LOADING,
                payload: true,
            });
        }
    }, [])

    //const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';

    const iframeHeight = () => {

        let frame = document.getElementById('myIframe') as HTMLIFrameElement;

        if (frame) {
            //console.info("iframe inside frame IF loop" );
            const iframeDoc = frame.contentDocument || frame.contentWindow?.document;
            //console.info("iframeDoc ==>", iframeDoc);
            if (iframeDoc?.readyState === 'complete') {
                //console.info("readyState Inside if loop");
                const contentWindow = frame.contentWindow;
                if (contentWindow) {
                    frame.style.height = contentWindow.document.body.scrollHeight + 250 + 'px';
                    //console.info("Iframe Inside if loop");
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });
                }
            } else {
                //console.info("readyState Inside else loop");
                setTimeout(() => {
                    iframeHeight();
                }, 100)
            }

        }
    }

    useEffect(() => {
        if (token) {
            const tokenObj = decodeBase64(token).split("|");

            console.info("tokenObj[1] ==>", tokenObj[1], tokenObj);

            setPackageID(toInteger(tokenObj[0]));
            setDomain(tokenObj[1]);
            setTermPlan(tokenObj[2]);
            setUserID(toInteger(tokenObj[3]));
            setActiveLanguageCode(tokenObj[4]);
            setIframURLType(toInteger(tokenObj[5]));
            setSubPackageID(toInteger(tokenObj[6]))
        } else {
            setPackageID(null);
            setDomain('');
            setTermPlan('');
            setUserID(null);
            setActiveLanguageCode('');
            setIframURLType(null);
            setSubPackageID(null)
        }
    }, [token])

    useEffect(() => {

        if (packageID !== null && domain !== '' && termPlan !== '' && userID !== null && activeLanguageCode !== '') {

            let urlString;
            //console.info(iframURLType,packageID,`${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}`);
            if (iframURLType === 1) {
                // For package URL token
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}`;
                const planEncodedString1 = encodeBase64(urlString);
                setPlanEncodedString(`package/${planEncodedString1}`);
            }
            else if (iframURLType === 10) {
                // For package URL token
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}|0|0|1|1`;
                const planEncodedString1 = encodeBase64(urlString);
                console.log("planEncodedString2 ==>", planEncodedString1);
                setPlanEncodedString(`package/${planEncodedString1}`);
            } else if (iframURLType === 2) {
                urlString = `${packageID}-${subPackageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}`;
                const planEncodedString2 = encodeBase64(urlString);
                console.log("planEncodedString2 ==>", planEncodedString2);
                setPlanEncodedString(`package/${planEncodedString2}`);
            } else if (iframURLType === 3) {
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}`;
                const planEncodedString3 = encodeBase64(urlString);
                setPlanEncodedString(`pre-purchase/${planEncodedString3}`);
            } else if (iframURLType === 4) {
                urlString = `${userID}|${activeLanguageCode}`;
                const planEncodedString4 = encodeBase64(urlString);
                setPlanEncodedString(`bulk-purchase/${planEncodedString4}`);
            } else if (iframURLType === 5) {
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}`;
                const planEncodedString5 = encodeBase64(urlString);
                console.info(`multi-store-payment/${planEncodedString5}`);
                setPlanEncodedString(`multi-store-payment/${planEncodedString5}`);
            } else if (iframURLType === 50) {
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}|1`;
                const planEncodedString5 = encodeBase64(urlString);
                setPlanEncodedString(`multi-store-payment/${planEncodedString5}`);
            } else if (iframURLType === 6) {
                // For package URL token
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}|3`;
                const planEncodedString1 = encodeBase64(urlString);
                setPlanEncodedString(`package/${planEncodedString1}`);
            } else if (iframURLType === 7) {
                // For package URL token
                urlString = `${packageID}|${domain}|${termPlan}|${userID}|${activeLanguageCode}`;
                const planEncodedString1 = encodeBase64(urlString);
                setPlanEncodedString(`update-card/${planEncodedString1}`);
            }

        } else {
            setPlanEncodedString('');
        }

    }, [iframURLType])

    const iframeLoader = () => {
        console.info("iframeLoader call...");
        iframeHeight();
    }

    return (
        <>
            {currentUserStatus && (
                <>
                    {currentUserStatus.isInitUser ? (
                        <>
                            <DashboardBlankPagesTemplate>
                                <div className="aioa_dashboard-blank-pages-content-inner-wrapper">
                                    <div className="aioa_dashboard-payment-screen-main">
                                        {((iframURLType === 1 || iframURLType === 2 || iframURLType === 3 || iframURLType === 4 || iframURLType === 5 || iframURLType === 50 || iframURLType === 6 || iframURLType === 10 || iframURLType === 7) && planEncodedString !== '') && (
                                            <>
                                                <iframe title={t("Payment for All in One Accessibility Plans/Add Ons").toString()} onLoad={iframeLoader} id="myIframe" src={`${baseURL}/${planEncodedString}`} style={{ width: '100%' }}></iframe>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </DashboardBlankPagesTemplate>
                        </>
                    ) : (
                        <>
                            <DashboardPagesTemplate>

                                <div className="aioa_dashboard-payment-screen-main">
                                    {((iframURLType === 1 || iframURLType === 2 || iframURLType === 3 || iframURLType === 4 || iframURLType === 5 || iframURLType === 50 || iframURLType === 6 || iframURLType === 7 || iframURLType === 10) && planEncodedString !== '') && (
                                        <>
                                            <iframe title={t("Payment for All in One Accessibility Plans/Add Ons").toString()} onLoad={iframeLoader} id="myIframe" src={`${baseURL}/${planEncodedString}`} style={{ width: '100%' }}></iframe>
                                        </>
                                    )}
                                </div>

                            </DashboardPagesTemplate>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default PlanPaymentPage;
