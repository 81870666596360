const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

const checkBulkDomainListAPI = async (
    accessToken: string,
    domainListFile: File,
): Promise<any> => {
    const url = `${baseURL}/partner-upload-domains`;

    const formData = new FormData();

    formData.append("import_file", domainListFile);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const bulkDomainListActionAPI = async (
    accessToken: string,
    objId: number,
    domain: string,
    planId: number,
    action: string
): Promise<any> => {
    const url = `${baseURL}/partner-update-domains`;

    const formData = new FormData();

    formData.append("id", objId.toString());
    formData.append("domain", domain);
    formData.append("plan_id", planId.toString());
    formData.append("action", action);
    

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export {
    checkBulkDomainListAPI,
    bulkDomainListActionAPI
};